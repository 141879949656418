<script>
import MapTrackingGpsComponent from "./MapTrackingGpsComponent";
export default {
  components: {},
  extends: MapTrackingGpsComponent,
};
</script>

<template>
  <div class="card-body" v-loading="isMapLoading"  v-if="onUserPermission('Permission.DeviceLocation.Show')">
    <div id="map"  style="height: 650px;" />
  </div>
</template>
