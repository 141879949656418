import _ from 'lodash'
import BaseViewModel from "../../../core/infraestructure/BaseViewModel";
import PostalCode from "../../../core/features/postalCodes/PostalCode";
import Neighborhood from "../../../core/features/neighborhoods/Neighborhood";
import Place from "../../../core/features/places/Place";

export default class AddressBoxViewModel extends BaseViewModel {
  constructor(view) {
    super(view);
  }

  async bindFormView(formData) {
    try{
      if(formData.postalCode) {
        this.view.postalCodes = [new PostalCode(formData.postalCode)];

        const requests = [
          this.api.countries.find(`Skip=0&Limit=10000&IsActive=true`),
        ]

        if(formData.country) {
          requests.push(this.api.districts.find(`CountryId=${formData.country.id}&Skip=0&Limit=10000&IsActive=true`))
        }

        if(formData.district) {
          requests.push(this.api.municipalities.find(`DistrictId=${formData.district.id}&Skip=0&Limit=10000&IsActive=true`))
        }

        if(formData.municipality) {
          requests.push(this.api.places.find(`DistrictId=${formData.district.id}&Skip=0&Limit=10000&IsActive=true`))
        }

        if(formData.postalCode) {
          requests.push(this.api.neighborhoods.find(`PostalCodeId=${formData.postalCode.id}&Skip=0&Limit=10000&IsActive=true`))
        }
        
        const [
          countries,
          districts,
          municipalities,
          places,
          neighborhoods,
        ] = await Promise.all(requests);

        this.view.countries = countries.data.data
        this.view.districts = districts?.data.data || []
        this.view.municipalities = municipalities?.data.data || []
        this.view.cities = places?.data.data || []
        this.view.neighborhoods = neighborhoods?.data.data || []

        this.view.onBindFormViewResponse()

      } else {

        const requests = [
          this.api.countries.find(`Skip=0&Limit=10000&IsActive=true`),
        ]

        const [
          countries,
        ] = await Promise.all(requests);

        this.view.countries = countries.data.data
      }
    } catch(error) {
      this.view.onError(error)
    }
  }

  findCountries(query) {
    this.api.countries
      .find(query)
      .then((response) => this.view.onFindCountriesResponse(response.data))
      .catch(this.view.onError);
  }

  findDistricts(query) {
    this.api.districts
      .find(query)
      .then((response) => this.view.onFindDistrictsResponse(response.data))
      .catch(this.view.onError);
  }

  findMunicipalities(query) {
    this.api.municipalities
      .find(query)
      .then((response) => this.view.onFindMunicipalitiesResponse(response.data))
      .catch(this.view.onError);
  }

  findCities(query) {
    this.api.places
      .find(query)
      .then((response) => this.view.onFindCitiesResponse(response.data))
      .catch(this.view.onError);
  }

  findNeighborhoods(query) {
    this.api.neighborhoods
      .find(query)
      .then((response) => this.view.onFindNeighborhoodsResponse(response.data))
      .catch(this.view.onError);
  }

  findAddress(query) {
    this.api.postalCodes
      .findAddress(query)
      .then((response) => this.view.onFindAddressResponse(response.data))
      .catch(this.view.onError);
  }

  addNeighborhood(data) {
    return new Neighborhood(data);
  }

  createNeighborhood(neighborhood, postalCodeId) {
    let neighborhoodResponse = null;
    this.api.neighborhoods
      .create(neighborhood.toSend())
      .then((response) => {
        return this.api.neighborhoods.findOne(response.data.data);
      })
      .then((response) => {
        neighborhoodResponse = response.data.data;
        return this.api.neighborhoods.find(
          `PostalCodeId=${postalCodeId}&Skip=0&Limit=10000&IsActive=true`
        );
      })
      .then((response) => {
        this.view.neighborhoods = response.data.data;
        this.view.onCreateNeighborhoodResponse(neighborhoodResponse);
      })
      .catch(this.view.onError);
  }

  findPostalCodes(query) {
    this.api.postalCodes
      .find(query)
      .then((response) =>
        this.view.onFindPostalCodesResponse(
          this.mapPostalCodes(response.data.data)
        )
      )
      .catch(this.view.onError);
  }

  async findPostalCode(postalCodeId) {
    try{
      const requests = [
        this.api.postalCodes.findOne(postalCodeId),
        this.api.countries.find(`Skip=0&Limit=10000&IsActive=true`),
      ]

      const [
        postalCode,
        countries,
      ] = await Promise.all(requests);

      this.view.countries = countries.data.data

      this.view.formData.country = postalCode.data.data.country
      this.view.formData.district = postalCode.data.data.district;
      this.view.formData.municipality = postalCode.data.data.municipality;
      this.view.formData.city = postalCode.data.data.city;
      // this.view.formData.neighborhood = (postalCode.data.data.neighborhood) ? postalCode.data.data.neighborhood : '';
      
      const secondRequests = [
        this.api.districts.find(`CountryId=${this.view.formData.country.id}&Skip=0&Limit=10000&IsActive=true`),
        this.api.municipalities.find(`DistrictId=${this.view.formData.district.id}&Skip=0&Limit=10000&IsActive=true`),
        this.api.places.find(`DistrictId=${this.view.formData.district.id}&Skip=0&Limit=10000&IsActive=true`),
        this.api.neighborhoods.find(`PostalCodeId=${postalCodeId}&Skip=0&Limit=10000&IsActive=true`)
      ]

      const [
        districts,
        municipalities,
        places,
        neighborhoods,
      ] = await Promise.all(secondRequests);

      this.view.districts = districts.data.data
      this.view.municipalities = municipalities.data.data
      this.view.cities = places.data.data
      this.view.neighborhoods = neighborhoods.data.data
      this.view.isAddressLoading = false;
    } catch(error) {
      this.view.onError(error)
    }
  }

  async findCustomerLocation(customer, location) {

    try {
      const requests = [
        this.api.customerLocation.findOne(customer.id, location.id)
      ]
      const [
        customerLocation,
      ] = await Promise.all(requests);

      this.view.$set(this.view.formData, "postalCode", customerLocation.data.data.postalCode);
      this.view.$set(this.view.formData, "country", customerLocation.data.data.country);
      this.view.$set(this.view.formData, "district", customerLocation.data.data.district);
      this.view.$set(this.view.formData, "municipality", customerLocation.data.data.municipality);
      this.view.$set(this.view.formData, "city", customerLocation.data.data.city);
      this.view.$set(this.view.formData, "neighborhood", customerLocation.data.data.neighborhood);

      const secondRequests = [
        this.api.neighborhoods.find(`PostalCodeId=${customerLocation.data.data.postalCode.id}&Skip=0&Limit=10000&IsActive=true`)
      ]
      const [
        neighborhoods,
      ] = await Promise.all(secondRequests);

      this.view.$set(this.view, "neighborhoods", neighborhoods.data.data);

      this.view.onFindCustomerLocationResponse(customerLocation.data.data);
      
    } catch (error) {
      this.view.onError(error)
    }
  }

  createPostalCode(data) {
    this.api.postalCodes
      .create(data)
      .then((response) => {
        return this.api.postalCodes.findOne(response.data.data);
      })
      .then((response) => {
        this.view.onCreatePostalCodeResponse(new PostalCode(response.data.data));
      })
      .catch(this.view.onError);
  }

  mapPostalCodes(collection) {
    return collection.map((item) => {
      return new PostalCode(item);
    });
  }

  mapPlaces(collection){
    return collection.map((item) => new Place(item))
  }

  getPostalCodeRequirements(formData) {
    let result = []
    
    if(_.isNil(formData.country)) {
      result.push("País requerido")
    }

    if(_.isNil(formData.district)) {
      result.push("Estado requerido")
    }

    if(_.isNil(formData.municipality)) {
      result.push("Municipio requerido")
    }

    if(_.isNil(formData.city)) {
      result.push("Ciudad requerido")
    }

    return result;
  }

  getNeighborhoodRequirements(formData){
    let result = []
    
    if(_.isNil(formData.postalCode)) {
      result.push("Código Postal requerido")
    }

    if(_.isNil(formData.country)) {
      result.push("País requerido")
    }

    if(_.isNil(formData.district)) {
      result.push("Estado requerido")
    }

    if(_.isNil(formData.municipality)) {
      result.push("Municipio requerido")
    }

    if(_.isNil(formData.city)) {
      result.push("Ciudad requerido")
    }

    return result;
  }
}
