export default class CustomerVerificationCode {
    constructor(options, state) {
      if(state){
        this.state = state
      }
      Object.assign(this, options)
    }
    
    toSend() {
      return {
        id: this.id,
        description: this.description,
        verificationCode: this.verificationCode,
        customerId: this.customerId,
        accountResponsibleId: (this.accountResponsible) ? this.accountResponsible.id : null
      }
    }
  }