<script>
import EditCustomerBoxComponent from "./EditCustomerBoxComponent";
import AddressBoxView from "../../shared/boxes/addressBox/AddressBoxView";

export default {
  components: {
    AddressBoxView,
  },
  extends: EditCustomerBoxComponent,
};
</script>
<template>
  <div>
    <el-card
      class="box-card"
      v-loading="isEditableLoading"
      style="height: 500px; overflow-x: hidden; overflow-y: auto; margin-bottom: 10px;"
    >
      <div slot="header" class="clearfix">
        <el-col :span="22"
          ><h5>
            <i class="uil-map-marker" /> {{ titleBox }} |
            {{ formData[`${prefixName}Place`].name }}
          </h5></el-col
        >
        <el-col :span="2" align="right"
          ><h5>
            <el-button
              v-if="!isEditable"
              @click="onEdit"
              type="default"
              size="small"
              ><i class="uil-edit-alt"
            /></el-button>
            <el-button
              v-if="isEditable"
              @click="onCancel"
              type="default"
              size="small"
              ><i class="uil-arrow-left"
            /></el-button></h5
        ></el-col>
      </div>
      <el-row v-if="hasCustomer()" type="flex" class="row-bg" :gutter="20">
        <el-col :span="24">
          <h5 class="field-details">
            Cliente:
          </h5>
        </el-col>
      </el-row>
      <el-row v-if="hasCustomer()" type="flex" class="row-bg" :gutter="20">
        <el-col :span="24">
          <p>
            {{ formData[`${prefixName}Location`].customer.description }}
          </p>
        </el-col>
      </el-row>
      <el-row
        v-if="hasCustomerLocation()"
        type="flex"
        class="row-bg"
        :gutter="20"
      >
        <el-col :span="24">
          <h5 class="field-details">
            Dirección:
          </h5>
        </el-col>
      </el-row>
      <el-row
        v-if="hasCustomerLocation()"
        type="flex"
        class="row-bg"
        :gutter="20"
      >
        <el-col :span="24">
          <p v-if="!isEditable">
            {{ formData[`${prefixName}Location`].getDescriptionFullAddress() }}
          </p>
          <address-box-view
            v-if="isEditable"
            :form-data="formData[`${prefixName}Location`]"
            :prop-name="`${propName}Location`"
            orientation="vertical"
            :event-after-load="`on-${prefixName}-address-load`"
          />
        </el-col>
      </el-row>
      <el-row type="flex" class="row-bg" :gutter="20">
        <el-col :span="24">
          <h5 class="field-details">
            Nombre:
          </h5>
        </el-col>
      </el-row>
      <el-row type="flex" class="row-bg" :gutter="20">
        <el-col :span="24">
          <p v-if="!isEditable">{{ formData[`${prefixName}Name`] }}</p>
          <p v-if="isEditable">
            <el-input
              v-model="formData[`${prefixName}Phone`]"
              type="text"
              size="medium"
            />
          </p>
        </el-col>
      </el-row>
      <el-row type="flex" class="row-bg" :gutter="20">
        <el-col :span="8">
          <h5 class="field-details">
            Teléfono:
          </h5>
        </el-col>
        <el-col :span="16">
          <h5 class="field-details">
            Correo:
          </h5>
        </el-col>
      </el-row>
      <el-row type="flex" class="row-bg" :gutter="20">
        <el-col :span="8">
          <p v-if="!isEditable">{{ formData[`${prefixName}Phone`] }}</p>
          <p v-if="isEditable">
            <el-input
              v-model="formData[`${prefixName}Phone`]"
              type="text"
              size="medium"
            />
          </p>
        </el-col>
        <el-col :span="16">
          <p v-if="!isEditable">{{ formData[`${prefixName}Email`] }}</p>
          <p v-if="isEditable">
            <el-input
              v-model="formData[`${prefixName}Email`]"
              type="text"
              size="medium"
            />
          </p>
        </el-col>
      </el-row>
      <el-row type="flex" class="row-bg" :gutter="20">
        <el-col :span="24">
          <h5 class="field-details">
            Referencia:
          </h5>
        </el-col>
      </el-row>
      <el-row type="flex" class="row-bg" :gutter="20">
        <el-col :span="24">
          <p v-if="!isEditable">{{ formData[`${prefixName}Observation`] }}</p>
          <p v-if="isEditable">
            <el-input
              v-model="formData[`${prefixName}Observation`]"
              type="textarea"
            />
          </p>
        </el-col>
      </el-row>
      <el-row type="flex" class="row-bg" :gutter="20" v-if="isEditable">
        <el-col :span="24" align="center">
          <el-button type="primary" @click="onUpdateCustomer"
            >Actualizar Información</el-button
          >
        </el-col>
      </el-row>
    </el-card>
  </div>
</template>
