<script>
import AdminMenuView from '../shared/menus/AdminMenuView.vue'
import AdminHeaderView from '../shared/headers/AdminHeaderView.vue'
import AdminFooterView from '../shared/footers/AdminFooterView'
import ModuleGpsTrackingComponent from './ModuleGpsTrackingComponent'
import MapTrackingGpsView from './MapTrackingGpsView'

export default {
    components: {
        'admin-menu-view': AdminMenuView,
        'admin-header-view': AdminHeaderView,
        'admin-footer-view': AdminFooterView,
        'map-tracking-gps-view': MapTrackingGpsView,
    },
    extends: ModuleGpsTrackingComponent,
}
</script>

<template>
  <div id="layout-wrapper">
    <admin-header-view />
    <admin-menu-view />
    <div class="main-content">
      <div class="page-content">
        <div class="container-fluid">
          <!-- start page title -->
          <div class="row">
            <div class="col-12">
              <div class="page-title-box d-flex align-items-center justify-content-between">
                <h4 class="mb-0">
                  <i class="uil-map-marker module-header-icon" /> Rastreo GPS
                </h4>

                <div class="page-title-right">
                  <el-button-group>
                    <el-button type="default" plain icon="fa fa-filter" size="small" @click="onMoreFilters">
                      Filtrar por:
                    </el-button>
                  </el-button-group>
                </div>
              </div>
            </div>
          </div>
          <!-- end page title -->
          <div class="row">
            <div class="col-lg-12">
              <div class="card">
                <map-tracking-gps-view />
              </div>
            </div>
          </div>
        </div> <!-- container-fluid -->
      </div>
      <!-- End Page-content -->
      <!-- footer -->
    </div>
    <admin-footer-view />
    <el-drawer
      custom-class="filters-drawer"
      :visible.sync="isMoreFilterVisible"
      direction="rtl"
      size="20%"
    >
      <template slot="title">
        <span class="title">Filtros</span>
        <el-button class="btn-text" type="text" @click="onClearFilters"
          >Borrar todos los filtros</el-button
        >
      </template>
      <el-row class="row-bg wrapper-more-filters" :gutter="20">
        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
          <div class="block">
            <span class="block-title">Operador:</span>
            <el-select
              v-model="filtersSelected.operator"
              filterable
              placeholder="Seleccionar"
              size="small"
              clearable
              @change="onOperatorChange"
            >
              <el-option
                v-for="item in operators"
                :key="item.id"
                :label="item.firstName + ' ' + item.lastName"
                :value="item.id"
              />
            </el-select>
          </div>
        </el-col>
      </el-row>
      <el-row class="row-bg wrapper-more-filters" :gutter="20">
        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
          <div class="block">
            <span class="block-title">Descripción:</span>
            <el-input
              type="text"
              placeholder="Buscar por descripción..."
              v-model="filtersSelected.description"
              clearable
              size="small"
              v-on:input="onSearchByDescription"
            ></el-input>
          </div>
        </el-col>
      </el-row>
      <el-row
        class="row-bg wrapper-more-filters"
        :gutter="20"
      >
        <el-col
          :xs="24"
          :sm="24"
          :md="24"
          :lg="24"
          :xl="24"
        >
          <div class="block">
            <span class="block-title">Fecha:</span>
            <el-date-picker
              v-model="filtersSelected.mapTrackingDate"
              type="daterange"
              size="small"
              clearable
              range-separator="-"
              start-placeholder="F. Inicio"
              end-placeholder="F. Fin"
              value-format="yyyy-MM-dd"
              @input="onSearchMapTrackingDate"
            />
          </div>
        </el-col>
      </el-row>
    </el-drawer>
  </div>
</template>