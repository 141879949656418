<script>
import ReceptionEditComponent from "./ReceptionEditComponent";
import EditCustomerBoxView from './boxes/EditCustomerBoxView'

export default {
  extends: ReceptionEditComponent,
  components:  {
    'edit-customer-box-view': EditCustomerBoxView
  }
};
</script>
<template>
  <div>
    <el-row>
      <el-col :span="24">
        <el-row
          type="flex"
          class="row-bg"
          :gutter="20"
          style="padding-top:15px;"
        >
          <el-col
            :span="6"
            align="center"
          >
            <h4>GUÍA DE ENVÍO</h4>
            <el-row
              type="flex"
              class="row-bg"
              :gutter="20"
            >
              <el-col :span="24">
                <h3 class="reception-resume-header">
                  <i class="uil-qrcode-scan" />
                  {{ formData.trackingNumber }}
                </h3>
              </el-col>
            </el-row>
          </el-col>
          <el-col
            :span="6"
            align="center"
          >
            <h4>FECHA DE RECEPCIÓN</h4>
            <el-row
              type="flex"
              class="row-bg"
              :gutter="20"
            >
              <el-col :span="24">
                <h3 class="reception-resume-header">
                  <i class="uil-calendar-alt" />
                  {{ formData.receptionDate | formatDate }}
                </h3>
              </el-col>
            </el-row>
          </el-col>
          <el-col
            :span="6"
            align="center"
          >
            <h4>TIPO DE RECEPCIÓN</h4>
            <el-row
              type="flex"
              class="row-bg"
              :gutter="20"
            >
              <el-col :span="24">
                <h3 class="reception-resume-header">
                  {{ formData.receptionTypeName }}
                </h3>
              </el-col>
            </el-row>
          </el-col>
          <el-col
            :span="6"
            align="center"
          >
            <h4>SEGUIMIENTO</h4>
            <el-row
              type="flex"
              class="row-bg"
              :gutter="20"
            >
              <el-col :span="24">
                <h3 class="reception-resume-header">
                  <i :class="onGetIconTrackingStatus(formData.status)" />
                  {{ formData.statusName }}
                </h3>
              </el-col>
            </el-row>
          </el-col>
        </el-row>
      </el-col>
    </el-row>
    <el-row
      type="flex"
      class="row-bg"
      :gutter="20"
    >
      <el-col :span="24">
        <el-tabs v-model="tabActive">
          <el-tab-pane
            label="Detalles"
            name="reception-details"
          >
            <el-row
              type="flex"
              class="row-bg reception-info-wrapper"
              :gutter="20"
            >
              <el-col :span="24">
                <el-row
                  type="flex"
                  class="row-bg reception-info-details"
                  :gutter="20"
                >
                  <el-col :span="6">
                    <el-card class="box-card">
                      <div
                        slot="header"
                        class="clearfix"
                      >
                        <h5><i class="uil-check-circle" /> Responsable de Pago</h5>
                      </div>
                      <div>
                        {{ onGetPayerResponsible(formData.payerResponsible) }}
                      </div>
                    </el-card>
                  </el-col>
                  <el-col :span="6">
                    <el-card class="box-card">
                      <div
                        slot="header"
                        class="clearfix"
                      >
                        <h5>
                          <i class="uil-check-circle" /> Tipo de Servicio
                        </h5>
                      </div>
                      <div>
                        {{ formData.receptionServiceTypeName }}
                      </div>
                    </el-card>
                  </el-col>
                  <el-col :span="6">
                    <el-card class="box-card">
                      <div
                        slot="header"
                        class="clearfix"
                      >
                        <h5>
                          <i class="uil-check-circle" /> Cobrase o Devuelvase
                        </h5>
                      </div>
                      <div class="box-input-body">
                        {{
                          formData.paymentAgainstDelivery | formatMXNCurrency
                        }}
                      </div>
                    </el-card>
                  </el-col>
                  <el-col :span="6">
                    <el-card class="box-card">
                      <div
                        slot="header"
                        class="clearfix"
                      >
                        <h5><i class="uil-check-circle" /> Referencia</h5>
                      </div>
                      <div class="box-input-body">
                        <el-input
                          v-model="formData.documentReference"
                          size="medium"
                          type="text"
                        />
                      </div>
                    </el-card>
                  </el-col>
                </el-row>
                <el-row
                  type="flex"
                  class="row-bg reception-info-details"
                  :gutter="20"
                >
                  <el-col :span="12">
                    <edit-customer-box-view
                      :form-data="formData"
                      :title-box="'Remitente'"
                      :prefix-name="'sender'"
                      :prop-name="'sender'"
                    />
                    
                  </el-col>
                  <el-col :span="12">
                    <edit-customer-box-view
                      :form-data="formData"
                      :title-box="'Destinatario'"
                      :prefix-name="'receiver'"
                      :prop-name="'receiver'"
                    />
                  </el-col>
                </el-row>
                <el-row
                  type="flex"
                  class="row-bg reception-info-details"
                  :gutter="20"
                >
                  <el-col :span="6">
                    <el-card class="box-card">
                      <div
                        slot="header"
                        class="clearfix"
                      >
                        <h5><i class="uil-check-circle" /> Total Flete</h5>
                      </div>
                      <div class="box-input-body">
                        {{ formData.sale.subtotal | formatMXNCurrency }}
                      </div>
                    </el-card>
                  </el-col>
                  <el-col :span="6">
                    <el-card class="box-card">
                      <div
                        slot="header"
                        class="clearfix"
                      >
                        <h5><i class="uil-check-circle" /> Total Descuento</h5>
                      </div>
                      <div class="box-input-body">
                        {{
                          formData.discountPercentage + '%'
                        }}
                      </div>
                    </el-card>
                  </el-col>
                  <el-col :span="6">
                    <el-card class="box-card">
                      <div
                        slot="header"
                        class="clearfix"
                      >
                        <h5><i class="uil-check-circle" /> Total Servicio</h5>
                      </div>
                      <div class="box-input-body">
                        {{
                          formData.sale.total | formatMXNCurrency
                        }}
                      </div>
                    </el-card>
                  </el-col>
                  <el-col :span="6">
                    <el-card class="box-card">
                      <div
                        slot="header"
                        class="clearfix"
                      >
                        <h5><i class="uil-check-circle" /> Pagado</h5>
                      </div>
                      <div class="box-input-body">
                        <el-checkbox
                          v-model="formData.freightPayable"
                          label="Pagado"
                          border
                        />
                      </div>
                    </el-card>
                  </el-col>
                </el-row>
              </el-col>
            </el-row>
          </el-tab-pane>
          <el-tab-pane
            label="Paquetes"
            name="packages"
          >
            <el-table
              :data="formData.receptionPackages"
              show-summary
              sum-text="Total"
              :summary-method="getSummaries"
              style="width: 100%"
            >
              <el-table-column
                label="Indice"
                width="100"
                align="center"
              >
                <template #default="scope">
                  {{ scope.$index + 1 }}
                </template>
              </el-table-column>
              <el-table-column
                prop="description"
                label="Descripción"
              />
              <el-table-column
                label="Tipo"
                width="200"
                align="center"
              >
                <template #default="scope">
                  <div v-if="scope.row.productType">
                    {{ scope.row.productType.name }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                label="Embalaje"
                width="160"
                align="center"
              >
                <template #default="scope">
                  <div v-if="scope.row.packagingType">
                    {{ scope.row.packagingType.name }} ({{ scope.row.packagingType.code }})
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                label="U.M."
                width="140"
                align="center"
              >
                <template #default="scope">
                  {{ scope.row.measureUnit.name }} ({{ scope.row.measureUnit.code }})
                </template>
              </el-table-column>
              <el-table-column
                prop="quantity"
                label="Cantidad"
                width="100"
                align="center"
              />
              <el-table-column
                prop="finalWeight"
                label="Peso"
                width="120"
                align="center"
              />
              <el-table-column
                prop="extraWeight"
                label="Sobre Peso"
                width="120"
                align="center"
              >
                <template slot-scope="scope">
                  <div v-if="scope.row.extraWeight">
                    {{ scope.row.extraWeight | 3Digits }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                label="V. Mercancia"
                width="120"
                align="center"
              >
                <template slot-scope="scope">
                  <div v-if="scope.row.merchandiseValue">
                    {{ scope.row.merchandiseValue | 3Digits }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                label="Moneda"
                width="110"
                align="center"
              >
                <template slot-scope="scope">
                  <div v-if="scope.row.currency">
                    {{ scope.row.currency.code }}
                  </div>
                </template>
              </el-table-column>
              <!-- <el-table-column
                prop="subtotal"
                label="Costo"
                width="140"
                align="center"
              >
                <template #default="scope">
                  {{ scope.row.subtotal | formatMXNCurrency }}
                </template>
              </el-table-column> -->
            </el-table>
          </el-tab-pane>
        </el-tabs>
      </el-col>
    </el-row>
  </div>
</template>
