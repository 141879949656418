import _ from 'lodash';
import BaseList from "../infraestructure/BaseList";
import OrderViewModel from "../../core/features/orders/OrderViewModel";

export default {
    extends: BaseList,
    data() {
        return {
            modulePermissions: "Order",
            viewModel: null,
            isMoreFilterVisible: false,
            filtersSelected: {
                orderNumber: '',
                contactName: '',
                contactPhone: '',
                email: '',
                operator: '',
                route: '',
                status: '',
            },
            orderStatuses: null,
            collectionRoutes: [],
            routes: [],
            operators: [],
            modalImport: {
                isVisible: false,
                isLoading: false,
                title: "IMPORTAR MONEDAS",
                isLoadingText: "Cargando...",
                onUploadImport: "on-upload-import-orders",
                onCancelImport: "on-cancel-import-orders",
            },
        };
    },
    mounted() {
        this.onMountedEvent("on-submited-order", this.onSearch);
        this.onMountedEvent("on-upload-import-orders", this.onUploadImport);
        this.onMountedEvent("on-cancel-import-orders", this.onCancelImport);
    },
    beforeDestroy() {
        this.onBeforeDestroyEvent("on-submited-order", this.onSearch);
        this.onBeforeDestroyEvent("on-upload-import-orders", this.onUploadImport);
        this.onBeforeDestroyEvent("on-cancel-import-orders", this.onCancelImport);
    },
    created() {
        this.viewModel = new OrderViewModel(this);
        this.onActive();
    },

    methods: {
        onActive() {
            this.isListLoading = true;
            this.viewModel.bindList()
        },
        onSelectOrder() { },
        onCreate() {
            this.$confirm(
                "¿Desea generar nueva orden de recolección?",
                "Información",
                {
                    confirmButtonText: "Generar",
                    cancelButtonText: "Cancelar",
                    type: "info",
                }
            )
                .then(() => {
                    this.isListLoading = true
                    setTimeout(() => {
                        let order = this.viewModel.create({ isActive: true });
                        this.viewModel.save(order);
                    }, 400);
                })
                .catch(() => { });
        },
        onMoreFilters() {
            this.isMoreFilterVisible = true
        },
        onSearchByOrderNumber: _.debounce(function (text) {
            if (text) {
                this.addFilter('OrderNumber', text)
            } else {
                this.removeFilter('OrderNumber')
            }
            this.onSearch();
        }, 500),
        onSearchByContactName: _.debounce(function (text) {
            if (text) {
                this.addFilter('ContactName', text)
            } else {
                this.removeFilter('ContactName')
            }
            this.onSearch();
        }, 500),
        onSearchByContactPhone: _.debounce(function (text) {
            if (text) {
                this.addFilter('ContactPhone', text)
            } else {
                this.removeFilter('ContactPhone')
            }
            this.onSearch();
        }, 500),
        onSearchByEmail: _.debounce(function (text) {
            if (text) {
                this.addFilter('ContactEmail', text)
            } else {
                this.removeFilter('ContactEmail')
            }
            this.onSearch();
        }, 500),
        onChangeOperator(data) {
            if (data) {
                this.addFilter("EmployeeProfileId", data);
            } else {
                this.removeFilter("EmployeeProfileId");
            }
            this.onSearch();
        },
        onChangeRoute(data) {
            if (data) {
                this.addFilter("CollectionRouteId", data);
            } else {
                this.removeFilter("CollectionRouteId");
            }
            this.onSearch();
        },
        searchOrderStatusFilter(data) {
            if (data !== null) {
                this.addFilter("Status", data);
            } else {
                this.removeFilter("Status");
            }
            this.onSearch();
        },
        searchStatusFilter(data) {
            if (data !== null) {
                this.addFilter('IsActive', data)
            } else {
                this.removeFilter('IsActive')
            }
            this.onSearch()
        },
        onClearFilters() {
            this.viewModel.allClearFilters();
        },
        onSaveResponse(response) {
            setTimeout(() => {
                this.$alert(`La nueva orden de recolección generada es: ${response.data}`, 'Orden Generada', {
                    confirmButtonText: 'Aceptar',
                    callback: () => {
                        this.onSearch();
                    }
                });
                this.isListLoading = false
            }, 400);
        },
        onSaveResponseError(error) {
            this.onError(error);
        },       
        getStatusTagStyle(status) {
            if (!status) {
                return "default";
            } else if (status === 1) {
                return "info";
            } else if (status === 2) {
                return "warning";
            } else if (status === 3) {
                return "success";
            }
        },
        getStatusIcon(status) {
            if (!status) {
                return "";
            } else if (status === 1) {
                return "el-icon-info";
            } else if (status === 2) {
                return "el-icon-warning";
            } else if (status === 3) {
                return "el-icon-success";
            }
        },
        onChangeCollectionRoute(order) {
            this.viewModel.setCollectionRoute(order.collectionRouteTraceId, {
                orderId: order.id,
                receptionId: order.receptionId,
                collectionRouteId: order.collectionRoute
                    ? order.collectionRoute.id
                    : null,
            });
        },
        onSetCollectionRouteResponse() {
            this.onSearch();
        },
        onChangeOption(event) {
            switch (event.command) {
                case "edit":
                    this.onEdit(event.item.id);
                    break;
                case "delete":
                    this.onDelete(event.item.id);
                    break;
                case "print-order-tickets":
                    this.onPrintOrderTickets(event.item.orderNumber);
                    break;
                default:
                    this.onError("Opción no valida");
                    break;
            }
        },
        onPrintOrderTickets(orderNumber) {
            this.isListLoading = true;
            this.viewModel.printOrderTickets(orderNumber);
        },
        onPrintOrderTicketsResponse(response) {
            setTimeout(() => {
                this.viewModel.addDownloadEvent(
                    response.data,
                    response.fileName,
                    "application/pdf",
                    () => {
                        this.notifySuccess("Etiquetdas generada.");
                        this.isListLoading = false;
                    }
                );
            }, 400);
        },
        onPrintOrderTicketsError(error) {
            this.isListLoading = false;
            this.onError(error)
        }
    },
};
