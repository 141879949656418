import BaseViewModel from "../../infraestructure/BaseViewModel";
import User from "../users/User";
import CollectionRoute from "../collectionRoutes/CollectionRoute";
import Order from "./Order";

export default class OrdersViewModel extends BaseViewModel {
    constructor(view) {
        super(view);
    }

    create(data) {
        return new Order(data, "create");
    }

    edit(data) {
        return new Order(data, "update");
    }

    save(data) {
        if (data.state === "create") {
            this.api.orders
                .create(data.toSend())
                .then((response) => this.view.onSaveResponse(response.data))
                .catch(this.view.onSaveResponseError);
        } else {
            this.api.orders
                .update(data.id, data.toSend())
                .then((response) => this.view.onSaveResponse(response.data))
                .catch(this.view.onError);
        }
    }

    delete(id) {
        this.api.orders
            .delete(id)
            .then((response) => this.view.onDeleteResponse(response.data))
            .catch(this.view.onError);
    }

    findCollection(filters) {
        this.api.orders
            .find(this.getQueryParameters(filters))
            .then((response) => {
                this.view.onSearchResponse(response.data, response.headers);
                return this.api.collectionRoutes.find(`Skip=0&Limit=100000`);
            })
            .then((response) => {
                this.view.collectionRoutes = response.data.data;
            })
            .catch(this.view.onError);
    }

    findItem(id) {
        this.api.orders
            .findOne(id)
            .then((response) => this.view.onFindItemResponse(response.data))
            .catch(this.view.onError);
    }

    bindForm(formData) {
        console.log(formData);
    }

    import(file) {
        this.api.orders
            .import(file)
            .then((response) => this.view.onImportResponse(response.data))
            .catch(this.view.onError);
    }

    bindList() {
        this.api.orderStatuses.find(`Skip=0&Limit=1000000&IsActive=true`)
            .then((response) => {
                this.view.orderStatuses = response.data.data;
                return this.api.users.find(`skip=0&limit=50000&IsActive=true&isTransportFigure=true`);
            })
            .then((response) => {
                this.view.operators = this.mapUsers(response.data.data)
                return this.api.collectionRoutes.find(`skip=0&limit=50000&IsActive=true`);
            })
            .then((response) => {
                this.view.routes = this.mapCollectionRoutes(response.data.data)
                
                setTimeout(() => {
                    this.view.isListLoading = false;
                    this.view.onSearch()
                }, 400);
            })
            .catch(this.view.onError);
    }

    allClearFilters() {
        this.view.filtersSelected = {
            orderNumber: '',
            contactName: '',
            contactPhone: '',
            email: '',
            operator: '',
            route: '',
            status: '',
        };

        this.view.removeFilter('OrderNumber');
        this.view.removeFilter('ContactName');
        this.view.removeFilter('ContactPhone');
        this.view.removeFilter('ContactEmail');
        this.view.removeFilter('EmployeeProfileId');
        this.view.removeFilter('CollectionRouteId');
        this.view.removeFilter('Status');
        this.view.removeFilter('IsActive');
        this.view.onSearch();
    }


    printOrderTickets(orderNumber) {
        this.api.orders.findPickingOrder(orderNumber)
            .then((response) => this.view.onPrintOrderTicketsResponse(response.data))
            .catch(this.view.onPrintOrderTicketsError)
    }

    findOperator(criteria) {
        this.api.employees
            .find(criteria)
            .then((response) => this.view.onFindOperatorResponse(response.data))
            .catch(this.view.onError);
    }

    findRoute(criteria) {
        this.api.collectionRoutes
            .find(criteria)
            .then((response) => this.view.onFindRouteResponse(response.data))
            .catch(this.view.onError);
    }

    //#region

    mapCollection(collection) {
        return collection.map((item) => new Order(item));
    }

    mapUsers(collection) {
        return collection.map((item) => new User(item));
    }

    mapCollectionRoutes(collection) {
        return collection.map((item) => new CollectionRoute(item));
    }

    //#endregion

    verifyRFC(value, callback) {
        this.api.bills
            .verifyRfc(value)
            .then((response) => {
                setTimeout(() => {
                    if (response.data.isValid) {
                        callback();
                    } else {
                        callback(new Error(response.data.message));
                    }
                    this.view.config.isLoading = false;
                }, 400);
            })
            .catch(this.view.onError);
    }

    setCollectionRoute(id, data) {
        if (!id) {
            this.api.collectionRouteTraces
                .create(data)
                .then((response) =>
                    this.view.onSetCollectionRouteResponse(response.data)
                )
                .catch(this.view.onError);
        } else {
            this.api.collectionRouteTraces
                .update(id, data)
                .then((response) =>
                    this.view.onSetCollectionRouteResponse(response.data)
                )
                .catch(this.view.onError);
        }
    }
}
