import BaseApi from '../../infraestructure/BaseApi'

export default class CustomerSolvencyApi extends BaseApi {
    constructor(client, endPoint) {
        super(client, endPoint)
    }

    create(customerId, data) {
        return this.client.post(`${this.endPoint}/${customerId}/solvencies`, data)
    }

    update(customerId, id, data) {
        return this.client.put(`${this.endPoint}/${customerId}/solvencies/${id}`, data)
    }

    delete(customerId, id) {
        return this.client.delete(`${this.endPoint}/${customerId}/solvencies/${id}`)
    }

    find(customerId, criteria) {
        const parameters = (criteria) ? `?${criteria}` : ''
        return this.client.get(`${this.endPoint}/${customerId}/solvencies${parameters}`)
    }

    findOne(customerId, id) {
        return this.client.get(`${this.endPoint}/${customerId}/solvencies/${id}`)
    }

    findReceptionsBySolvency(customerId, solvencyId) {
        return this.client.get(`${this.endPoint}/${customerId}/solvencies/${solvencyId}/receptions`)
    }
}