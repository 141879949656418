<script>
import ModalReceptionsCustomerComponent from "./ModalReceptionsCustomerComponent";
import AddressBoxView from "../shared/boxes/addressBox/AddressBoxView";

export default {
  components: {
    AddressBoxView,
  },
  extends: ModalReceptionsCustomerComponent,
};
</script>

<template>
  <el-dialog
    custom-class="dialog"
    :visible.sync="config.isVisible"
    :before-close="onCancel"
    :close-on-click-modal="false"
    :append-to-body="true"
  >
    <span slot="title" class="dialog-title">
      <i class="uil-comment-notes" /> Crear Cliente
    </span>

    <el-form
      ref="formData"
      v-loading="config.isLoading"
      :model="formData"
      :rules="rules"
      :label-position="'top'"
    >
      <el-row type="flex" class="row-bg" :gutter="20">
        <el-col :span="18">
          <el-form-item label="Descripción/Alias de Cliente" prop="description">
            <el-input
              v-model="formData.description"
              autocomplete="off"
              size="medium"
            />
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="Descuento (%)" prop="discount">
            <el-input-number
              ref="discount"
              v-model="formData.discountPercentage"
              autocomplete="off"
              autofocus="true"
              size="medium"
              :step="5"
              :min="0"
              :max="100"
              step-strictly
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row type="flex" class="row-bg" :gutter="20">
        <el-col :span="9">
          <el-form-item label="Nombre de Contacto" prop="contactName">
            <el-input
              v-model="formData.contactName"
              autocomplete="off"
              size="medium"
            />
          </el-form-item>
        </el-col>
        <el-col :span="10">
          <el-form-item
            label="Correo Electrónico de Contacto"
            prop="contactEmail"
          >
            <el-input
              v-model="formData.contactEmail"
              autocomplete="off"
              size="medium"
            />
          </el-form-item>
        </el-col>
        <el-col :span="5">
          <el-form-item label="Tel. de Contacto" prop="contactPhone">
            <el-input
              v-model="formData.contactPhone"
              autocomplete="off"
              size="medium"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-tabs v-model="activeName">
        <el-tab-pane label="Datos Fiscales" name="first">
          <el-row type="flex" class="row-bg" :gutter="20">
            <el-col :span="16">
              <el-form-item label="Regimen Fiscal" prop="taxRegime">
                <el-select
                  v-model="formData.taxRegime"
                  placeholder="Seleccionar"
                  filterable
                  clearable
                  debounce
                  value-key="id"
                  size="large"
                >
                  <el-option
                    v-for="item in taxRegimes"
                    :key="item.id"
                    :label="item.getFullDescription()"
                    :value="item"
                  />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="Dirección Fiscal (C.P.)" prop="fiscalPostalCode">
                <el-select
                  v-model="formData.fiscalPostalCode"
                  placeholder="Seleccionar"
                  filterable
                  clearable
                  debounce
                  value-key="id"
                  size="large"
                  remote
                  :remote-method="onFindPostalCodes"
                >
                  <el-option
                    v-for="item in postalCodes"
                    :key="item.id"
                    :label="item.getFullDescription()"
                    :value="item"
                  />
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row type="flex" class="row-bg" :gutter="20">
            <el-col :span="16">
              <el-form-item label="Razón Social" prop="fiscalName">
                <el-input
                  v-model="formData.fiscalName"
                  autocomplete="off"
                  size="medium"
                />
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="RFC" prop="fiscalCode">
                <el-input
                  placeholder="Ingresar R.F.C"
                  v-model="formData.fiscalCode"
                  autocomplete="off"
                  size="medium"
                >
                  <template slot="append">
                    <i
                      v-if="!isRfcSearching"
                      class="el-icon-search"
                      id="ic_search_rfc_icon"
                    />
                    <i v-else class="el-icon-loading" id="ic_search_rfc_icon" />
                    
                  </template>
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-tab-pane>
        <el-tab-pane label="Datos de Ubicación" name="second">
          <el-row type="flex" class="row-bg" :gutter="20">
            <el-col :span="24">
              <el-form-item
                label="Descripción/Alias de Ubicación"
                prop="address.description"
              >
                <el-input
                  v-model="formData.address.description"
                  autocomplete="off"
                  size="medium"
                />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row type="flex" class="row-bg" :gutter="20">
        <el-col :span="6">
          <el-form-item label="Código de Cliente" prop="code">
            <el-input ref="customerCode" v-model="formData.address.customerCode" autocomplete="off" autofocus="true"
              size="medium" />
          </el-form-item>
        </el-col>
        <el-col :span="11" align="center">
          <el-form-item label="¿Mostrar código de cliente en documentación?">
            <el-switch v-model="formData.address.isCustomerCodeDocUsed" active-text="Si" inactive-text="No" />
          </el-form-item>
        </el-col>
      </el-row>
          <address-box-view
            orientation="vertical"
            :form-data="formData.address"
            prop-name="address"
          />
        </el-tab-pane>
      </el-tabs>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button size="small" @click="onCancel">Cancelar</el-button>
      <el-button type="primary" size="small" @click="onSubmitForm('formData')"
        >Guardar</el-button
      >
    </span>
  </el-dialog>
</template>
