<script>
import ModalEmployeeComponent from "./ModalEmployeeComponent";
import AddressBoxView from "../shared/boxes/addressBox/AddressBoxView";

export default {
  components: {
    "address-box-view": AddressBoxView,
  },
  extends: ModalEmployeeComponent,
};
</script>

<template>
  <el-dialog
    custom-class="dialog-employee dialog"
    :visible.sync="config.isVisible"
    :before-close="onCancel"
    :close-on-click-modal="false"
  >
    <span
      slot="title"
      class="dialog-title"
    >
      <i class="uil-head-side" /> Empleado
    </span>
    <el-form
      ref="formData"
      v-loading="config.isLoading"
      :element-loading-text="config.isLoadingText"
      element-loading-spinner="el-icon-loading"
      :model="formData"
      :rules="rules"
      :label-position="'top'"
    >
      <el-tabs type="border-card">
        <el-tab-pane label="Info. Cuenta">
          <el-row
            type="flex"
            class="row-bg"
            :gutter="20"
          >
            <el-col :span="8">
              <el-form-item
                label="Sucursal"
                prop="branchOffice"
              >
                <el-select
                  v-model="formData.branchOffice"
                  placeholder="Seleccionar Sucursal"
                  filterable
                  clearable
                  debounce
                  value-key="id"
                  size="medium"
                  remote
                  :remote-method="onFindBranchOffices"
                >
                  <el-option
                    v-for="item in branchOffices"
                    :key="item.id"
                    :label="item.name"
                    :value="item"
                  />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item
                label="Rol"
                prop="roleName"
              >
                <el-select
                  v-model="formData.role"
                  placeholder="Seleccionar Rol"
                  filterable
                  clearable
                  debounce
                  value-key="id"
                  size="medium"
                >
                  <el-option
                    v-for="item in roles"
                    :key="item.id"
                    :label="item.name"
                    :value="item"
                  />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col
              :span="8"
              align="center"
            >
              <el-form-item label="Estado">
                <el-switch
                  v-model="formData.isActive"
                  active-text="Activo"
                  inactive-text="Inactivo"
                />
              </el-form-item>
            </el-col>
          </el-row>

          <el-row
            type="flex"
            class="row-bg"
            :gutter="20"
          >
            <el-col :span="8">
              <el-form-item
                label="Usuario"
                prop="userName"
              >
                <el-input
                  v-model="formData.userName"
                  autocomplete="off"
                  size="medium"
                />
              </el-form-item>
            </el-col>
            <el-col :span="16">
              <el-form-item
                type="email"
                label="Correo electrónico"
                prop="email"
              >
                <el-input
                  v-model="formData.email"
                  autocomplete="off"
                  size="medium"
                />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row
            type="flex"
            class="row-bg"
            :gutter="20"
          >
            <el-col
              v-if="formData.state === 'update'"
              :span="8"
            >
              <el-form-item
                prop="editPass"
                label="Editar Contraseña"
              >
                <el-checkbox
                  v-model="editPass"
                  border
                  @change="onClearFieldForm('formData')"
                >
                  Seleccionar casilla
                </el-checkbox>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item
                label="Contraseña"
                prop="password"
              >
                <el-input
                  v-model="formData.password"
                  autocomplete="off"
                  size="medium"
                  show-password
                  name="password"
                  :disabled="!editPass && formData.state === 'update'"
                />
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item
                label="Verificar Contraseña"
                prop="passwordConfirmation"
              >
                <el-input
                  v-model="formData.passwordConfirmation"
                  autocomplete="off"
                  name="passwordConfirmation"
                  size="medium"
                  show-password
                  :disabled="!editPass && formData.state === 'update'"
                />
              </el-form-item>
            </el-col>
          </el-row>
        </el-tab-pane>
        <el-tab-pane label="Info. Empleado">
          <el-row
            type="flex"
            class="row-bg"
            :gutter="20"
          >
            <el-col :span="12">
              <el-form-item
                label="Nombre"
                prop="firstName"
              >
                <el-input
                  v-model="formData.firstName"
                  autocomplete="off"
                  size="medium"
                />
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item
                label="Apellido(s)"
                prop="lastName"
              >
                <el-input
                  v-model="formData.lastName"
                  autocomplete="off"
                  size="medium"
                />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row
            type="flex"
            class="row-bg"
            :gutter="20"
          >
            <el-col :span="8">
              <el-form-item
                label="Identificación"
                prop="identificationNumber"
              >
                <el-input
                  v-model="formData.identificationNumber"
                  autocomplete="off"
                  size="medium"
                  name="identificationNumber"
                />
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item
                label="RFC"
                prop="federalTaxPayerRegistration"
              >
                <el-input placeholder="Ingresar R.F.C" v-model="formData.federalTaxPayerRegistration" 
                      autocomplete="off"
                      name="federalTaxPayerRegistration"
                      size="medium">
                      <template slot="append">
                        <i v-if="!isRfcSearching" class="el-icon-search" id="ic_search_rfc_icon" />
                        <i v-else class="el-icon-loading" id="ic_search_rfc_icon" />
                      </template>
                    </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item
                label="Figura Transporte"
                prop="figureTransportType"
              >
                <el-select
                  v-model="formData.figureTransportType"
                  placeholder="Seleccionar"
                  size="medium"
                  clearable
                >
                  <el-option
                    v-for="item in figureTransportTypes"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  />
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row
            type="flex"
            class="row-bg"
            :gutter="20"
          >
            <el-col
              :span="8"
              align="center"
            >
              <el-form-item label="¿Es Figura Transporte?">
                <el-switch
                  v-model="formData.isTransportFigure"
                  active-text="Si"
                  inactive-text="No"
                />
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item
                label="No. Licencia"
                prop="licenseNumber"
              >
                <el-input
                  v-model="formData.licenseNumber"
                  autocomplete="off"
                  name="licenseNumber"
                  size="medium"
                />
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item
                label="F. Vencimiento"
                prop="licenseExpiration"
              >
                <el-date-picker
                  v-model="formData.licenseExpiration"
                  type="date"
                  placeholder="Selecciónar Fecha"
                  size="medium"
                />
              </el-form-item>
            </el-col>
          </el-row>
        </el-tab-pane>
        <el-tab-pane label="Info. Dirección">
          <address-box-view
            :form-data="formData.address"
            prop-name="address"
            orientation="vertical"
          />
        </el-tab-pane>
      </el-tabs>
    </el-form>
    <span
      slot="footer"
      class="dialog-footer"
    >
      <el-button
        size="small"
        @click="onCancel"
      >Cancelar</el-button>
      <el-button
        type="primary"
        size="small"
        :disabled="!onCanSaveOrUpdate()"
        @click="onSubmitForm('formData')"
      >Guardar</el-button>
    </span>
  </el-dialog>
</template>
