<script>
import ListCustomerContactComponent from "./ListCustomerContactComponent";
import ModalCustomerContactView from "./ModalCustomerContactView";
import ModalImportView from "../shared/modals/ModalImportView";

export default {
  components: {
    "modal-customer-location-view": ModalCustomerContactView,
    "modal-import-view": ModalImportView,
  },
  extends: ListCustomerContactComponent,
};
</script>

<template>
  <div>
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="row mb-2">
              <div class="d-flex">
                <div class="col-2">
                  <div
                    id="entradas"
                    class="mb-3"
                  >
                    <span class="mx-2">Mostrar</span>
                    <el-select
                      v-model="defaultShowEntry"
                      placeholder="Opción"
                      size="small"
                      @change="onChangeEntries"
                    >
                      <el-option
                        v-for="item in showEntries"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      />
                    </el-select>
                    <span class="mx-2">registros </span>
                  </div>
                </div>
                <div class="col-6 me-2">
                  <div class="row mb-2">
                    <!-- <div class="col">
                      <el-input
                        v-model="searchCode"
                        placeholder="Buscar por código..."
                        prefix-icon="el-icon-search"
                        size="medium"
                        clearable
                        debounce
                        @input="searchCodeFilter"
                      />
                    </div>-->
                    <div class="col">
                      <el-input
                        v-model="searchDescription"
                        placeholder="Buscar por descripción..."
                        prefix-icon="el-icon-search"
                        size="medium"
                        clearable
                        debounce
                        @input="searchDescriptionFilter"
                      />
                    </div>
                    <div class="col">
                      <el-input
                        v-model="searchFiscalName"
                        placeholder="Buscar por Razón Social..."
                        prefix-icon="el-icon-search"
                        size="medium"
                        clearable
                        debounce
                        @input="searchFiscalNameFilter"
                      />
                    </div>
                  </div>
                  <div class="row mb-2">
                    <div class="col">
                      <el-input
                        v-model="searchContactName"
                        placeholder="Buscar por Contacto..."
                        prefix-icon="el-icon-search"
                        size="medium"
                        clearable
                        debounce
                        @input="searchContactNameFilter"
                      />
                    </div>
                    <div class="col">
                      <el-input
                        v-model="searchContactEmail"
                        placeholder="Buscar por Correo..."
                        prefix-icon="el-icon-search"
                        size="medium"
                        clearable
                        debounce
                        @input="searchContactEmailFilter"
                      />
                    </div>
                    <div class="col">
                      <el-input
                        v-model="searchFiscalCode"
                        placeholder="Buscar por RFC..."
                        prefix-icon="el-icon-search"
                        size="medium"
                        clearable
                        debounce
                        @input="searchFiscalCodeFilter"
                      />
                    </div>
                  </div>
                </div>
                <div class="col-4">
                  <div
                    id="buttonlist"
                    class="mb-3"
                  >
                    <el-dropdown
                      size="medium"
                      class="filter-action"
                    >
                      <el-button
                        type="danger"
                        size="medium"
                      >
                        <i class="uil-filter" /> Filtrar
                        <i class="el-icon-arrow-down el-icon--right" />
                      </el-button>
                      <el-dropdown-menu slot="dropdown">
                        <!--                         <el-dropdown-item class="my-2">
                          <el-select
                            v-model="searchCustomerType"
                            placeholder="Seleccionar Tipo de Cliente"
                            :popper-append-to-body="false"
                            size="medium"
                            clearable
                            @change="searchCustomerTypeFilter"
                          >
                            <el-option
                              v-for="item in customerTypes"
                              :key="item.id"
                              :label="item.name"
                              :value="item.id"
                            />
                          </el-select>
                        </el-dropdown-item> -->
                        <el-dropdown-item class="my-2">
                          <el-select
                            v-model="defaultStatus"
                            placeholder="Seleccionar Estado"
                            :popper-append-to-body="false"
                            size="medium"
                            clearable
                            @change="searchStatusFilter"
                          >
                            <el-option
                              v-for="item in statusOptions"
                              :key="item.value"
                              :label="item.label"
                              :value="item.value"
                            />
                          </el-select>
                        </el-dropdown-item>
                      </el-dropdown-menu>
                    </el-dropdown>
                    <!-- <el-dropdown
                      size="medium"
                      class="more-options-action"
                    >
                      <el-button
                        type="plain"
                        size="medium"
                      >
                        <i class="uil-ellipsis-v" /> M&aacute;s Opciones
                        <i class="el-icon-arrow-down el-icon--right" />
                      </el-button>
                      <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item class="my-2">
                          <el-button
                            size="medium"
                            @click="onShowImport"
                          >
                            <i class="el-icon-upload2" /> Importar
                          </el-button>
                        </el-dropdown-item>
                        <el-dropdown-item class="my-2">
                          <el-button
                            size="medium"
                            @click="onExport"
                          >
                            <i class="el-icon-download" /> Exportar
                          </el-button>
                        </el-dropdown-item>
                      </el-dropdown-menu>
                    </el-dropdown> -->
                    <el-button
                      v-if="onUserPermission('Permission.CustomersContact.Create')"
                      type="primary"
                      size="medium"
                      class="main-action"
                      @click="onCreate"
                    >
                      <i class="fas fa-plus-circle" /> Crear Contacto
                    </el-button>
                  </div>
                </div>
              </div>
            </div>
            <!-- end row -->
            <div class="table-responsive mb-4">
              <el-table
                v-if="onUserPermission('Permission.CustomersContact.Read')"
                ref="multipleTable"
                v-loading="isListLoading"
                class="table table-centered table-nowrap mb-0"
                :data="collection"
                style="width: 100%"
                @selection-change="onSelectCustomer"
              >
                <el-table-column
                  type="selection"
                  width="55"
                />

                <el-table-column
                  label="Descripción"
                  property="description"
                  show-overflow-tooltip
                  width="200"
                />
                <el-table-column
                  label="Nombre Contacto"
                  property="contactName"
                  show-overflow-tooltip
                />
                <el-table-column
                  label="Teléfono Contacto"
                  property="contactPhone"
                  show-overflow-tooltip
                />
                <el-table-column
                  property="contactEmail"
                  label="Email Contacto"
                  show-overflow-tooltip
                  align="center"
                />
                <el-table-column
                  property="fiscalCode"
                  label="R.F.C"
                  show-overflow-tooltip
                  align="center"
                />
                <el-table-column
                  property="fiscalName"
                  label="Razón Social"
                  show-overflow-tooltip
                  align="center"
                />
                <!--
                <el-table-column
                  label="Estado"
                  show-overflow-tooltip
                  align="center"
                  width="120"
                >
                  <template #default="scope">
                    <el-tag
                      v-if="scope.row.isActive"
                      type="success"
                      effect="plain"
                    >
                      <i class="el-icon-success" /> Activo
                    </el-tag>
                    <el-tag
                      v-if="!scope.row.isActive"
                      type="danger"
                      effect="plain"
                    >
                      <i class="el-icon-error" /> Inactivo
                    </el-tag>
                  </template>
                </el-table-column>
                -->
                <el-table-column
                  v-if="
                    onUserPermission('Permission.CustomersContact.Update') ||
                      onUserPermission('Permission.CustomersContact.Delete')
                  "
                  label="Opciones"
                  width="120"
                  align="center"
                  show-overflow-tooltip
                >
                  <template #default="scope">
                    <el-dropdown @command="onChangeOptionCustomerContacts">
                      <el-button size="small">
                        <i class="el-icon-more" />
                      </el-button>
                      <template #dropdown>
                        <el-dropdown-menu>
                          <el-dropdown-item
                            v-if="
                              onUserPermission('Permission.CustomersContact.Update')
                            "
                            :command="{
                              command: 'edit',
                              item: scope.row,
                              customerId: customerId,
                            }"
                          >
                            <i class="el-icon-edit" /> Mostrar
                          </el-dropdown-item>
                          <el-dropdown-item
                            v-if="
                              onUserPermission('Permission.CustomersContact.Delete')
                            "
                            :command="{
                              command: 'delete',
                              item: scope.row,
                              customerId: customerId,
                            }"
                          >
                            <i class="el-icon-delete" /> Eliminar
                          </el-dropdown-item>
                        </el-dropdown-menu>
                      </template>
                    </el-dropdown>
                  </template>
                </el-table-column>
              </el-table>
            </div>
            <div class="row mt-4">
              <div class="col-sm-6">
                <div>
                  <p class="mb-sm-0">
                    Mostrar 1 a {{ pagination.limit }} de
                    {{ pagination.totalItems }} registros
                  </p>
                </div>
              </div>
              <div class="col-sm-6">
                <div class="float-sm-end">
                  <el-pagination
                    :page-count="pagination.totalPages"
                    :current-page.sync="currentPage"
                    layout="prev, pager, next"
                    @current-change="onPageChange"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <modal-import-view :conf="modalImport" />
    <modal-customer-location-view
      v-if="modalConfig.isVisible"
      :form-data="item"
      :config="modalConfig"
    />
  </div>
</template>
