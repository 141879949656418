<script>
import ModalPlaceComponent from './ModalPlaceComponent'

export default {
    extends: ModalPlaceComponent,
}
</script>

<template>
  <el-dialog
    custom-class="dialog-place"
    :visible.sync="config.isVisible"
    :before-close="onCancel"
    :close-on-click-modal="false"
  >
    <span
      slot="title"
      class="dialog-title"
    >
      <i class="uil-map-marker" /> Localidad
    </span>
    <el-form
      ref="formData"
      v-loading="config.isLoading"
      :model="formData"
      :rules="rules"
      :label-position="'top'"
    >
      <el-row
        type="flex"
        class="row-bg"
        :gutter="20"
      >
        <el-col :span="7">
          <el-form-item
            label="Código"
            prop="code"
          >
            <el-input
              v-model="formData.code"
              autocomplete="off"
              size="medium"
              :disabled="true"
            />
          </el-form-item>
        </el-col>
        <el-col :span="7">
          <el-form-item
            label="Código SAT"
            prop="catalogNumber"
          >
            <el-input
              v-model="formData.catalogNumber"
              autocomplete="off"
              size="medium"
            />
          </el-form-item>
        </el-col>
        <el-col
          :span="10"
          align="center"
        >
          <el-form-item label="Estado">
            <el-switch
              v-model="formData.isActive"
              active-text="Activo"
              inactive-text="Inactivo"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row
        type="flex"
        class="row-bg"
        :gutter="20"
      >
        <el-col :span="24">
          <el-form-item
            label="Nombre"
            prop="name"
          >
            <el-input
              v-model="formData.name"
              autocomplete="off"
              size="medium"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row
        type="flex"
        class="row-bg"
        :gutter="20"
      >
        <el-col :span="24">
          <el-form-item
            label="Descripción"
            prop="description"
          >
            <el-input
              v-model="formData.description"
              autocomplete="off"
              size="medium"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row
        type="flex"
        class="row-bg"
        :gutter="20"
      >
        <el-col :span="24">
          <el-form-item
            label="País"
            prop="country"
          >
            <el-select
              v-model="formData.country"
              placeholder="Seleccionar País"
              value-key="id"
              size="medium"
              clearable
              filterable
              @change="onChangeCountry"
            >
              <el-option
                v-for="item in countries"
                :key="item.id"
                :label="item.name"
                :value="item"
              />
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row
        type="flex"
        class="row-bg"
        :gutter="20"
      >
        <el-col :span="24">
          <el-form-item
            label="Estado"
            prop="district"
          >
            <el-select
              v-model="formData.district"
              placeholder="Seleccionar estado"
              value-key="id"
              size="medium"
              clearable
              filterable
              @change="onChangeDistrict"
            >
              <el-option
                v-for="item in districts"
                :key="item.id"
                :label="item.name"
                :value="item"
              />
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <!-- <el-row
        type="flex"
        class="row-bg"
        :gutter="20"
      >
        <el-col :span="24">
          <el-form-item
            label="Municipio"
            prop="municipality"
          >
            <el-select
              v-model="formData.municipality"
              placeholder="Seleccionar municipio"
              value-key="id"
              size="medium"
              clearable
              filterable
            >
              <el-option
                v-for="item in municipalities"
                :key="item.id"
                :label="item.name"
                :value="item"
              />
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>-->
      <el-row
        type="flex"
        class="row-bg"
        :gutter="20"
      >
        <el-col
          :span="10"
          align="center"
        >
          <el-form-item label="¿Destino?">
            <el-switch
              v-model="formData.isDestination"
              active-text="Si"
              inactive-text="No"
              @change="onChangeIsDestination"
            />
          </el-form-item>
        </el-col>
        <el-col :span="14" align="center">
          <el-form-item
            label="Código de Destino"
            prop="destinationCode"
          >
            <el-input
              v-model="formData.destinationCode"
              autocomplete="off"
              size="medium"
              :disabled="!formData.isDestination"
            />
          </el-form-item>
        </el-col>
        
      </el-row>
    </el-form>
    <span
      slot="footer"
      class="dialog-footer"
    >
      <el-button
        size="small"
        @click="onCancel"
      >Cancelar</el-button>
      <el-button
        type="primary"
        size="small"
        :disabled="!onCanSaveOrUpdate()"
        @click="onSubmitForm('formData')"
      >Guardar</el-button>
    </span>
  </el-dialog>
</template>