export default {
  API: '/api',
  AUTH: '/auth',
  USERS: '/users',
  ROLES: '/roles',
  SECURITY: '/security', 
  CUSTOMERS: '/customers',
  RECEPTIONS: '/receptions',
  MEASURE_UNITS: '/measure-units',
  DESTINATIONS: '/places',
  PLACES: '/places',
  TRACE_STATUS_REASONS: '/trace-status-reasons',
  BRANCH_OFFICES: '/branch-offices',
  VEHICLES: '/vehicles',
  VEHICLE_TYPES: '/vehicle-types',
  SERVICE_DELIVERY_PRICES: '/service-delivery-prices',
  SHIPMENTS:'/shipments',
  TRACKINGS: '/historytrace',
  SOLVENCIES: '/solvencies',
  WEIGHT_COSTS: '/weight-costs',
  RESUMEN: '/resume',
  PREPAID_PLAN: '/prepaid-plans',
  WORK_ROUTES:'/work-routes',
  PRODUCT_TYPES:'/product-types',
  EMPLOYEES: '/employees',
  NEIGHBORHOODS: '/neighborhoods',
  COLLECTION_ROUTES: '/collection-routes',
  COLLECTION_ROUTE_TRACES: '/collection-route-traces',
  CUSTOMER_DASHBOARD: '/customer-dashboard',
  TAX_TYPES: '/tax-types',
  TAX_FEES: '/tax-fees',
  TAX_FACTOR_TYPES: '/tax-factor-types',
  PAYMENT_METHODS: '/payment-methods',
  PAYMENT_TYPES: '/payment-types',
  CURRENCIES: '/currencies',
  BILL_TYPES: '/bill-types',
  BILL_CONCEPTS: '/bill-concepts',
  BILL_USING_TYPES: '/bill-using-types',
  BILL_RELATIONS: '/bill-relations',
  COUNTRIES: '/countries',
  DISTRICTS: '/districts',
  MUNICIPALITIES: '/municipalities',
  POSTAL_CODES: '/postal-codes',
  SALES: '/sales',
  DANGEROUS_MATERIALS: '/dangerous-materials',
  TRANSPORTATION_TYPES: '/transportation-types',
  TRANSPORT_MERCHANDISE_TYPES: '/transport-merchandise-types',
  SCT_PERMISSION_TYPES: '/sct-permission-types',
  PACKAGING_TYPES: '/packaging-types',
  UNIT_KEYS: '/unit-keys',
  BILL_CONCEPT_KEYS: '/bill-concept-keys',
  TRANSPORT_FIGURE_TYPES: '/transport-figure-types',
  CONSIGNMENT_LOCATION_TYPES: '/consignment-location-types',
  SALE_BILL_STATUSES: '/sale-bill-statuses',
  BILLS: '/bills',
  ORDERS: '/orders',
  ORDER_STATUSES: '/order-statuses',
  GPS_DEVICE_LOCATIONS: '/device-locations',
  BANK_ACCOUNTS: '/bank-accounts',
  TAX_REGIMES: '/tax-regimes',
  CUSTOMER_SPECIAL_PRICES: '/customer-special-prices'
}