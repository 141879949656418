<script>
import ReceptionCreateComponent from "./CustomerReceptionCreateComponent";
import ModalReceptionPackageView from "./ModalReceptionPackageView";
import ModalReceptionsCustomerView from "./ModalReceptionsCustomerView";
// import AddressBoxView from "../shared/boxes/addressBox/AddressBoxView";

export default {
  components: {
    "modal-reception-package-view": ModalReceptionPackageView,
    "modal-receptions-customer-view": ModalReceptionsCustomerView,
    // AddressBoxView,
  },
  extends: ReceptionCreateComponent,
};
</script>
<template>
  <div v-loading="isLoading">
    <el-row type="flex" class="row-bg" :gutter="20">
      <el-col>
        <el-steps :active="stepActive" align-center>
          <el-step
            title="Cliente"
            description="Tipo de Servicio y Datos de Envío"
          />
          <el-step title="Paquetes" description="Información de Mercancía" />
          <el-step title="Finalizar" description="Guía de Envío" />
        </el-steps>
      </el-col>
    </el-row>
    <el-form
      ref="formDataStep0"
      :model="formData"
      :rules="rulesStep0"
      :label-position="'top'"
    >
      <el-row
        v-if="stepActive === 0"
        type="flex"
        class="row-bg reception-steps-wrapper"
        :gutter="20"
      >
        <el-col :span="24">
          <el-row type="flex" class="row-bg" :gutter="20">
            <el-col :span="12">
              <h4>Tipo de Servicio</h4>
              <el-row type="flex" class="row-bg" :gutter="20">
                <el-col :span="24" align="center">
                  <el-form-item
                    prop="receptionServiceType"
                    class="field-client-type"
                  >
                    <el-radio-group
                      v-model="formData.receptionServiceType"
                      @change="onChangeReceptionServiceType"
                    >
                      <el-radio
                        v-for="item in receptionServiceTypes"
                        :key="item.id"
                        :label="item.id"
                      >
                        <i :class="item.icon" /> {{ item.name }}
                      </el-radio>
                    </el-radio-group>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-col>
            <el-col :span="12">
              <h4>Responsable de Pago</h4>
              <el-row type="flex" class="row-bg" :gutter="20">
                <el-col :span="24" align="center">
                  <el-form-item
                    prop="payerResponsible"
                    class="field-client-type"
                  >
                    <el-radio-group
                      v-model="formData.payerResponsible"
                      @change="onChangePayerResponsible"
                    >
                      <el-radio
                        v-for="item in payerResponsibles"
                        :key="item.id"
                        :label="item.id"
                      >
                        <i :class="item.icon" /> {{ item.name }}
                      </el-radio>
                    </el-radio-group>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-col>
          </el-row>
          <div class="request-quote__shipment">
            <el-row type="flex" class="row-bg" :gutter="20">
              <el-col :span="24">
                <h4>Datos de Envío</h4>
                <el-collapse v-model="shipmentTabActive" accordion>
                  <el-collapse-item title="REMITENTE" name="1">
                    <el-row type="flex" class="row-bg" :gutter="20">
                      <el-col :span="24">
                        <el-form-item
                          label="Seleccionar una opción si desea usar los datos de la cuenta o de sus contactos."
                        >
                          <el-radio-group
                            v-model="senderData"
                            size="small"
                            @change="onSenderOptionChange"
                          >
                            <el-radio-button label="account">
                              Usar Mi Cuenta
                            </el-radio-button>
                            <el-radio-button label="contacts">
                              Seleccionar Mis Contactos
                            </el-radio-button>
                          </el-radio-group>
                        </el-form-item>
                      </el-col>
                    </el-row>
                    <el-tabs type="border-card">
                      <el-tab-pane label="Información de Contacto">
                        <el-row
                          v-if="senderData !== 'account'"
                          type="flex"
                          class="row-bg"
                          :gutter="20"
                        >
                          <el-col :span="24">
                            <el-form-item
                              label="Contacto"
                              prop="sender.customer"
                            >
                              <el-select
                                v-model="formData.sender.customer"
                                placeholder="Seleccionar"
                                filterable
                                clearable
                                value-key="id"
                                size="medium"
                                @change="onChangeSenderContact"
                              >
                                <el-option
                                  v-for="item in senderContacts"
                                  :key="item.id"
                                  :label="item.description"
                                  :value="item"
                                />
                              </el-select>
                            </el-form-item>
                          </el-col>
                        </el-row>
                        <el-row type="flex" class="row-bg" :gutter="20">
                          <el-col :span="15">
                            <el-form-item
                              label="Nombre"
                              required
                              prop="sender.contactName"
                            >
                              <el-input
                                v-model="formData.sender.contactName"
                                autocomplete="off"
                                size="medium"
                              />
                            </el-form-item>
                          </el-col>
                          <el-col :span="9">
                            <el-form-item
                              label="Teléfono"
                              prop="sender.contactPhone"
                              required
                            >
                              <el-input
                                v-model="formData.sender.contactPhone"
                                autocomplete="off"
                                size="medium"
                              />
                            </el-form-item>
                          </el-col>
                        </el-row>
                        <el-row type="flex" class="row-bg" :gutter="20">
                          <el-col :span="24">
                            <el-form-item
                              label="Correo Electrónico"
                              prop="sender.contactEmail"
                            >
                              <el-input
                                v-model="formData.sender.contactEmail"
                                autocomplete="off"
                                size="medium"
                              />
                            </el-form-item>
                          </el-col>
                        </el-row>
                        <el-row type="flex" class="row-bg" :gutter="20">
                          <el-col :span="24">
                            <el-form-item
                              label="Referencia/Observación"
                              prop="sender.observation"
                            >
                              <el-input
                                v-model="formData.sender.observation"
                                autocomplete="off"
                                type="textarea"
                                size="medium"
                                :rows="4"
                              />
                            </el-form-item>
                          </el-col>
                        </el-row>
                      </el-tab-pane>
                      <!-- INICIO DIRECCION-->
                      <el-tab-pane label="Dirección">
                        <el-row type="flex" class="row-bg" :gutter="20">
                          <el-col :span="24">
                            <el-form-item
                              label="Ubicación"
                              prop="sender.customerLocation"
                            >
                              <el-select
                                v-model="formData.sender.customerLocation"
                                placeholder="Seleccionar"
                                filterable
                                debounce
                                value-key="id"
                                size="medium"
                                @change="onChangeSenderCustomerLocation"
                                clearable
                              >
                                <el-option
                                  v-for="item in senderCustomerLocations"
                                  :key="item.id"
                                  :label="item.address"
                                  :value="item"
                                />
                              </el-select>
                            </el-form-item>
                          </el-col>
                        </el-row>
                        <!-- <address-box-view
                          orientation="vertical"
                          :form-data="formData.sender.customerLocation"
                          prop-name="sender"
                          v-if="formData.sender.customerLocation"
                        />-->
                      </el-tab-pane>
                      <!-- FINAL DIRECCION-->
                    </el-tabs>
                  </el-collapse-item>
                  <el-collapse-item title="DESTINATARIO" name="2">
                    <el-row type="flex" class="row-bg" :gutter="20">
                      <el-col :span="24">
                        <el-form-item
                          label="Seleccionar una opción si desea usar los datos de la cuenta o de sus contactos."
                        >
                          <el-radio-group
                            v-model="receiverData"
                            size="small"
                            @change="onReceiverOptionChange"
                          >
                            <el-radio-button label="account">
                              Usar Mi Cuenta
                            </el-radio-button>
                            <el-radio-button label="contacts">
                              Seleccionar Mis Contactos
                            </el-radio-button>
                          </el-radio-group>
                        </el-form-item>
                      </el-col>
                    </el-row>
                    <el-tabs type="border-card">
                      <el-tab-pane label="Información de Contacto">
                        <el-row
                          v-if="receiverData === 'contacts'"
                          type="flex"
                          class="row-bg"
                          :gutter="20"
                        >
                          <el-col :span="24">
                            <el-form-item
                              label="Contacto"
                              prop="receiver.customer"
                            >
                              <el-select
                                v-model="formData.receiver.customer"
                                placeholder="Seleccionar"
                                filterable
                                clearable
                                debounce
                                value-key="id"
                                remote
                                size="medium"
                                @change="onChangeReceiverContact"
                              >
                                <el-option
                                  v-for="item in receiverContacts"
                                  :key="item.id"
                                  :label="item.description"
                                  :value="item"
                                />
                              </el-select>
                            </el-form-item>
                          </el-col>
                        </el-row>
                        <el-row type="flex" class="row-bg" :gutter="20">
                          <el-col :span="15">
                            <el-form-item
                              label="Nombre"
                              prop="receiver.contactName"
                            >
                              <el-input
                                v-model="formData.receiver.contactName"
                                autocomplete="off"
                                size="medium"
                              />
                            </el-form-item>
                          </el-col>

                          <el-col :span="9">
                            <el-form-item
                              label="Teléfono"
                              prop="receiver.contactPhone"
                            >
                              <el-input
                                v-model="formData.receiver.contactPhone"
                                autocomplete="off"
                                size="medium"
                              />
                            </el-form-item>
                          </el-col>
                        </el-row>
                        <el-row type="flex" class="row-bg" :gutter="20">
                          <el-col :span="24">
                            <el-form-item
                              label="Correo Electrónico"
                              prop="receiver.contactEmail"
                            >
                              <el-input
                                v-model="formData.receiver.contactEmail"
                                autocomplete="off"
                                size="medium"
                              />
                            </el-form-item>
                          </el-col>
                        </el-row>
                        <el-row type="flex" class="row-bg" :gutter="20">
                          <el-col :span="24">
                            <el-form-item
                              label="Referencia/Observación"
                              prop="receiver.observation"
                            >
                              <el-input
                                v-model="formData.receiver.observation"
                                autocomplete="off"
                                type="textarea"
                                size="medium"
                                :rows="4"
                              />
                            </el-form-item>
                          </el-col>
                        </el-row>
                      </el-tab-pane>
                      <el-tab-pane label="Dirección">
                        <el-row type="flex" class="row-bg" :gutter="20">
                          <el-col :span="24">
                            <el-form-item
                              label="Ubicación"
                              prop="receiver.customerLocation"
                            >
                              <el-select
                                v-model="formData.receiver.customerLocation"
                                placeholder="Seleccionar"
                                filterable
                                debounce
                                value-key="id"
                                size="medium"
                                @change="onChangeReceiverCustomerLocation"
                                clearable
                              >
                                <el-option
                                  v-for="item in receiverCustomerLocations"
                                  :key="item.id"
                                  :label="item.address"
                                  :value="item"
                                />
                              </el-select>
                            </el-form-item>
                          </el-col>
                        </el-row>
                        <!-- <address-box-view
                          orientation="vertical"
                          :form-data="formData.receiver.selectedLocation"
                          prop-name="receiver"
                        />-->
                      </el-tab-pane>
                    </el-tabs>
                  </el-collapse-item>
                  <el-collapse-item title="FACTURACIÓN" name="3">
                    <el-tabs type="border-card">
                      <el-tab-pane label="Datos Fiscales">
                        <el-row type="flex" class="row-bg" :gutter="20">
                          <el-col :span="24">
                            <el-form-item
                              label="Seleccionar una opción si desea usar los datos de remitente o destinatario"
                            >
                              <el-radio-group
                                v-model="useCustomerData"
                                size="small"
                                @change="onFiscalOptionChange"
                              >
                                <el-radio-button label="none">
                                  Ningúno
                                </el-radio-button>
                                <el-radio-button label="my-account">
                                  Mi Cuenta
                                </el-radio-button>
                                <el-radio-button label="sender">
                                  Remitente
                                </el-radio-button>
                                <el-radio-button label="receiver">
                                  Destinatario
                                </el-radio-button>
                              </el-radio-group>
                            </el-form-item>
                          </el-col>
                        </el-row>
                        <el-card>
                          <el-row class="row-bg" :gutter="20">
                            <el-col :xs="24" :sm="16">
                              <el-form-item
                                label="Razón Social"
                                required
                                :prop="`orderFiscal.fiscalName`"
                              >
                                <el-input
                                  v-model="formData.orderFiscal.fiscalName"
                                  autocomplete="off"
                                  autofocus="true"
                                  size="medium"
                                  :disabled="true"
                                />
                              </el-form-item>
                            </el-col>
                            <el-col :xs="24" :sm="8">
                              <el-form-item
                                label="R.F.C."
                                :prop="`orderFiscal.fiscalCode`"
                                required
                              >
                                <el-input
                                  v-model="formData.orderFiscal.fiscalCode"
                                  autocomplete="off"
                                  autofocus="true"
                                  size="medium"
                                  :disabled="true"
                                />
                              </el-form-item>
                            </el-col>
                          </el-row>
                          <el-row type="flex" class="row-bg" :gutter="20">
                            <el-col :span="18">
                              <el-form-item
                                label="Correo Electrónico"
                                :prop="`orderFiscal.fiscalEmail`"
                              >
                                <el-input
                                  v-model="formData.orderFiscal.fiscalEmail"
                                  autocomplete="off"
                                  autofocus="true"
                                  size="medium"
                                  :disabled="true"
                                />
                              </el-form-item>
                            </el-col>
                            <el-col :span="6">
                              <el-form-item
                                label="Código Postal"
                                :prop="`orderFiscal.fiscalPostalCode`"
                              >
                              <el-select v-model="formData.orderFiscal.fiscalPostalCode" placeholder="" :disabled="true">
                                  <el-option
                                    v-for="item in fiscalPostalCodes"
                                    :key="item.id"
                                    :label="item.name"
                                    :value="item">
                                  </el-option>
                                </el-select>
                              </el-form-item>
                            </el-col>
                          </el-row>
                        </el-card>
                      </el-tab-pane>
                      <!--<el-tab-pane label="Dirección">
                        <address-box-view
                          orientation="vertical"
                          :form-data="formData.orderFiscal.fiscalLocation"
                          prop-name="orderFiscal"
                        />
                      </el-tab-pane>-->
                    </el-tabs>
                  </el-collapse-item>
                </el-collapse>
              </el-col>
            </el-row>
          </div>

          <modal-receptions-customer-view
            v-if="modalReceptionsCustomerConfig.isVisible"
            :form-data="itemReceptionsCustomer"
            :config="modalReceptionsCustomerConfig"
          />
        </el-col>
      </el-row>
    </el-form>
    <el-form
      ref="formDataStep1"
      :model="formData"
      :rules="rulesStep1"
      :label-position="'top'"
    >
      <el-row
        v-if="stepActive === 1"
        type="flex"
        class="row-bg reception-steps-wrapper"
        :gutter="20"
      >
        <el-col :span="24">
          <el-row type="flex" class="row-bg" :gutter="20">
            <el-col :span="24" align="center">
              <el-row type="flex" class="row-bg" :gutter="20">
                <!--      <el-col :span="24">
                  <h4>Tipo de Recepción</h4>
                </el-col>
              </el-row>
              <el-row
                type="flex"
                class="row-bg"
                :gutter="20"
              >
                <el-col
                  :span="24"
                  align="center"
                >
                  <el-form-item
                    prop="receptionType"
                    class="field-reception-type"
                  >
                    <el-radio-group
                      v-model="formData.receptionType"
                      @change="onChangeReceptionType"
                    >
                      <el-radio
                        v-for="item in receptionTypes"
                        :key="item.id"
                        :label="item.id"
                      >
                        <i :class="item.icon" /> {{ item.name }}
                      </el-radio>
                    </el-radio-group>
                  </el-form-item>
                </el-col> -->
              </el-row>
            </el-col>
          </el-row>
          <el-row type="flex" class="row-bg" :gutter="20">
            <el-col :span="16">
              <h4>Flete (Salidas/Viajes)</h4>
            </el-col>
            <el-col :span="8">
              <h4>Cobrese ó Devuelvase (C.O.D.)</h4>
            </el-col>
          </el-row>
          <el-row type="flex" class="row-bg" :gutter="20">
            <el-col :span="16">
              <el-form-item prop="serviceCost">
                <el-select
                  v-model="formData.serviceCost"
                  placeholder="Seleccionar"
                  filterable
                  clearable
                  debounce
                  value-key="id"
                  size="large"
                  @change="onChangeServiceCost"
                >
                  <el-option
                    v-for="item in serviceCosts"
                    :key="item.id"
                    :label="onFormatServiceCost(item)"
                    :value="item"
                  />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8" align="center">
              <el-form-item prop="paymentAgainstDelivery">
                <el-input-number
                  v-model="formData.paymentAgainstDelivery"
                  size="medium"
                />
              </el-form-item>
            </el-col>
          </el-row>

          <el-row type="flex" class="row-bg" :gutter="20">
            <el-col :span="24">
              <el-row type="flex" class="row-bg" :gutter="20">
                <h4>Lista de Mercancia</h4>
              </el-row>
              <el-row type="flex" class="row-bg" :gutter="20">
                <el-col :span="24">
                  <el-button
                    type="primary"
                    size="small"
                    @click="onCreateReceptionPackage"
                  >
                    <i class="fas fa-plus-circle" /> Agregar Paquete
                  </el-button>
                </el-col>
              </el-row>
              <el-row type="flex" class="row-bg" :gutter="20">
                <el-col :span="24">
                  <el-form-item
                    prop="receptionPackages"
                    class="field-reception-packages"
                  >
                    <el-table
                      show-summary
                      sum-text="Total"
                      :summary-method="getSummaries"
                      :data="formData.receptionPackages"
                      style="width: 100%"
                    >
                      <el-table-column
                        label="Indice"
                        width="100"
                        align="center"
                      >
                        <template #default="scope">
                          {{ scope.$index + 1 }}
                        </template>
                      </el-table-column>
                      <el-table-column prop="description" label="Descripción" />
                      <el-table-column
                        prop="quantity"
                        label="Cantidad"
                        width="100"
                        align="center"
                      />
                      <el-table-column
                        prop="weight"
                        label="Peso (kg)"
                        width="110"
                        align="center"
                      />
                      <el-table-column
                        prop="height"
                        label="Alto (cm)"
                        width="110"
                        align="center"
                      />

                      <el-table-column
                        prop="width"
                        label="Ancho (cm)"
                        width="110"
                        align="center"
                      />

                      <el-table-column
                        prop="length"
                        label="Largo (cm)"
                        width="110"
                        align="center"
                      />

                      <el-table-column
                        label="Opciones"
                        width="120"
                        align="center"
                        show-overflow-tooltip
                      >
                        <template #default="scope">
                          <el-dropdown
                            @command="onChangeOptionReceptionPackage"
                          >
                            <el-button size="small">
                              <i class="el-icon-more" />
                            </el-button>
                            <template #dropdown>
                              <el-dropdown-menu>
                                <el-dropdown-item
                                  :command="{
                                    command: 'edit',
                                    item: scope.row,
                                    index: scope.$index,
                                  }"
                                >
                                  <i class="el-icon-edit" /> Mostrar
                                </el-dropdown-item>
                                <el-dropdown-item
                                  :command="{
                                    command: 'delete',
                                    item: scope.row,
                                    index: scope.$index,
                                  }"
                                >
                                  <i class="el-icon-delete" /> Eliminar
                                </el-dropdown-item>
                              </el-dropdown-menu>
                            </template>
                          </el-dropdown>
                        </template>
                      </el-table-column>
                    </el-table>
                  </el-form-item>
                </el-col>
              </el-row>
              <modal-reception-package-view
                v-if="modalReceptionPackageConfig.isVisible"
                :form-data="itemReceptionPackage"
                :config="modalReceptionPackageConfig"
              />
            </el-col>
          </el-row>
        </el-col>
      </el-row>
    </el-form>
    <el-row
      v-if="stepActive === 2 && receptionData"
      type="flex"
      class="row-bg reception-steps-wrapper"
      :gutter="20"
    >
      <el-col :span="24">
        <el-row type="flex" class="row-bg" :gutter="20">
          <el-col :span="24" align="center">
            <h4>GUÍA DE ENVÍO</h4>
            <el-row type="flex" class="row-bg" :gutter="20">
              <el-col :span="24">
                <i class="uil-check-circle success" />
              </el-col>
            </el-row>
            <div class="request-code__order__detail">
              <el-row type="flex" class="row-bg" :gutter="20">
                <el-col :span="24" align="center">
                  <h5 class="tracking-number">
                    NO. GUÍA:
                    {{ receptionData.trackingNumber }}
                  </h5>
                </el-col>
              </el-row>
              <el-row type="flex" class="row-bg" :gutter="20">
                <el-col :span="24" align="center">
                  <el-button
                    size="medium"
                    type="primary"
                    icon="el-icon-download"
                    @click="generateTrackingDocument(receptionData.orderNumber)"
                  >
                    Descargar Orden de Recolección
                  </el-button>
                </el-col>
              </el-row>
            </div>
          </el-col>
        </el-row>
      </el-col>
    </el-row>
    <el-row type="flex" class="row-bg" :gutter="20">
      <el-col align="center" class="reception-steps-navigation">
        <el-button
          v-if="!isFirstStep() && stepActive !== 2"
          type="primary"
          size="small"
          @click="onPrevStep"
        >
          Anterior
        </el-button>
        <el-button
          v-if="stepActive !== 2"
          type="primary"
          size="small"
          @click="onNextStep"
        >
          Siguiente
        </el-button>
        <!--  <el-button
          v-if="receptionData && stepActive === 2"
          type="primary"
          icon="el-icon-printer"
          size="small"
          @click="onPrint"
        >
          Imprimir
        </el-button> -->
      </el-col>
    </el-row>
  </div>
</template>
