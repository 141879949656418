export default class Order {
    constructor(options, state) {
      if(state){
        this.state = state
      }
      Object.assign(this, options)
    }
    
    toSend() {
      return {
        id: this.id,
        client: this.mapCustomer('customer'),
        sender: this.mapOrderShipmentContact('sender'),
        receiver: this.mapOrderShipmentContact('receiver'),
      }
    }
    
    mapCustomer(customerField) {
      return {
        id: (this[customerField]) ? this[customerField].id : null,
        contactName: (this[customerField]) ? this[customerField].contactName : null,
        contactEmail: (this[customerField]) ? this[customerField].contactEmail : null,
        contactPhone: (this[customerField]) ? this[customerField].contactPhone : null,
      }
    }

    mapOrderShipmentContact(customerField) {
      return {
        id: (this[customerField]) ? this[customerField].id : null,
        name: (this[customerField]) ? this[customerField].name : null,
        email: (this[customerField]) ? this[customerField].email : null,
        phone: (this[customerField]) ? this[customerField].phone : null,
        observation: (this[customerField]) ? this[customerField].observation : null,
        locationId: (this[customerField] && this[customerField].customerLocation) ? this[customerField].customerLocation.id : null,
      }
    }
  }