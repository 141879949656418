<script>
import ListTrackingComponent from "./ListTrackingComponent";
import ModalTrackingView from "./ModalTrackingView";
import ModalImportView from "../shared/modals/ModalImportView";
import ModalReceptionStatusView from "./ModalReceptionStatusView";

export default {
  components: {
    "modal-tracking-view": ModalTrackingView,
    "modal-import-view": ModalImportView,
    "modal-reception-status-view": ModalReceptionStatusView,
  },
  extends: ListTrackingComponent,
};
</script>

<template>
  <div>
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="row mb-2">
              <div class="d-flex">
                <div class="col-5">
                  <div
                    id="entradas"
                    class="mb-3"
                  >
                    <span class="mx-2">Mostrar</span>
                    <el-select
                      v-model="defaultShowEntry"
                      placeholder="Opción"
                      size="small"
                      @change="onChangeEntries"
                    >
                      <el-option
                        v-for="item in showEntries"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      />
                    </el-select>
                    <span class="mx-2">registros </span>
                  </div>
                </div>
                <div class="col-6 me-2">
                </div>
                <div class="col-1">
                  <div
                    id="buttonlist"
                    class="mb-3"
                  >
                    <el-button type="danger" class="filter-btn" icon="fa fa-filter" size="medium" @click="onMoreFilters">
                      Filtrar por:
                    </el-button>
                  </div>
                </div>
              </div>
            </div>
            <!-- end row -->
            <div class="table-responsive mb-4">
              <el-table
                ref="multipleTable"
                v-loading="isListLoading"
                class="table table-centered table-nowrap mb-0"
                :data="collection"
                style="width: 100%"
                align="center"
                @selection-change="onSelectTracking"
              >
                <el-table-column
                  type="selection"
                  width="55"
                />
                <el-table-column
                  property="trackingNumber"
                  label="No. Guía"
                  align="center"
                  width="180"
                />
                <el-table-column
                  property="orderNumber"
                  label="No. Orden"
                  width="140"
                  align="center"
                />
                <el-table-column
                  label="Fecha de Envio"
                  property="receptionDate"
                  show-overflow-tooltip
                  align="center"
                >
                  <template slot-scope="scope">
                    <el-tag
                      type="primary"
                      effect="plain"
                    >
                      <i class="el-icon-date" />
                      <span class="mx-2">
                        {{ scope.row.receptionDate | formatDate }}
                      </span>
                    </el-tag>
                  </template>
                </el-table-column>
                <el-table-column
                  label="Remitente | Teléfono"
                  show-overflow-tooltip
                >
                  <template #default="scope">
                    {{ scope.row.senderName }} | {{ scope.row.senderPhone }}
                  </template>
                </el-table-column>
                <el-table-column
                  label="Destinatario | Teléfono"
                  show-overflow-tooltip
                >
                  <template #default="scope">
                    {{ scope.row.receiverName }} | {{ scope.row.receiverPhone }}
                  </template>
                </el-table-column>
                <el-table-column
                  label="Origen"
                  show-overflow-tooltip
                  align="center"
                >
                  <template #default="scope">
                    {{ scope.row.origin }}
                  </template>
                </el-table-column>
                <el-table-column
                  label="Destino"
                  show-overflow-tooltip
                  align="center"
                >
                  <template #default="scope">
                    {{ scope.row.destiny }}
                  </template>
                </el-table-column>
                <el-table-column
                  label="Estado"
                  show-overflow-tooltip
                  align="center"
                  width="195"
                >
                  <template #default="scope">
                    <el-tag
                      :type="onGetStyleTrackingStatus(scope.row.status)"
                      effect="plain"
                    >
                      <i :class="onGetIconTrackingStatus(scope.row.status)" />
                      {{ onGetNameTrackingStatus(scope.row.status) }}
                    </el-tag>
                    <!-- <el-tag
                      v-if="scope.row.status === 1"
                      type="Primary"
                      effect="plain"
                    >
                      <i class="el-icon-box" /> {{ scope.row.statusName }}
                    </el-tag>
                    <el-tag
                      v-else-if="scope.row.status === 2"
                      type="info"
                      effect="plain"
                    >
                      <i class="el-icon-truck" /> {{ scope.row.statusName }}
                    </el-tag>
                    <el-tag
                      v-else-if="scope.row.status === 3"
                      type="success"
                      effect="plain"
                    >
                      <i class="el-icon-circle-check" />
                      {{ scope.row.statusName }}
                    </el-tag>
                    <el-tag
                      v-else-if="scope.row.status === 4"
                      type="danger"
                      effect="plain"
                    >
                      <i class="el-icon-takeaway-box" />
                      {{ scope.row.statusName }}
                    </el-tag> -->
                  </template>
                </el-table-column>
                <el-table-column
                  label="Opciones"
                  width="120"
                  align="center"
                  show-overflow-tooltip
                >
                  <template #default="scope">
                    <el-dropdown @command="onChangeOption">
                      <el-button size="small">
                        <i class="el-icon-more" />
                      </el-button>
                      <template #dropdown>
                        <el-dropdown-menu>
                          <el-dropdown-item
                            :disabled="!onCanRead(modulePermissions)"
                            :command="{
                              command: 'generate-tracking-document',
                              item: scope.row,
                            }"
                          >
                            <i class="mdi mdi-file-pdf-outline" /> Generar Guía
                          </el-dropdown-item>
                          <el-dropdown-item
                            :disabled="!onCanRead(modulePermissions)"
                            :command="{
                              command: 'generate-tracking-labels',
                              item: scope.row,
                            }"
                          >
                            <i class="mdi mdi-file-pdf-outline" /> Generar
                            Etiquetas
                          </el-dropdown-item>
                          <el-dropdown-item
                            :disabled="!onCanRead(modulePermissions)"
                            :command="{ command: 'show', item: scope.row }"
                          >
                            <i class="el-icon-time" /> Mostrar historial
                          </el-dropdown-item>
                          <el-dropdown-item
                            :command="{
                              command: 'add-reception-status',
                              item: scope.row,
                            }"
                          >
                            <i class="uil-layer-group" /> Actualizar Estado
                          </el-dropdown-item>
                        </el-dropdown-menu>
                      </template>
                    </el-dropdown>
                  </template>
                </el-table-column>
              </el-table>
            </div>
            <div class="row mt-4">
              <div class="col-sm-6">
                <div>
                  <p class="mb-sm-0">
                    Mostrar 1 a {{ pagination.limit }} de
                    {{ pagination.totalItems }} registros
                  </p>
                </div>
              </div>
              <div class="col-sm-6">
                <div class="float-sm-end">
                  <el-pagination
                    :page-count="pagination.totalPages"
                    :current-page.sync="currentPage"
                    layout="prev, pager, next"
                    @current-change="onPageChange"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <modal-import-view :conf="modalImport" />
    <modal-tracking-view
      v-if="modalConfig.isVisible"
      :form-data="item"
      :config="modalConfig"
    />
    <modal-reception-status-view
      v-if="modalReceptionStatus.isVisible"
      :config="modalReceptionStatus"
      :form-data="itemReceptionStatus"
    />
    <el-drawer
      custom-class="filters-drawer"
      :visible.sync="isMoreFilterVisible"
      direction="rtl"
      :lock-scroll="false"
      size="20%"
    >
      <template slot="title">
        <span class="title">Filtros</span>
        <el-button class="btn-text" type="text" @click="onClearFilters"
          >Borrar todos los filtros</el-button
        >
      </template>
      <el-row class="row-bg wrapper-more-filters" :gutter="20">
        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
          <div class="block">
            <span class="block-title">Guía:</span>
            <el-input
              type="text"
              placeholder="Buscar por Guía"
              v-model="filtersSelected.receptionNumber"
              clearable
              size="small"
              v-on:input="onSearchByReceptionNumber"
            ></el-input>
          </div>
        </el-col>
      </el-row>
      <el-row class="row-bg wrapper-more-filters" :gutter="20">
        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
          <div class="block">
            <span class="block-title">Origen:</span>
            <el-select
              v-model="filtersSelected.originName"
              placeholder="Buscar Origen"
              filterable
              clearable
              debounce
              value-key="id"
              size="medium"
              @change="searchOriginFilter"
            >
              <el-option
                v-for="item in origins"
                :key="item.id"
                :label="item.name"
                :value="item"
              />
            </el-select>
          </div>
        </el-col>
      </el-row>
      <el-row class="row-bg wrapper-more-filters" :gutter="20">
        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
          <div class="block">
            <span class="block-title">Destino:</span>
            <el-select
              v-model="filtersSelected.destinyName"
              placeholder="Buscar Destino"
              filterable
              clearable
              debounce
              value-key="id"
              size="medium"
              @change="searchDestinationFilter"
            >
              <el-option
                v-for="item in destinations"
                :key="item.id"
                :label="item.name"
                :value="item"
              />
            </el-select>
          </div>
        </el-col>
      </el-row>
      <el-row class="row-bg wrapper-more-filters" :gutter="20">
        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
          <div class="block">
            <span class="block-title">Fecha:</span>
            <el-date-picker
              v-model="filtersSelected.beginingDate"
              type="daterange"
              size="small"
              clearable
              range-separator="-"
              start-placeholder="F. Inicio"
              end-placeholder="F. Fin"
              value-format="yyyy-MM-dd"
              @input="onSearchDate"
            />
          </div>
        </el-col>
      </el-row>
      <el-row class="row-bg wrapper-more-filters" :gutter="20">
        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
          <div class="block">
            <span class="block-title">Remitente:</span>
            <el-input
              type="text"
              placeholder="Buscar por Remitente"
              v-model="filtersSelected.sender"
              clearable
              size="small"
              v-on:input="onSearchBySenderName"
            ></el-input>
          </div>
        </el-col>
      </el-row>
      <el-row class="row-bg wrapper-more-filters" :gutter="20">
        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
          <div class="block">
            <span class="block-title">Destinatario:</span>
            <el-input
              type="text"
              placeholder="Buscar por Destinatario"
              v-model="filtersSelected.receiver"
              clearable
              size="small"
              v-on:input="onSearchByReceiverName"
            ></el-input>
          </div>
        </el-col>
      </el-row>
      <el-row class="row-bg wrapper-more-filters" :gutter="20">
        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
          <div class="block">
            <span class="block-title">Cliente Destinatario:</span>
            <el-select
              v-model="filtersSelected.customerSender"
              placeholder="Seleccionar"
              filterable
              clearable
              debounce
              @change="searchCustomerSenderNameFilter"
            >
              <el-option
                v-for="item in senderCustomers"
                :key="item.id"
                :label="item.description"
                :value="item.id"
              />
            </el-select>
          </div>
        </el-col>
      </el-row>
      <el-row class="row-bg wrapper-more-filters" :gutter="20">
        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
          <div class="block">
            <span class="block-title">Cliente Remitente:</span>
            <el-select
              v-model="filtersSelected.customerReceiver"
              placeholder="Seleccionar"
              filterable
              clearable
              debounce
              @change="searchCustomerReceiverNameFilter"
            >
              <el-option
                v-for="item in receiverCustomers"
                :key="item.id"
                :label="item.description"
                :value="item.id"
              />
            </el-select>
          </div>
        </el-col>
      </el-row>
      <el-row class="row-bg wrapper-more-filters" :gutter="20">
        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
          <div class="block">
            <span class="block-title">Estado del envío:</span>
            <el-select
              v-model="filtersSelected.statusTracking"
              placeholder="Seleccionar"
              :popper-append-to-body="false"
              clearable
              @change="serachStatusTrackingFilter"
            >
              <el-option
                v-for="item in receptionTrackingStatus"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </div>
        </el-col>
      </el-row>
    </el-drawer>
  </div>
</template>
