<script>
import ModalOrderComponent from "./ModalOrderComponent";
import AddressBoxView from "../shared/boxes/addressBox/AddressBoxView";

export default {
  components: {
    AddressBoxView,
  },
  extends: ModalOrderComponent,
};
</script>

<template>
  <el-dialog
    custom-class="dialog-order"
    :visible.sync="config.isVisible"
    :before-close="onCancel"
    :close-on-click-modal="false"
  >
    <span
      slot="title"
      class="dialog-title"
    >
      <i class="uil-location-pin-alt" /> Orden
    </span>

    <el-form
      ref="formData"
      v-loading="config.isLoading"
      :element-loading-text="config.isLoadingText"
      element-loading-spinner="el-icon-loading"
      :model="formData"
      :rules="rules"
      :label-position="'top'"
    >
      <el-tabs type="border-card">
        <el-tab-pane>
          <span slot="label">Información General</span>
          <el-row>
            <el-col
              :span="24"
              align="center"
            >
              <h3>
                <i class="el-icon-success" /> ORDEN: {{ formData.orderNumber }}
              </h3>
            </el-col>
          </el-row>
          <div
            style="margin-top:20px"
            class="request-quote__shipment"
          >
            <el-row
              class="row-bg"
              :gutter="20"
            >
              <el-col
                :xs="24"
                :sm="8"
              >
                <el-form-item
                  label="Nombre"
                  prop="customer.contactName"
                >
                  <el-input
                    v-model="formData.customer.contactName"
                    autocomplete="off"
                    size="medium"
                  />
                </el-form-item>
              </el-col>
              <el-col
                :xs="24"
                :sm="8"
              >
                <el-form-item
                  label="Correo Electrónico"
                  prop="customer.contactEmail"
                >
                  <el-input
                    v-model="formData.customer.contactEmail"
                    autocomplete="off"
                    size="medium"
                  />
                </el-form-item>
              </el-col>
              <el-col
                :xs="24"
                :sm="8"
              >
                <el-form-item
                  label="Tel. de Contacto"
                  prop="customer.contactPhone"
                >
                  <el-input
                    v-model="formData.customer.contactPhone"
                    autocomplete="off"
                    size="medium"
                  />
                </el-form-item>
              </el-col>
            </el-row>
            <el-row
              class="row-bg"
              :gutter="20"
            >
              <el-col
                :xs="24"
                :sm="16"
              >
                <el-form-item
                  label="Razón Social"
                  prop="customer.fiscalName"
                >
                  <el-input
                    v-model="formData.customer.fiscalName"
                    autocomplete="off"
                    size="medium"
                  />
                </el-form-item>
              </el-col>
              <el-col
                :xs="24"
                :sm="8"
              >
                <el-form-item
                  label="RFC"
                  prop="customer.fiscalCode"
                >
                  <el-input
                    v-model="formData.customer.fiscalCode"
                    autocomplete="off"
                    size="medium"
                  />
                </el-form-item>
              </el-col>
            </el-row>
            <!-- <el-tabs v-model="activeName">
              <el-tab-pane label="Dirección" name="second">
                <address-box-view
                  :form-data="formData.customer"
                  orientation="vertical"
                  prop-name="customer"
                />
              </el-tab-pane>
            </el-tabs> -->
          </div>
        </el-tab-pane>
        <el-tab-pane label="Información de Envío">
          <el-collapse
            v-model="shipmentTabActive"
            accordion
          >
            <el-collapse-item
              v-loading="isSenderBoxLoading"
              title="REMITENTE"
              name="1"
            >
              <el-tabs type="border-card">
                <el-tab-pane label="Información de Contacto">
                  <el-row
                    class="row-bg"
                    :gutter="20"
                  >
                    <el-col
                      :xs="24"
                      :sm="16"
                    >
                      <el-form-item
                        label="Nombre"
                        required
                        :prop="`sender.name`"
                      >
                        <el-input
                          v-model="formData.sender.name"
                          autocomplete="off"
                          autofocus="true"
                          size="medium"
                        />
                      </el-form-item>
                    </el-col>
                    <el-col
                      :xs="24"
                      :sm="8"
                    >
                      <el-form-item
                        label="Teléfono"
                        :prop="`sender.phone`"
                        required
                      >
                        <el-input
                          v-model="formData.sender.phone"
                          autocomplete="off"
                          autofocus="true"
                          size="medium"
                        />
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row
                    class="row-bg"
                    :gutter="20"
                  >
                    <el-col
                      :xs="24"
                      :sm="24"
                    >
                      <el-form-item
                        label="Correo Electrónico"
                        :prop="`sender.email`"
                      >
                        <el-input
                          v-model="formData.sender.email"
                          autocomplete="off"
                          autofocus="true"
                          size="medium"
                        />
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row
                    class="row-bg"
                    :gutter="20"
                  >
                    <el-col
                      :xs="24"
                      :sm="24"
                    >
                      <el-form-item
                        label="Referencia/Observación"
                        :prop="`sender.observation`"
                      >
                        <el-input
                          v-model="formData.sender.observation"
                          autocomplete="off"
                          autofocus="true"
                          type="textarea"
                          size="medium"
                          :rows="4"
                        />
                      </el-form-item>
                    </el-col>
                  </el-row>
                </el-tab-pane>
                <el-tab-pane label="Dirección">
                  <address-box-view
                    v-if="formData.sender"
                    :form-data="formData.sender"
                    orientation="vertical"
                    prop-name="sender"
                  />
                </el-tab-pane>
              </el-tabs>
            </el-collapse-item>
            <el-collapse-item
              title="DESTINATARIO"
              name="2"
            >
              <el-tabs type="border-card">
                <el-tab-pane label="Información de Contacto">
                  <el-row
                    class="row-bg"
                    :gutter="20"
                  >
                    <el-col
                      :xs="24"
                      :sm="16"
                    >
                      <el-form-item
                        label="Nombre"
                        required
                        :prop="`receiver.name`"
                      >
                        <el-input
                          v-model="formData.receiver.name"
                          autocomplete="off"
                          autofocus="true"
                          size="medium"
                        />
                      </el-form-item>
                    </el-col>
                    <el-col
                      :xs="24"
                      :sm="8"
                    >
                      <el-form-item
                        label="Teléfono"
                        :prop="`receiver.phone`"
                        required
                      >
                        <el-input
                          v-model="formData.receiver.phone"
                          autocomplete="off"
                          autofocus="true"
                          size="medium"
                        />
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row
                    type="flex"
                    class="row-bg"
                    :gutter="20"
                  >
                    <el-col :span="24">
                      <el-form-item
                        label="Correo Electrónico"
                        :prop="`receiver.email`"
                      >
                        <el-input
                          v-model="formData.receiver.email"
                          autocomplete="off"
                          autofocus="true"
                          size="medium"
                        />
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row
                    type="flex"
                    class="row-bg"
                    :gutter="20"
                  >
                    <el-col :span="24">
                      <el-form-item
                        label="Referencia/Observación"
                        :prop="`receiver.observation`"
                      >
                        <el-input
                          v-model="formData.receiver.observation"
                          autocomplete="off"
                          autofocus="true"
                          type="textarea"
                          size="medium"
                          :rows="4"
                        />
                      </el-form-item>
                    </el-col>
                  </el-row>
                </el-tab-pane>
                <el-tab-pane label="Dirección">
                  <address-box-view
                    v-if="formData.receiver"
                    :form-data="formData.receiver"
                    orientation="vertical"
                    prop-name="receiver"
                  />
                </el-tab-pane>
              </el-tabs>
            </el-collapse-item>
            <el-collapse-item
              title="DATOS FISCALES"
              name="3"
            >
              <el-tabs type="border-card">
                <el-tab-pane label="Información de Contacto">
                  <el-row
                    class="row-bg"
                    :gutter="20"
                  >
                    <el-col
                      :xs="24"
                      :sm="16"
                    >
                      <el-form-item
                        label="Razón Social"
                        prop="orderFiscal.fiscalName"
                      >
                        <el-input
                          v-model="formData.orderFiscal.fiscalName"
                          autocomplete="off"
                          autofocus="true"
                          size="medium"
                        />
                      </el-form-item>
                    </el-col>
                    <el-col
                      :xs="24"
                      :sm="8"
                    >
                      <el-form-item
                        label="R.F.C."
                        prop="orderFiscal.fiscalCode"
                      >
                        <el-input
                          v-model="formData.orderFiscal.fiscalCode"
                          autocomplete="off"
                          autofocus="true"
                          size="medium"
                        />
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row
                    type="flex"
                    class="row-bg"
                    :gutter="20"
                  >
                    <el-col :span="24">
                      <el-form-item
                        label="Correo Electrónico"
                        prop="orderFiscal.fiscalEmail"
                      >
                        <el-input
                          v-model="formData.orderFiscal.fiscalEmail"
                          autocomplete="off"
                          autofocus="true"
                          size="medium"
                        />
                      </el-form-item>
                    </el-col>
                  </el-row>
                </el-tab-pane>
                <el-tab-pane label="Dirección">
                  <address-box-view
                    v-if="formData.orderFiscal.location"
                    :form-data="formData.orderFiscal.location"
                    orientation="vertical"
                    prop-name="orderFiscal.location"
                  />
                </el-tab-pane>
              </el-tabs>
            </el-collapse-item>
          </el-collapse>
        </el-tab-pane>
        <el-tab-pane label="Paquetes">
          <el-row
            class="row-bg"
            :gutter="20"
          >
            <el-col :span="24">
              <el-form-item
                prop="traces"
                align="center"
              >
                <el-table
                  :data="formData.traces"
                  style="width: 100%"
                >
                  <el-table-column type="expand">
                    <template slot-scope="props">
                      <el-row
                        class="row-bg"
                        :gutter="20"
                      >
                        <el-col
                          :xs="12"
                          :sm="4"
                          align="center"
                        >
                          <el-form-item
                            label="Cantidad"
                            :prop="'traces.' + props.$index + '.quantity'"
                            :rules="[
                              {
                                required: true,
                                message: 'Cantidad requerido.',
                                trigger: 'blur',
                              },
                            ]"
                          >
                            <el-input-number
                              v-model="props.row.quantity"
                              size="small"
                              :min="1"
                            />
                          </el-form-item>
                        </el-col>
                        <el-col
                          :xs="12"
                          :sm="5"
                          align="center"
                        >
                          <el-form-item
                            label="Largo"
                            :prop="'traces.' + props.$index + '.length'"
                            :rules="[
                              {
                                required: true,
                                message: 'Largo requerido.',
                                trigger: 'blur',
                              },
                            ]"
                          >
                            <el-input-number
                              v-model="props.row.length"
                              size="small"
                              :min="1"
                            />
                          </el-form-item>
                        </el-col>
                        <el-col
                          :xs="12"
                          :sm="5"
                          align="center"
                        >
                          <el-form-item
                            label="Alto"
                            :prop="'traces.' + props.$index + '.height'"
                            :rules="[
                              {
                                required: true,
                                message: 'Alto requerido.',
                                trigger: 'blur',
                              },
                            ]"
                          >
                            <el-input-number
                              v-model="props.row.height"
                              size="small"
                              :min="1"
                            />
                          </el-form-item>
                        </el-col>
                        <el-col
                          :xs="12"
                          :sm="5"
                          align="center"
                        >
                          <el-form-item
                            label="Ancho"
                            :prop="'traces.' + props.$index + '.width'"
                            :rules="[
                              {
                                required: true,
                                message: 'Ancho requerido.',
                                trigger: 'blur',
                              },
                            ]"
                          >
                            <el-input-number
                              v-model="props.row.width"
                              size="small"
                              :min="1"
                            />
                          </el-form-item>
                        </el-col>
                        <el-col
                          :xs="12"
                          :sm="5"
                          align="center"
                        >
                          <el-form-item
                            label="Peso"
                            :prop="'traces.' + props.$index + '.weight'"
                            :rules="[
                              {
                                required: true,
                                message: 'Ancho requerido.',
                                trigger: 'blur',
                              },
                            ]"
                          >
                            <el-input-number
                              v-model="props.row.weight"
                              size="small"
                              :min="1"
                            />
                          </el-form-item>
                        </el-col>
                      </el-row>
                    </template>
                  </el-table-column>
                  <el-table-column
                    prop="description"
                    label="Descripción"
                  >
                    <template slot-scope="scope">
                      <el-form-item
                        :prop="'traces.' + scope.$index + '.description'"
                        :rules="[
                          {
                            required: true,
                            message: 'Descripción requerido.',
                            trigger: 'blur',
                          },
                        ]"
                      >
                        <el-input
                          v-model="scope.row.description"
                          size="small"
                          type="textarea"
                        />
                      </el-form-item>
                    </template>
                  </el-table-column>
                  <el-table-column
                    label="Opciones"
                    width="100"
                    align="center"
                  >
                    <template slot-scope="scope">
                      <el-button
                        type="default"
                        icon="el-icon-delete-solid"
                        size="small"
                        @click="onDeletePackage(scope.$index)"
                      />
                    </template>
                  </el-table-column>
                </el-table>
              </el-form-item>
            </el-col>
          </el-row>
        </el-tab-pane>
      </el-tabs>

      <!-- <el-row>
        <el-col :span="24">
          <el-form-item label="Estado">
            <el-switch
              v-model="formData.isActive"
              active-text="Activo"
              inactive-text="Inactivo"
            />
          </el-form-item>
        </el-col>
      </el-row> -->
    </el-form>
    <span
      slot="footer"
      class="dialog-footer"
    >
      <el-button
        size="small"
        @click="onCancel"
      >Cancelar</el-button>
      <el-button
        type="primary"
        size="small"
        :disabled="!onCanSaveOrUpdate()"
        @click="onSubmitForm('formData')"
      >Guardar</el-button>
    </span>
  </el-dialog>
</template>
