import BaseList from "../infraestructure/BaseList";
import CustomerSolvencyViewModel from "../../core/features/customerSolvencies/CustomerSolvencyViewModel";

export default {
  extends: BaseList,
  data() {
    return {
      viewModel: null,
      searchCode: '',
      searchName: '',
      modalImport: {
        isVisible: false,
        isLoading: false,
        title: "IMPORTAR CRÉDITOS Y PAGOS",
        onUploadImport: "on-upload-import-customer-solvencies",
        onCancelImport: "on-cancel-import-customer-solvencies",
      },
      solvencyReceptions: {
        receptions: []
      },
      modalSolvencyReceptions: {
        isVisible: false,
        isLoading: false
      },
      customerId: null,
    };
  },
  mounted() {
    this.onMountedEvent("on-submited-customer-solvency", this.onSearch);
    this.onMountedEvent("on-upload-import-customer-solvencies", this.onUploadImport);
    this.onMountedEvent("on-cancel-import-customer-solvencies", this.onCancelImport);
  },
  beforeDestroy() {
    this.onBeforeDestroyEvent("on-submited-solvency", this.onSearch);
    this.onBeforeDestroyEvent(
      "on-upload-customer-solvencies",
      this.onUploadImport
    );
    this.onBeforeDestroyEvent(
      "on-cancel-customer-solvencies",
      this.onCancelImport
    );
  },
  created() {
    this.viewModel = new CustomerSolvencyViewModel(this);
    this.onActive();
  },
  methods: {
    onActive() {
      this.customerId = this.$store.getters["admin/getUserActive"].id;
      this.onSearch();
    },
    onSelectSolvency() { },
    onSearch(){
      this.defaultFilters();
      this.isListLoading = true;
      setTimeout(() => {
        this.viewModel.findCollection(this.customerId, this.filters);
      }, 400);
    },
    onChangeOption(event) {
      if (event.command === "show-receptions") this.onShowReceptionsBySolvency(event.item.id);
    },
    onShowReceptionsBySolvency(solvencyId) {
      this.viewModel.findReceptionsBySolvency(this.customerId, solvencyId)
    },
    onFindReceptionsBySolvencyResponse(response) {
      this.modalSolvencyReceptions.isVisible = true
      this.solvencyReceptions.receptions = response.data
    },
    searchCodeFilter() {
      this.addFilter("Code", this.searchCode);
      this.onSearch();
    },
    searchNameFilter() {
      this.addFilter("CustomerName", this.searchName);
      this.onSearch();
    },
    searchStatusFilter(data) {
      if (data !== null) {
        this.addFilter("IsActive", data);
      } else {
        this.removeFilter("IsActive");
      }
      this.onSearch();
    },
    searchCustomerTypeFilter(data) {
      if (data !== null) {
        this.addFilter("CustomerType", data);
      } else {
        this.removeFilter("CustomerType");
      }
      this.onSearch();
    },
    onChangeSolvencyType(data) {
      if (data !== null) {
        this.addFilter("type", data);
      } else {
        this.removeFilter("type");
      }
      this.onSearch();
    }
  },
};
