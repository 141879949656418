export default class Vehicle {
  constructor(options, state) {
    if (state) {
      this.state = state;
    }
    Object.assign(this, options);
  }

  toSend() {
    return {
      id: this.id,
      equipmentNumber: this.equipmentNumber,
      description: this.description,
      plateNumber: this.plateNumber,
      policyNumber: this.policyNumber,
      insuranceCarrier: this.insuranceCarrier,
      insuranceEnvironment: this.insuranceEnvironment,
      policyEnvironment: this.policyEnvironment,
      modelYear: this.modelYear ? this.modelYear.toString() : "",
      permissionNumber: this.permissionNumber,
      isActive: this.isActive,
      branchOfficeId: this.branchOffice ? this.branchOffice.id : null,
      vehicleTypeId: this.vehicleType ? this.vehicleType.id : null,
    };
  }

  toCatalog() {
    return `${this.equipmentNumber} - ${this.description}`;
  }
}
