import BaseForm from "../../infraestructure/BaseForm";
import ReceptionsViewModel from "../../../core/features/receptions/ReceptionsViewModel";

export default {
  extends: BaseForm,
  data() {
    return {
      viewModel: null,
      customers: [],
      customerLocations: [],
      paymentTypes: [],
      paymentMethods: [],
      billUsingTypes: [],
      billTypes: [],
      currencies: [],
      weightUnits: [],
      taxRegimes: [],
      postalCodes: [],
      isBillingLoading: false,
    };
  },
  created() {
    this.viewModel = new ReceptionsViewModel(this);
    this.onActive();
  },
  props: {
    formData: {
      type: Object,
      required: true,
    },
    propName: {
      type: String,
      required: true,
    },
  },
  methods: {
    onActive() {
      this.viewModel.bindBillingInformationFormView(this.formData);
    },
    onSearchCustomers(data) {
      this.customers = [];
      if (data)
        this.viewModel.findCustomers(`FullSearch=${data}&IsActive=true`);
    },
    onChangeCustomer(customer) {
      this.customers = []
      this.postalCodes = []
      if (customer) {
        this.isBillingLoading = true;
        this.viewModel.findCustomer(customer.id);
      } else {
        this.setCustomerFiscal({});
      }
    },
    onFindCustomerResponse(response) {
      setTimeout(() => {
        this.setCustomerFiscal({
          fiscalName: response.data.fiscalName,
          fiscalCode: response.data.fiscalCode,
          fiscalEmail: response.data.contactEmail,
          taxRegime: response.data.taxRegime,
          fiscalPostalCode: response.data.fiscalPostalCode,
        });
        this.isBillingLoading = false;
      }, 400);
    },
    setCustomerFiscal({
      fiscalName = null,
      fiscalCode = null,
      fiscalEmail = null,
      taxRegime = null,
      fiscalPostalCode = null,
    }) {
      console.log(fiscalPostalCode)
      if(fiscalPostalCode) this.postalCodes.push(fiscalPostalCode)

      this.$set(this.formData, "fiscalName", fiscalName);
      this.$set(this.formData, "fiscalCode", fiscalCode);
      this.$set(this.formData, "fiscalEmail", fiscalEmail);
      this.$set(this.formData, "taxRegime", taxRegime);
      this.$set(this.formData.billAddress, "fiscalPostalCode", fiscalPostalCode);
    },
    onFindCustomersResponse(response) {
      this.customers = response.data;
    },
    onSearchCustomerError(error) {
      setTimeout(() => {
        this.onError(error);
      }, 400);
    },
    onChangeBillLocation(data) {
      this.isBillingLoading = true;
      setTimeout(() => {
        this.formData.billAddress = {};
        if (data) {
          this.$set(this.formData, "billAddress", data);
        }
        this.isBillingLoading = false;
      }, 400);
    },
    onFindCustomerLocationsResponse(response) {
      this.$set(this, "customerLocations", response.data.customerLocations);
    },
  },
};
