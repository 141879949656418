import BaseForm from '../infraestructure/BaseForm'
import CustomerReceptionsViewModel from "../../core/features/customersReceptions/CustomerReceptionsViewModel";
export default {
    extends: BaseForm,
    data() {
        return {
            viewModel: null,
            weightCostConfig: null,
            rules: {
                productTypePath: [{
                    required: true,
                    message: 'Producto es requerido.',
                    trigger: 'blur'
                }],
                measureUnit: [{
                    required: true,
                    message: 'Unidad de Medida requerido.',
                    trigger: 'blur'
                }],
                description: [{
                    required: true,
                    message: 'Descripción requerido.',
                    trigger: 'blur'
                }],
                quantity: [{
                    required: true,
                    message: 'Cantidad requerido.',
                    trigger: 'blur'
                }],
                weight: [{
                    required: true,
                    message: 'Peso requerido.',
                    trigger: 'blur'
                }],
                height: [{
                    required: true,
                    message: 'Altura requerido.',
                    trigger: 'blur'
                }],
                width: [{
                    required: true,
                    message: 'Ancho requerido.',
                    trigger: 'blur'
                }],
                length: [{
                    required: true,
                    message: 'Largo requerido.',
                    trigger: 'blur'
                }]
            },
        }
    },
    props: {
        config: {
            type: Object,
            required: true
        },
        formData: {
            type: Object,
            required: true
        }
    },
    created() {
        this.viewModel = new CustomerReceptionsViewModel(this)
        this.onActive()
    },
    methods: {
        onActive() {
            // this.viewModel.bindReceptionPackageForm(this.formData)
        },
        onSubmitForm(formName) {
            this.$refs[formName].validate((isValid) => {
                if (isValid) {
                    this.onSave(this.formData)
                    return true
                }
                return false
            })
        },
        onSave(formData) {
            this.onDoAfterLocalSaveResponse(formData, 'on-submited-customer-reception-packages')
        }
    }
}