<script>
import ListBillTypeComponent from "./ListBillTypeComponent";
import ModalBillTypeView from "./ModalBillTypeView";
import ModalImportView from '../shared/modals/ModalImportView'

export default {
  components: {
    "modal-bill-type-view": ModalBillTypeView,
    'modal-import-view': ModalImportView
  },
  extends: ListBillTypeComponent,
};
</script>

<template>
  <div>
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="row mb-2">
              <div class="d-flex justify-content-between">
                <div>
                  <div
                    id="entradas"
                    class="mb-3"
                  >
                    <span class="mx-2">Mostrar</span>
                    <el-select
                      v-model="defaultShowEntry"
                      placeholder="Opción"
                      size="small"
                      @change="onChangeEntries"
                    >
                      <el-option
                        v-for="item in showEntries"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      />
                    </el-select>
                    <span class="mx-2">registros </span>
                  </div>
                </div>
                <div class="d-flex justify-content-between">
                  <div>
                    <div
                      id="buttonlist"
                      class="mb-3"
                    >
                      <el-button type="danger" class="filter-btn" icon="fa fa-filter" size="medium" @click="onMoreFilters">
                        Filtrar por:
                      </el-button>
                      <el-dropdown
                        size="medium"
                        class="more-options-action"
                      >
                        <el-button
                          type="plain"
                          size="medium"
                        >
                          <i class="uil-ellipsis-v" /> M&aacute;s Opciones
                          <i class="el-icon-arrow-down el-icon--right" />
                        </el-button>
                        <el-dropdown-menu slot="dropdown">
                          <el-dropdown-item class="my-2">
                            <el-button
                              size="medium"
                              :disabled="!onCanCreate(modulePermissions)"
                              @click="onShowImport"
                            >
                              <i class="el-icon-upload2" /> Importar
                            </el-button>
                          </el-dropdown-item>
                          <el-dropdown-item class="my-2">
                            <el-button
                              size="medium"
                              :disabled="!onCanRead(modulePermissions)"
                              @click="onCreate"
                            >
                              <i class="el-icon-download" /> Exportar
                            </el-button>
                          </el-dropdown-item>
                        </el-dropdown-menu>
                      </el-dropdown>
                      <el-button 
                        v-if="onUserPermission('Permission.BillType.Create')"
                        type="primary"
                        size="medium"
                        class="main-action"
                        :disabled="!onCanCreate(modulePermissions)"
                        @click="onCreate"
                      >
                        <i class="fas fa-plus-circle" /> Crear Tipo de Factura
                      </el-button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- end row -->
            <div class="table-responsive mb-4">
              <el-table
                v-if="onUserPermission('Permission.BillType.Read')"
                ref="multipleTable"
                v-loading="isListLoading"
                class="table table-centered table-nowrap mb-0"
                :data="collection"
                style="width: 100%"
                @selection-change="onSelectBillType"
              >
                <el-table-column
                  type="selection"
                  width="55"
                />
                <el-table-column
                  property="code"
                  label="Código"
                />

                <el-table-column
                  label="Nombre"
                  property="name"
                  show-overflow-tooltip
                />
                <el-table-column
                  label="Default"
                  show-overflow-tooltip
                  align="center"
                  width="120"
                >
                  <template slot-scope="scope">
                    <el-tag
                      v-if="scope.row.isDefault"
                      type="success"
                      effect="plain"
                    >
                      <i class="el-icon-success" />
                    </el-tag>
                  </template>
                </el-table-column>
                <el-table-column
                  label="Estado"
                  show-overflow-tooltip
                  align="center"
                  width="120"
                >
                  <template #default="scope">
                    <el-tag
                      v-if="scope.row.isActive"
                      type="success"
                      effect="plain"
                    >
                      <i class="el-icon-success" /> Activo
                    </el-tag>
                    <el-tag
                      v-if="!scope.row.isActive"
                      type="danger"
                      effect="plain"
                    >
                      <i class="el-icon-error" /> Inactivo
                    </el-tag>
                  </template>
                </el-table-column>
                <el-table-column
                  v-if="onUserPermission('Permission.BillType.Update') || onUserPermission('Permission.BillType.Delete')"
                  label="Opciones"
                  width="120"
                  align="center"
                  show-overflow-tooltip
                >
                  <template #default="scope">
                    <el-dropdown @command="onChangeOption">
                      <el-button size="small">
                        <i class="el-icon-more" />
                      </el-button>
                      <template #dropdown>
                        <el-dropdown-menu>
                          <el-dropdown-item
                            v-if="onUserPermission('Permission.BillType.Update')"
                            :disabled="!onCanRead(modulePermissions)"
                            :command="{ command: 'edit', item: scope.row }"
                          >
                            <i class="el-icon-edit" /> Mostrar
                          </el-dropdown-item>
                          <el-dropdown-item
                            v-if="onUserPermission('Permission.BillType.Delete')"
                            :disabled="!onCanDelete(modulePermissions)"
                            :command="{ command: 'delete', item: scope.row }"
                          >
                            <i class="el-icon-delete" /> Eliminar
                          </el-dropdown-item>
                        </el-dropdown-menu>
                      </template>
                    </el-dropdown>
                  </template>
                </el-table-column>
              </el-table>
            </div>
            <div class="row mt-4">
              <div class="col-sm-6">
                <div>
                  <p class="mb-sm-0">
                    Mostrar 1 a {{ pagination.limit }} de
                    {{ pagination.totalItems }} registros
                  </p>
                </div>
              </div>
              <div class="col-sm-6">
                <div class="float-sm-end">
                  <el-pagination
                    :page-count="pagination.totalPages"
                    :current-page.sync="currentPage"
                    layout="prev, pager, next"
                    @current-change="onPageChange"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <modal-import-view :conf="modalImport" />
    <modal-bill-type-view
      v-if="modalConfig.isVisible"
      :form-data="item"
      :config="modalConfig"
    />
    <el-drawer
      custom-class="filters-drawer"
      :visible.sync="isMoreFilterVisible"
      direction="rtl"
      size="20%"
    >
      <template slot="title">
        <span class="title">Filtros</span>
        <el-button class="btn-text" type="text" @click="onClearFilters"
          >Borrar todos los filtros</el-button
        >
      </template>
      <el-row class="row-bg wrapper-more-filters" :gutter="20">
        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
          <div class="block">
            <span class="block-title">Codigo:</span>
            <el-input
              type="text"
              placeholder="Buscar por código"
              v-model="filtersSelected.code"
              clearable
              size="small"
              v-on:input="onSearchByCode"
            ></el-input>
          </div>
        </el-col>
      </el-row>
      <el-row class="row-bg wrapper-more-filters" :gutter="20">
        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
          <div class="block">
            <span class="block-title">Nombre:</span>
            <el-input
              type="text"
              placeholder="Buscar por nombre"
              v-model="filtersSelected.name"
              clearable
              size="small"
              v-on:input="onSearchByName"
            ></el-input>
          </div>
        </el-col>
      </el-row>
      <el-row class="row-bg wrapper-more-filters" :gutter="20">
        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
          <div class="block">
            <span class="block-title">¿Activo?:</span>
            <el-select
              v-model="filtersSelected.status"
              placeholder="Seleccionar"
              :popper-append-to-body="false"
              size="medium"
              clearable
              debounce
              @change="searchStatusFilter"
            >
              <el-option
                v-for="item in statusOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </div>
        </el-col>
      </el-row>
    </el-drawer>
  </div>
</template>
