export default class Reception {
  constructor(options, state) {
    if (state) {
      this.state = state
    }
    Object.assign(this, options)
  }


  toSend() {
    return {
      id: this.id,
      trackingNumber: this.trackingNumber,
      receptionType: this.receptionType,
      receptionServiceType: this.receptionServiceType,
      freightPayable: this.freightPayable,
      sender: this.mapCustomer('sender'),
      receiver: this.mapCustomer('receiver'),
      receptionDate: this.receptionDate,
      serviceCostId: (this.serviceCost) ? this.serviceCost.id : null,
      originPlaceId: (this.originPlace) ? this.originPlace.id : null,
      destinationPlaceId: (this.destinationPlace) ? this.destinationPlace.id : null,
      serviceDeliveryPrice: this.serviceDeliveryPrice,
      paymentAgainstDelivery: this.paymentAgainstDelivery,
      payerResponsible: this.payerResponsible,
      documentReference: this.documentReference,
      discountPercentage: this.discountPercentage,
      customerSolvencyId: (this.customerSolvencyId) ? this.customerSolvencyId : null,
      receptionPackages: this.mapReceptionPackages(this.receptionPackages)
    }
  }

  toEditSend() {
    return {
      id: this.id,
      freightPayable: this.freightPayable,
      documentReference: this.documentReference,
      senderName: this.senderName,
      senderPhone: this.senderPhone,
      senderEmail: this.senderEmail,
      senderObservation: this.senderObservation,
      receiverName: this.receiverName,
      receiverPhone: this.receiverPhone,
      receiverEmail: this.receiverEmail,
      receiverObservation: this.receiverObservation,
      senderLocationId: this.senderLocation.id,
      receiverLocationId: this.receiverLocation.id
    }
  }

  calculateShippingQuote(collection) {
    var total = 0
    collection.forEach(item => total = total + item.subtotal)
    return total
  }

  mapCustomer(customerField) {
    return {
      name: (this[customerField]) ? this[customerField].name : null,
      email: (this[customerField]) ? this[customerField].email : null,
      phone: (this[customerField]) ? this[customerField].phone : null,
      observation: (this[customerField]) ? this[customerField].observation : null,
      locationId: (this[customerField] && this[customerField].customerLocation) ? this[customerField].customerLocation.id : null,
    }
  }

  mapReceptionPackages(collection) {
    var result = []
    collection.forEach((item) => {
      result.push({
        id: item.id,
        description: item.description,
        merchandiseValue: item.merchandiseValue,
        weight: item.weight,
        height: item.height,
        width: item.width,
        length: item.length,
        quantity: item.quantity,
        volumetricWeight: item.volumetricWeight,
        subtotal: item.subtotal,
        extraWeight: item.extraWeight,
        extraWeightCost: item.extraWeightCost,
        weightBase: item.weightBase,
        finalWeight: item.finalWeight,
        measureUnitId: (item.measureUnit) ? item.measureUnit.id : null,
        productTypeId: (item.productType) ? item.productType.id : null,
        isDangerousMaterial: item.isDangerousMaterial,
        transportMerchandiseTypeId: (item.transportMerchandiseType) ? item.transportMerchandiseType.id : null,
        dangerousMaterialId: (item.dangerousMaterial) ? item.dangerousMaterial.id : null,
        packagingTypeId: (item.packagingType) ? item.packagingType.id : null,
        currencyId: (item.currency) ? item.currency.id : null,
      })
    })
    return result
  }
  
}