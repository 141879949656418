export default class CustomerLocation {
  constructor(options, state) {
    if (state) {
      this.state = state;
    }
    Object.assign(this, options);
  }

  getFullAddress() {
    var result = [];
    result.push(this.mainStreet);
    result.push(this.secondaryStreet);
    if (this.extNumber) {
      result.push(`No. Ext: ${this.extNumber}`);
    }
    if (this.intNumber) {
      result.push(`No. Int: ${this.intNumber}`);
    }
    if (this.postalCode && this.postalCode.id) {
      result.push(`C.P.: ${this.postalCode.code}`);
    } else {
      result.push(`C.P.: ${this.postalCode || ""}`);
    }
    
    if(this.neighborhood) {
      result.push(`Col. ${this.neighborhood.name}`);
    }

    if (this.city) {
      result.push(`${this.city.name}`);
    }
    if (this.municipality) {
      result.push(`${this.municipality.name}`);
    }

    if (this.district) {
      result.push(`${this.district.name}`);
    }

    if (this.country) {
      result.push(`${this.country.name}`);
    }
    return result.join(", ");
  }

  getDescriptionFullAddress() {
    var result = [];
    result.push(this.mainStreet);
    result.push(this.secondaryStreet);
    if (this.extNumber) {
      result.push(`No. Ext: ${this.extNumber}`);
    }
    if (this.intNumber) {
      result.push(`No. Int: ${this.intNumber}`);
    }
    if (this.postalCode && this.postalCode.id) {
      result.push(`C.P.: ${this.postalCode.code}`);
    } else {
      result.push(`C.P.: ${this.postalCode || ""}`);
    }
    result.push(`Col. ${this.neighborhood.name}`);
    result.push(`${this.city.name}`);
    result.push(`${this.municipality.name}`);
    result.push(`${this.district.name}`);
    result.push(`${this.country.code}`);
    return result.join(", ");
    // return `${this.mainStreet || ''}, ${this.extNumber || ''}, ${this.secondaryStreet || ''}, C.P: ${this.postalCode}, ${this.neighborhood.name} ${this.city.name}`
  }

  getNameWithCode() {
    return (this.isCustomerCodeDocUsed) ? `${this.description} - (${this.customerCode})` : `${this.description}`
  }

  toSend() {
    return {
      id: this.id,
      code: this.code,
      customerCode: this.customerCode,
      isCustomerCodeDocUsed: this.isCustomerCodeDocUsed,
      description: this.description,
      mainStreet: this.mainStreet,
      secondaryStreet: this.secondaryStreet,
      extNumber: this.extNumber,
      intNumber: this.intNumber,
      neighborhoodId: this.neighborhood ? this.neighborhood.id : null,
      postalCodeId: this.postalCode ? this.postalCode.id : null,
      cityId: (this.city) ? this.city.id : null,
      municipalityId: (this.municipality) ? this.municipality.id : null,
      districtId: (this.district) ? this.district.id : null,
      countryId: (this.country) ? this.country.id : null,
      isActive: this.isActive,
      updateState: this.updateState,
    };
  }

  toSendCustomerLocation() {
    return {
      id: this.id,
      code: this.code,
      customerCode: this.customerCode,
      isCustomerCodeDocUsed: this.isCustomerCodeDocUsed,
      description: this.description,
      mainStreet: this.mainStreet,
      secondaryStreet: this.secondaryStreet,
      extNumber: this.extNumber,
      intNumber: this.intNumber,
      neighborhoodId: this.neighborhood ? this.neighborhood.id : null,
      postalCodeId: this.postalCode ? this.postalCode.id : null,
      cityId: (this.city) ? this.city.id : null,
      municipalityId: (this.municipality) ? this.municipality.id : null,
      districtId: (this.district) ? this.district.id : null,
      countryId: (this.country) ? this.country.id : null,
      isActive: this.isActive,
      customerId: this.customerId,
    };
  }
}
