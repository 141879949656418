<script>
import AdminHeaderCustomerComponent from "./AdminHeaderCustomerComponent";

export default {
  extends: AdminHeaderCustomerComponent,
};
</script>

<template>
  <header id="page-topbar">
    <div class="navbar-header">
      <div class="d-flex">
        <!-- LOGO -->
        <div class="navbar-brand-box">
          <router-link
            :to="'/admin/resumen'"
            class="logo logo-dark"
            title="Resumen"
          >
            <span class="logo-sm">
              <img
                :src="
                  require('@/assets/dashboard/assets/images/logo-small.png')
                "
                alt=""
              >
            </span>
            <span class="logo-lg">
              <img
                src="assets/images/logo.svg"
                alt=""
                height="20"
              >
            </span>
          </router-link>
          <!-- 
          <a href="index.html" class="logo logo-light">
            <span class="logo-sm">
              <img src="assets/images/logo.svg" alt="" height="22" />
            </span>
            <span class="logo-lg">
              <img src="assets/images/logo.svg" alt="" height="20" />
            </span>
          </a> -->
        </div>

        <button
          type="button"
          class="btn btn-sm px-3 font-size-16 header-item waves-effect vertical-menu-btn"
        >
          <i class="fa fa-fw fa-bars" />
        </button>
      </div>
      <div class="d-flex ">
        <div class="dropdown  d-lg-inline-block ms-1">
          <div
            class="btn header-item noti-icon waves-effect notifications-receptions d-flex align-items-center el-alert__description"
          >
            <div
              v-if="receptionSolvency && receptionSolvency.isAvailable"
              :class="bgclass"
              style="border-radius:6px; padding: 5px 7px;"
            >
              <i :class="'el-icon-' + type + ' me-1 el-alert__description'" />
              <span>{{ receptionSolvency.quantity }} Guias Disponibles</span>
            </div>
            <div
              v-if="receptionSolvency && !receptionSolvency.isAvailable"
              :class="bgclass"
              style="border-radius:6px; padding: 5px 7px;"
            >
              <i :class="'el-icon-' + type + ' me-1 el-alert__description'" />
              <span>{{ receptionSolvency.quantity }} Guias Disponibles</span>
            </div>
          </div>
        </div>
        <div class="dropdown d-inline-block">
          <button
            id="page-header-customer-user-dropdown"
            type="button"
            class="btn header-item waves-effect"
            data-bs-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
            @click.capture="toggleSubMenu('page-header-customer-user-dropdown')"
          >
            <img
              class="rounded-circle header-profile-user"
              :src="require('@/assets/dashboard/assets/images/users/user.png')"
              alt="User Avatar"
            >
            <span class="d-none d-xl-inline-block ms-1 fw-medium font-size-15">
              {{ this.$store.state.admin.userCurrent.name }}
            </span>
            <i class="uil-angle-down d-none d-xl-inline-block font-size-15" />
          </button>
          <div
            class="dropdown-menu dropdown-menu-end"
            :class="{ show: show }"
          >
            <!-- item-->

            <router-link
              :to="{ name: 'userProfile' }"
              class="dropdown-item"
              title="Perfil de Usuarios"
            >
              <i
                class="uil-user-square font-size-18 align-middle me-1 text-muted"
              />
              <span class="align-middle">Perfil de Usuario</span>
            </router-link>

            <a
              class="dropdown-item"
              href="#"
              @click="onEndSession"
            ><i
               class="uil uil-sign-out-alt font-size-18 align-middle me-1 text-muted"
             />
              <span class="align-middle">Cerrar sesión</span></a>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>
