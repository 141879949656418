export default class ReceptionHelper{

    static CONSTANT_SOLVENCY_TYPES = {
        CREDIT: 1,
        PREPAID: 2,
        CASH: 3
    }

    static SolvencyType = {
        Credit: 1,
        Prepaid: 2
    }

    static solvencyTypes = [
        {
            id: 1,
            name: "Credito",
        },
        {
            id: 2,
            name: "Prepago",
        }
    ]

    static receptionTypes = [{
        id: 1,
        name: "Recolección",
        icon: "uil-truck"
    }, 
    {
        id: 2,
        name: "Sucursal",
        icon: "uil-store"
    }];

    static receptionServiceTypes = [{
        id: 3,
        name: "Contado",
        icon: "uil-money-bill"
    },
    {
        id: 1,
        name: "Credito",
        icon: "uil-bill"
    },
    /*{
        id: 2,
        name: "Prepago",
        icon: "uil-money-stack"
    }*/];

    static paymentTypes = [
        {
            id: 1,
            name: "Efectivo"
        },
        {
            id: 2,
            name: "Tarjeta"
        },
        {
            id: 3,
            name: "Transferencia"
        },
        {
            id: 4,
            name: "Cheque"
        }
    ];

    static receptionTrackingStatus = [
        {
            id: 1,
            name: "En Espera de Recolección"
        },
        {
            id: 2,
            name: "Recolectado"
        },
        {
            id: 3,
            name: "En Almacen"
        },
        {
            id: 4,
            name: "En Ruta"
        },
        {
            id: 5,
            name: "Entregado"
        },
        {
            id: 6,
            name: "Devolución"
        },
        {
            id: 7,
            name: "Cancelado"
        },
    ];

    static getNameClientType(type) {
        var item = ReceptionHelper.receptionServiceTypes.find((item) => item.id === type);
        return item ? item.name : "Sin Tipo";
    }

    static getNamePaymentType(type) {
        var item = ReceptionHelper.paymentTypes.find((item) => item.id === type);
        return item ? item.name : "Sin Tipo";
    }

    static getNameReceptionType(type) {
        var item = ReceptionHelper.receptionTypes.find((item) => item.id === type);
        return item ? item.name : "Sin Tipo";
    }

    static getIconTrackingStatus(status) {
        let icon = ''
        switch (status) {
            case 1:
                icon = "el-icon-time";
                break;
            case 2:
                icon = "el-icon-s-claim";
                break;
            case 3:
                icon = "el-icon-box";
                break;
            case 4:
                icon = "el-icon-truck";
                break;
            case 5:
                icon = "el-icon-circle-check";
                break;
            case 6:
                icon = "el-icon-d-arrow-left";
                break;
            case 7:
                icon = "uil-cancel";
                break;
        }
        return icon
    }

    static getStyleTrackingStatus(status) {
        let type = ''
        switch (status) {
            case 1:
                type = "primary";
                break;
            case 2:
                type = "primary";
                break;
            case 3:
                type = "primary";
                break;
            case 4:
                type = "primary";
                break;
            case 5:
                type = "success";
                break;
            case 6:
                type = "danger";
                break;
            case 7:
                type = "info";
                break;
        }
        return type
    }

    static getColorTrackingStatus(status) {
        let color = ''
        switch (status) {
            case 1:
                color = "#303133";
                break;
            case 2:
                color = "#303133";
                break;
            case 3:
                color = "#303133";
                break;
            case 4:
                color = "#303133";
                break;
            case 5:
                color = "#67C23A";
                break;
        }
        return color
    }

    static getNameTrackingStatus(status) {
        var item = ReceptionHelper.receptionTrackingStatus.find((item) => item.id === status);
        return item ? item.name : "Sin Status";
    }


    static calculateSubtotal(receptionPackage, deliveryPriceCost) {
        return ReceptionHelper.getSubtotal(deliveryPriceCost, receptionPackage.quantity)
    }

    static calculateSubtotalOverweight(receptionPackage, deliveryPriceCost, weightCostConfig) {
        let subtotal = 0;
        let volumetricWeight = ReceptionHelper.calculateVolumetricWeight(receptionPackage.quantity, receptionPackage.length, receptionPackage.height, receptionPackage.width)
        let realWeight = ReceptionHelper.getRealWeight(receptionPackage.weight, volumetricWeight)
        let extraWeight = ReceptionHelper.getExtraWeight(realWeight, weightCostConfig.weightBase, receptionPackage.quantity)

        if (extraWeight > 0) {
            let extraCost = ReceptionHelper.getExtraCost(extraWeight, weightCostConfig.extraWeightCost)
            let extraCostTax = ReceptionHelper.getExtraCostTax(extraCost, weightCostConfig.extraWeightTax)
            subtotal = ReceptionHelper.getSubtotal(deliveryPriceCost, receptionPackage.quantity, extraCost, extraCostTax)
        }

        return subtotal;
    }

    static getFinalWeight(weight, volumetricWeight) {
        return ReceptionHelper.getRealWeight(weight, volumetricWeight)
    }

    static calculateExtraWeight(finalWeight, weightBase, quantity) {
        let extraWeight = ReceptionHelper.getExtraWeight(finalWeight, weightBase, quantity)
        return (extraWeight > 0) ? extraWeight : 0;
    }

    static calculateSubtotalExtaWeight(receptionPackage, weightCostConfig) {
        let subtotal = 0;
        let volumetricWeight = ReceptionHelper.calculateVolumetricWeight(receptionPackage.quantity, receptionPackage.length, receptionPackage.height, receptionPackage.width)
        let realWeight = ReceptionHelper.getRealWeight(receptionPackage.weight, volumetricWeight)
        let extraWeight = ReceptionHelper.getExtraWeight(realWeight, weightCostConfig.weightBase, receptionPackage.quantity)
        if (extraWeight > 0) {
            subtotal = ReceptionHelper.getExtraCost(extraWeight, weightCostConfig.extraWeightCost)
        }
        return subtotal;
    }

    /**
     * 
     * @param {*} receptionPackage 
     * @param {*} deliveryPriceCost 
     * @param {*} weightCostConfig 
     * @returns 
     */
    static calculateDeliveryPrice(receptionPackage, deliveryPriceCost, weightCostConfig) {
        let subtotal = 0;
        let volumetricWeight = ReceptionHelper.calculateVolumetricWeight(receptionPackage.quantity, receptionPackage.length, receptionPackage.height, receptionPackage.width)
        let realWeight = ReceptionHelper.getRealWeight(receptionPackage.weight, volumetricWeight)
        let extraWeight = ReceptionHelper.getExtraWeight(realWeight, weightCostConfig.weightBase, receptionPackage.quantity)
        if (extraWeight > 0) {
            let extraCost = ReceptionHelper.getExtraCost(extraWeight, weightCostConfig.extraWeightCost)
            let extraCostTax = ReceptionHelper.getExtraCostTax(extraCost, weightCostConfig.extraWeightTax)
            subtotal = ReceptionHelper.getSubtotal(deliveryPriceCost, receptionPackage.quantity, extraCost, extraCostTax)
        } else {
            subtotal = ReceptionHelper.getSubtotal(deliveryPriceCost, receptionPackage.quantity)
        }
        return subtotal;
    }



    /**
     * Metodo para obtener el peso real a calcular
     * @param {*} physicalWeight 
     * @param {*} volumetricWeight 
     * @returns 
     */
    static getRealWeight(physicalWeight, volumetricWeight) {
        return (physicalWeight > volumetricWeight) ? physicalWeight : volumetricWeight
    }

    /**
     * Metodo para obtener el extra peso
     * @param {*} weight 
     * @param {*} weightBase
     * @param {*} quantity 
     * @returns 
     */
    static getExtraWeight(weight, weightBase, quantity) {
        var result = weight - (weightBase * quantity)
        return (result > -1) ? result : 0
    }

    /**
     * Metodo para obtener el costo del extra peso
     * @param {*} extraWeight 
     * @param {*} extraWeightCost 
     * @returns 
     */
    static getExtraCost(extraWeight, extraWeightCost) {
        return extraWeight * extraWeightCost
    }

    static getExtraCostTax(extraCost, extraWeightTax) {
        return extraCost * ReceptionHelper.getExtraWeightPercentTaxes(extraWeightTax)
    }

    static getSubtotal(deliveryPriceCost, quantity, extraCost, extraCostTax) {
        if(extraCost && extraCostTax) return (deliveryPriceCost * quantity) + (extraCost + extraCostTax);
        return (deliveryPriceCost * quantity)
    }

    /**
     * 
     * @param {*} extraWeightPercentTax 
     * @returns 
     */
    static getExtraWeightPercentTaxes(extraWeightPercentTax) {
        return (extraWeightPercentTax / 100)
    }

    /**
     * Metodo para calcular el peso volumetrico
     */

    static calculateVolumetricWeight(quantity, legth, height, width) {
        return quantity * ((legth * height * width) / 5000);
    }

}