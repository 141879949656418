import _ from 'lodash'
import BaseForm from '../infraestructure/BaseForm'
import OrderViewModel from '../../core/features/orders/OrderViewModel'

export default {
    extends: BaseForm,
    data() {
        return {
            modulePermissions: 'Order',
            viewModel: null,
            shipmentTabActive: '1',
            activeName: 'second',
            isSenderBoxLoading: false,
            rules: {
                customer: {
                    fiscalCode: [{
                        validator: this.onVerifyRFC, trigger: ['blur', 'change']
                    }],
                }
                /* code: [{
                    required: true,
                    message: 'Código(s) requerido.',
                    trigger: 'blur'
                }],
                name: [{
                    required: true,
                    message: 'Nombre requerido.',
                    trigger: 'blur'
                }],
                isActive: [{
                    required: true,
                    message: 'El Estado es requerido.',
                    trigger: 'blur'
                }]*/
            }
        }
    },
    props: {
        config: {
            type: Object,
            required: true
        },
        formData: {
            type: Object,
            required: true
        }
    },
    created() {
        this.viewModel = new OrderViewModel(this)
        this.onActive()
    },
    methods: {
        onActive() {
            this.viewModel.bindForm(this.formData)
        },
        onSubmitForm(formName) {
            this.$refs[formName].validate((isValid) => {
                if (isValid) {
                    this.onSave(this.formData)
                    return true
                }
                return false
            })
        },
        onSaveResponse(response) {
            this.onDoAfterSaveResponse(response.message, 'on-submited-order')
        },
        onVerifyRFC(rule, value, callback) {
            if (value) {
                _.debounce(() => {
                    if (this.formData.customer.fiscalCode) {
                        this.config.isLoading = true
                        this.config.isLoadingText = 'Verificando RFC...'
                        this.viewModel.verifyRFC(this.formData.customer.fiscalCode, callback)
                    } else {
                        callback();
                    }
                }, 500)()
            } else {
                callback();
            }
        },
        onDeletePackage() {

        }
    }
}