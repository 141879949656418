import _ from "lodash";
import BaseForm from "../infraestructure/BaseForm";
import CustomersAccountViewModel from "../../core/features/customerAccount/CustomersAccountViewModel";

export default {
  extends: BaseForm,
  data() {
    return {
      viewModel: null,
      editPass: false,
      roles: [],
      rules: {
        accountName: [
          {
            trigger: ["blur", "change"],
            required: true,
            message: "Nombre de cuenta requerida.",
          },
          {
            trigger: ["blur", "change"],
            min: 3,
            message: "Minimo 3 dígitos.",
          },
        ],
        userName: [
          {
            required: true,
            message: "Usuario requerido.",
          },
          {
            trigger: "blur",
            validator: this.onValidateUserName,
          },
        ],
        accountPhone: [
          {
            required: true,
            message: "Teléfono requerido.",
            trigger: "blur",
          },
        ],
        email: [
          {
            required: true,
            message: "Correo requerido.",
          },
          {
            trigger: ["blur", "change"],

            validator: this.onValidateEmail,
          },
          {
            type: "email",
            message: "Ingrese una dirección de correo electrónico correcta",
            trigger: ["blur", "change"],
          },
        ],
        password: [
          {
            validator: this.onValidatePassword,
            trigger: ["blur", "change"],
          },
          {
            min: 6,
            message: "Minimo de 6 digitos",
            trigger: ["blur", "change"],
          },
        ],
        passwordConfirmation: [
          {
            validator: this.onValidatePasswordConfirmation,
            trigger: ["blur", "change"],
          },
          {
            min: 6,
            message: "Minimo de 6 digitos",
            trigger: ["blur", "change"],
          },
        ],
      },
    };
  },
  props: {
    config: {
      type: Object,
      required: true,
    },
    formData: {
      type: Object,
      required: true,
    },
    customerId: {
        type: String,
        required: true,
    }
  },
  created() {
    this.viewModel = new CustomersAccountViewModel(this);
    this.onActive();
  },
  methods: {
    onActive() {
      this.viewModel.bindForm(this.formData);
    },
    onSubmitForm(formName) {
      this.$refs[formName].validate((isValid) => {
        if (isValid) {
          this.onSave(this.formData);
          return true;
        }
        return false;
      });
    },
    onSaveResponse(response) {
      this.onDoAfterSaveResponse(response.message, "on-submited-customer-user");
    },
    onValidateUserName(rule, value, callback) {
      _.debounce(() => {
        this.viewModel.verifyUserName(this.customerId, value, callback);
      }, 500)();
    },
    onValidateEmail(rule, value, callback) {
      _.debounce(() => {
        this.viewModel.verifyEmail(this.customerId, value, callback);
      }, 500)();
    },
    onValidatePassword(rule, value, callback) {
      _.debounce(() => {
        if (this.isPasswordEnabled()) {
          this.viewModel.verifyPassword(value, callback);
        } else {
          callback();
        }
      }, 500)();
    },
    onValidatePasswordConfirmation(rule, value, callback) {
      _.debounce(() => {
        if (this.isPasswordEnabled()) {
          this.viewModel.verifyPasswordConfirmation(
            this.formData,
            value,
            callback
          );
        } else {
          callback();
        }
      }, 500)();
    },
    isPasswordEnabled() {
        return this.formData.state === 'create' || (this.editPass && this.formData.state === 'update')
    },
    onClearFieldForm(formName) {
      this.onClearValidationField(
        formName,
        "password",
        this.editPass ? this.editPass : !this.editPass
      );
      this.onClearValidationField(
        formName,
        "passwordConfirmation",
        this.editPass ? this.editPass : !this.editPass
      );
      this.$refs[formName].clearValidate(["password", "passwordConfirmation"]);
    },
    onClearValidationField(formName, fieldName, value) {
      if (this.$refs[formName].rules[fieldName]) {
        this.$refs[formName].rules[fieldName][0].required = value;
      }
    },
  },
};
