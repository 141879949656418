import _ from "lodash";
import BaseViewModel from "../../infraestructure/BaseViewModel";
import Sale from "./Sale";
import ConsignmentNote from "./ConsignmentNote";
import User from "../users/User";
import Shipment from "../shipments/Shipment";
import Vehicle from "../vehicles/Vehicle";
import Employee from "../employees/Employee";
import BillRelation from "../billRelations/BillRelation";
import ConsignmentLocation from "./ConsignmentLocation";
import Customer from "../customers/Customer";
import TaxRegime from "../taxRegimes/TaxRegime";

import ReceptionPackage from "../receptions/ReceptionPackage";
import Reception from "../receptions/Reception";
import SaleBill from "./SaleBill";
import SaleBillPayment from "./SaleBillPayment";

export default class SaleViewModel extends BaseViewModel {
  constructor(view) {
    super(view);
  }

  create(data) {
    data.consignmentNote = new ConsignmentNote(
      data.saleBill.consignmentNote,
      "create"
    );
    data.saleBill = new SaleBill(data.saleBill, "update");
    data.consignmentNote.transportationFigures =
      data.saleBill.consignmentNote.transportationFigure?.staff ?? [];
    data.consignmentNote.transportationType =
      data.saleBill.consignmentNote.transportationFigure?.transportationType ??
      null;
    data.consignmentNote.autoTransportations =
      data.saleBill.consignmentNote.autoTransportation?.vehicles ?? [];
    data.consignmentNote.sctPermissionType =
      data.saleBill.consignmentNote.autoTransportation?.sctPermissionType ??
      null;
    return new Sale(data, "create");
  }

  edit(data) {
    data.consignmentNote = new ConsignmentNote(
      data.saleBill.consignmentNote,
      "update"
    );
    data.saleBill = new SaleBill(data.saleBill, "update");
    if (data.saleBill.consignmentNote) {
      data.consignmentNote.transportationFigures =
        data.saleBill.consignmentNote.transportationFigure?.staff ?? [];
      data.consignmentNote.transportationType =
        data.saleBill.consignmentNote.transportationFigure
          ?.transportationType ?? null;
      data.consignmentNote.autoTransportations =
        data.saleBill.consignmentNote.autoTransportation?.vehicles ?? [];
      data.consignmentNote.sctPermissionType =
        data.saleBill.consignmentNote.autoTransportation?.sctPermissionType ??
        null;
    }
    this.mapSaleBillPayments(data.saleBill);

    return new Sale(data, "update");
  }

  save(data) {
    if (data.state === "create") {
      this.api.sales
        .create(data.toSend())
        .then((response) => this.view.onSaveResponse(response.data))
        .catch(this.view.onError);
    } else {
      this.api.sales
        .update(data.id, data.toSend())
        .then((response) => this.view.onSaveResponse(response.data))
        .catch(this.view.onError);
    }
  }

  delete(id) {
    this.api.sales
      .delete(id)
      .then((response) => this.view.onDeleteResponse(response.data))
      .catch(this.view.onError);
  }

  findCollection(filters) {
    this.api.sales
      .find(this.getQueryParameters(filters))
      .then((response) => {
        this.view.onSearchResponse(response.data, response.headers),
          this.view.onSearchResponseMaxCost(response.data);
      })
      .catch(this.view.onError);
  }

  findItem(id) {
    this.api.sales
      .findOne(id)
      .then((response) => this.view.onFindItemResponse(response.data))
      .catch(this.view.onError);
  }

  doBill(id) {
    this.api.sales
      .doBill(id)
      .then((response) => this.view.onDoBillResponse(response.data))
      .catch(this.view.onDoBillError);
  }

  doBillCancellation(id) {
    this.api.sales
      .doBillCancellation(id)
      .then((response) => this.view.onDoBillCancellationResponse(response.data))
      .catch(this.view.onDoBillCancellationError);
  }

  async bindFormView(formData) {
    this.view.config.isLoading = true;

    try {
      const [
        paymentMethods,
        paymentTypes,
        currencies,
        billUsingTypes,
        billTypes,
        measureUnits,
        taxRegimes
      ] = await Promise.all([
        this.api.paymentMethods.find(`Skip=0&Limit=10000&IsActive=true`),
        this.api.paymentTypes.find(`Skip=0&Limit=10000&IsActive=true`),
        this.api.currencies.find(`Skip=0&Limit=10000&IsActive=true`),
        this.api.billUsingTypes.find(`Skip=0&Limit=10000&IsActive=true`),
        this.api.billTypes.find(`Skip=0&Limit=10000&IsActive=true`),
        this.api.measureUnits.find(`Skip=0&Limit=10000&IsActive=true`),
        this.api.taxRegimes.find(`Skip=0&Limit=10000&IsActive=true`),
      ]);

      this.view.paymentMethods = paymentMethods.data.data;
      this.view.paymentTypes = paymentTypes.data.data;
      this.view.currencies = currencies.data.data;
      this.view.billUsingTypes = billUsingTypes.data.data;
      this.view.billTypes = billTypes.data.data;
      this.view.weightUnits = measureUnits.data.data;
      this.view.taxRegimes = this.mapTaxRegimes(taxRegimes.data.data);
      this.view.postalCodes = (formData.saleBill.postalCode) ? [formData.saleBill.postalCode] : []

      this.mapCustomerInSale(formData);
      this.mapSaleBillOption(formData, "paymentType", paymentTypes.data.data);
      this.mapSaleBillOption(formData, "billType", billTypes.data.data);
      this.mapConsignmentNoteOptions(
        formData,
        "weightUnit",
        measureUnits.data.data,
        { field: "name", value: "kilogramo" }
      );
      this.mapConsignmentNoteLocation(formData, "origin", 1);
      this.mapConsignmentNoteLocation(formData, "destination", 2);
      this.mapSaleBillTransportFigures(formData);

      if(!_.isNil(formData.receptions) && !_.isEmpty(formData.receptions)) {
        formData.currentReception = formData.receptions[0]
      } else {
        formData.currentReception = new Reception({})
      }
      setTimeout(() => {
        this.view.config.isLoading = false;
      }, 400);
    } catch (error) {
      this.view.onError(error);
    }
  }

  mapCustomerInSale(formData) {
    if (formData.state === "update") {
      this.view.customers = formData.customer
        ? this.mapCustomers([formData.customer])
        : [];
    }
  }

  /**
   * metodo para mapear propiedades de la factura
   * @param {*} formData
   * @param {*} propertyName
   * @param {*} collection
   */
  mapSaleBillOption(formData, propertyName, collection) {
    var result = formData.saleBill[propertyName]
      ? formData.saleBill[propertyName]
      : _.find(collection, "isDefault");
    this.view.$set(formData.saleBill, propertyName, result);
  }

  /**
   * Metodo para mapear propiedades de la carta porte
   * @param {*} formData
   * @param {*} propertyName
   * @param {*} collection
   * @param {*} filter
   */
  mapConsignmentNoteOptions(formData, propertyName, collection, filter) {
    var result = formData.consignmentNote[propertyName]
      ? formData.consignmentNote[propertyName]
      : _.find(collection, (item) => {
          return item[filter.field].toLowerCase() == filter.value.toLowerCase();
        });
    this.view.$set(formData.consignmentNote, propertyName, result);
  }

  /**
   * Metodo para mapear las ubicaciones de la carta porte
   * @param {*} formData
   * @param {*} propertyName
   * @param {*} locationType
   */
  mapConsignmentNoteLocation(formData, propertyName, locationType) {
    var result =
      _.find(formData.consignmentNote.consignmentLocations, {
        locationType: locationType,
      }) ?? new ConsignmentLocation({}, "create");
    this.view.$set(formData.consignmentNote, propertyName, result);
  }

  /**
   * Metodo para mapear figuras de transporte de la carta porte
   * @param {*} formData
   */
  mapSaleBillTransportFigures(formData) {
    _.forEach(
      formData.consignmentNote.transportationFigures,
      (transportationFigure) => {
        transportationFigure.address = this.getAddress(
          transportationFigure.staff.address || null
        );
      }
    );
  }

  mapSaleBillPayments(formData) {
    if (formData.saleBillPayments) {
      formData.saleBillPayments = formData.saleBillPayments.map(
        (item) => new SaleBillPayment(item, "update")
      );
    }
  }

  bindList() {
    this.api.paymentTypes.find(`Skip=0&Limit=1000000&IsActive=true`)
      .then((response) => {
        this.view.paymentTypeStatus = response.data.data;
        return this.api.saleBillStatuses.find(`Skip=0&Limit=1000000&IsActive=true`);
      })
      .then((response) => {
        this.view.saleBillStatus = response.data.data;
        setTimeout(() => {
          this.view.isListLoading = false;
          this.view.onSearch()
      }, 400);
      })
      .catch(this.view.onError);
  }

  allClearFilters() {
    this.view.filtersSelected = {
      folNumber: '',
      receptionNumber: '',
      saleDate: '',
      paymentType: '',
      billStatus: '',
      total: [0, 0],
    };

    this.view.removeFilter('FolNumber');
    this.view.removeFilter('ReceptionTrackingNumber');
    this.view.removeFilter('StartDate');
    this.view.removeFilter('EndDate');
    this.view.removeFilter('TotalMin');
    this.view.removeFilter('TotalMax');
    this.view.removeFilter('PaymentTypeId');
    this.view.removeFilter('SaleBillStatus');
    this.view.onSearch();
}

  getConsigmentNotExchanger(locationType, consignmentLocations) {
    return (
      _.find(consignmentLocations, { locationType: locationType }) ??
      new ConsignmentLocation({}, "create")
    );
  }

  getAddress(address) {
    var result = [];
    if (address) {
      result.push(address.mainStreet);
      if (address.secondaryStreet) result.push(address.secondaryStreet);
      if (address.extNumber) result.push(`No. Ext: ${address.extNumber}`);
      if (address.intNumber) result.push(`No. Int: ${address.intNumber}`);
      if (address.postalCode) result.push(`C.P.: ${address.postalCode.code}`);
      if (address.neighborhood)
        result.push(`Col. ${address.neighborhood.name}`);
      if (address.city) result.push(`${address.city.name}`);
      if (address.municipality) result.push(`${address.municipality.name}`);
      if (address.district) result.push(`${address.district.name}`);
      if (address.country) result.push(`${address.country.code}`);
    }
    return result.join(", ");
  }

  import(file) {
    this.api.sales
      .import(file)
      .then((response) => this.view.onImportResponse(response.data))
      .catch(this.view.onError);
  }

  findShipments(query) {
    this.api.shipments
      .find(query)
      .then((response) => this.view.onFindShipmentsResponse(response.data))
      .catch(this.view.onError);
  }

  findTransportFigures(query) {
    this.api.employees
      .find(query)
      .then((response) =>
        this.view.onFindTransportFiguresResponse(response.data)
      )
      .catch(this.view.onError);
  }

  findVehicles(query) {
    this.api.vehicles
      .find(query)
      .then((response) => this.view.onFindVehiclesResponse(response.data))
      .catch(this.view.onError);
  }

  findVehicle(id) {
    this.api.vehicles
      .findOne(id)
      .then((response) => this.view.onFindVehicleResponse(response.data))
      .catch(this.view.onError);
  }

  findBillRelations(query) {
    this.api.billRelations
      .find(query)
      .then((response) => this.view.onFindBillRelationsResponse(response.data))
      .catch(this.view.onError);
  }

  findCustomers(query) {
    this.api.customers
      .find(query)
      .then((response) => {
        var customers = this.mapCustomers(response.data.data);
        this.view.onSearchCustomersSuccess(customers);
      })
      .catch((error) => this.view.onSearchCustomerError(error));
  }

  findCustomer(id) {
    this.api.customers
      .findOne(id)
      .then((response) => this.view.onFindCustomerResponse(response.data))
      .catch(this.view.onError);
  }

  //#region

  mapCollection(collection) {
    return collection.map((item) => new Sale(item));
  }

  mapUsers(collection) {
    return collection.map((item) => new User(item));
  }

  mapShipments(collection) {
    return collection.map((item) => new Shipment(item));
  }

  mapTransportFigures(collection) {
    return collection.map((item) => new Employee(item));
  }

  mapVehicles(collection) {
    return collection.map((item) => new Vehicle(item));
  }

  mapBillRelations(collection) {
    return collection.map((item) => new BillRelation(item));
  }

  mapCustomers(collection) {
    return collection.map((item) => new Customer(item));
  }

  mapTaxRegimes(collection) {
    return collection.map((item) => new TaxRegime(item));
  }

  //#endregion

  //#region FACTURACION

  createBillByManifest(shipmentId) {
    this.api.bills
      .createBillByManifest(shipmentId)
      .then((response) =>
        this.view.onCreateBillByManifestResponse(response.data)
      )
      .catch(this.view.onBillError);
  }

  reBillByManifest(shipmentId, data) {
    this.api.bills
      .reBillByManifest(shipmentId, data)
      .then((response) => this.view.onReBillByManifestResponse(response.data))
      .catch(this.view.onBillError);
  }

  cancelBillsByManifest(shipmentId) {
    this.api.bills
      .cancelBillsByManifest(shipmentId)
      .then((response) =>
        this.view.onCancelBillsByManifestResponse(response.data)
      )
      .catch(this.view.onBillError);
  }

  //#endregion

  calculateSummary(param) {
    const { columns, data } = param;
    const sums = [];
    columns.forEach((column, index) => {
      if (index === 0) {
        sums[index] = "Total";
        return;
      }
      const values = data.map((item) => Number(item[column.property]));
      if (!values.every((value) => isNaN(value))) {
        if (column.label === "Peso") {
          sums[index] = values.reduce((prev, curr) => {
            const value = Number(curr);
            if (!isNaN(value)) {
              return prev + curr;
            } else {
              return prev;
            }
          }, 0);
          // sums[index] = this.formatMoney(total);
        } else if (column.label === "Sobre Peso") {
          sums[index] = values.reduce((prev, curr) => {
            const value = Number(curr);
            if (!isNaN(value)) {
              return prev + curr;
            } else {
              return prev;
            }
          }, 0);
          // sums[index] = this.formatMoney(total);
        } else {
          sums[index] = values.reduce((prev, curr) => {
            const value = Number(curr);
            if (!isNaN(value)) {
              return prev + curr;
            } else {
              return prev;
            }
          }, 0);
        }
      } else {
        sums[index] = "";
      }
    });

    return sums;
  }

  editReceptionPackage(data) {
    return new ReceptionPackage(data, "update");
  }

  deleteReceptionPackage(collection, index) {
    collection.splice(index, 1);
  }

  hasDangerousMaterials(receptionPackages) {
    return receptionPackages.some(
      (receptionPackage) => receptionPackage.isDangerousMaterial
    );
  }

  verifyMerchandise(receptions, callback) {
    var merchandises = receptions.reduce(
      (accumulator, reception) =>
        accumulator.concat(reception.receptionPackages),
      []
    );
    if (_.size(merchandises) === 0)
      callback(new Error("Al menos una mercancia requerida."));
    if (!merchandises.every((item) => item.packagingType))
      callback(new Error("Mercancia sin embalaje."));
    callback();
  }

  verifyAutoTransportation(collection, callback) {
    if (_.isNil(collection) || _.isEmpty(collection))
      callback(new Error("Al menos un vehículo requerido."));
    if (!collection.every((item) => item.vehicle))
      callback(new Error("Autotransporte sin vehículo asignado."));
    callback();
  }

  verifyTransportationFigure(collection, callback) {
    if (_.isNil(collection) || _.isEmpty(collection))
      callback(new Error("Al menos una figura de transporte requerido."));
    if (!collection.every((item) => item.staff))
      callback(new Error("Figura de Transporte sin operador asignado."));
    callback();
  }

  upsertReceptionPackage(collection, data, selectedIndex) {
    if (data.state === "update") {
      this.view.$set(collection, selectedIndex, data);
    } else {
      collection.push(data);
    }
    selectedIndex = null;
  }

  getSalesByChunks(sales, chunkSize = 50) {
    return sales
      .map((e, i) => {
        return i % chunkSize === 0 ? sales.slice(i, i + chunkSize) : null;
      })
      .filter((e) => {
        return e;
      });
  }

  batchBillsByChunks(data) {
    this.api.bills
      .doBatchBills({ saleIds: data })
      .then((response) => this.view.onBatchBillsResponse(response.data))
      .catch(this.view.onBatchBillsError);
  }

  updateRestructureLocations(){
    this.api.sales.updateRestructureLocations()
      .then((response) => this.view.onRestructureLocationsResponse(response.data))
      .catch(this.view.onError)
  }

  getTotalPackages(collection) {
    return _.sumBy(collection, "quantity");
  }
}
