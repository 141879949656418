import _ from "lodash";
import BaseForm from "../infraestructure/BaseForm";
import CustomersViewModel from "../../core/features/customers/CustomersViewModel";

export default {
  extends: BaseForm,
  data() {
    return {
      modulePermissions: "Customer",
      customerTabActive: "general-info",
      viewModel: null,
      editPass: false,
      taxRegimes: [],
      postalCodes: [],
      isTableCustomerLocationLoading: false,
      isTableVerificationCodesLoading: false,
      isTableCustomerSubAccountLoading: false,
      isRfcSearching: false,
      rules: {
        fiscalName: [
          {
            message: "Razón Social requerido.",
            trigger: "blur",
          },
        ],
        fiscalCode: [
          {
            validator: this.onSearchRfc,
          },
        ],
        description: [
          {
            required: true,
            validator: this.onVerifyDescription,
            trigger: ["blur", "change"],
          },
        ],
        isActive: [
          {
            required: true,
            message: "El Estado es requerido.",
            trigger: "blur",
          },
        ],
        contactName: [
          {
            required: true,
            message: "Nombre(s) de contacto requerido.",
            trigger: "blur",
          },
        ],
        contactEmail: [
          {
            validator: this.onSearchEmail,
            trigger: "blur",
          },
        ],
        contactPhone: [
          {
            required: true,
            message: "Teléfono contacto requerido.",
            trigger: "blur",
          },
        ],
        masterAccount: {
          userName: [
            {
              trigger: "blur",
              validator: this.onValidateUserName,
            },
          ],
          email: [
            {
              trigger: ["blur", "change"],
              validator: this.onValidateEmail,
            },
            {
              type: "email",
              message: "Ingrese una dirección de correo electrónico correcta",
              trigger: ["blur", "change"],
            },
          ],
          password: [
            {
              validator: this.onValidatePassword,
              trigger: ["blur", "change"],
            },
            {
              min: 6,
              message: "Minimo de 6 digitos",
              trigger: ["blur", "change"],
            },
          ],
          passwordConfirmation: [
            {
              validator: this.onValidatePasswordConfirmation,
              trigger: ["blur", "change"],
            },
            {
              min: 6,
              message: "Minimo de 6 digitos",
              trigger: ["blur", "change"],
            },
          ],
        },
      },
      customerTypes: [
        {
          id: 1,
          name: "Prepago",
        },
        {
          id: 2,
          name: "Crédito",
        },
      ],
      destinations: [],
      customerLocationSelectedIndex: null,
      modalImportLocations: {
        isVisible: false,
        isLoading: false,
        title: "IMPORTAR UBICACIONES",
        onUploadImport: "on-upload-import-customer-locations",
        onCancelImport: "on-cancel-import-customer-locations",
      },
      itemCustomerLocation: null,
      modalCustomerLocationConfig: {
        isVisible: false,
        isLoading: false,
      },
      customerSubAccountSelectedIndex: null,
      itemCustomerSubAccount: null,
      modalCustomerSubAccountConfig: {
        isVisible: false,
        isLoading: false,
      },
      paginationSubAccount: {},
      pageSizes: [50, 100, 150, 200],
      currentPageSubAccount: 1,
      limitSubAccount: 5,
      paginationVerificationCode: {},
      currentPageVerificationCode: 1,
      limitVerificationCode: 5,
    };
  },
  props: {
    config: {
      type: Object,
      required: true,
    },
    formData: {
      type: Object,
      required: true,
    },
  },
  mounted() {
    this.onMountedEvent(
      "on-submited-customer-location",
      this.onUpsertCustomerLocation
    );
    this.onMountedEvent(
      "on-submited-customer-sub-account",
      this.onUpsertCustomerSubAccount
    );
    this.onMountedEvent("on-upload-import-customer-locations", this.onUploadLocationImport);
    this.onMountedEvent("on-cancel-import-customer-locations", this.onCancelLocationImport);
  },
  beforeDestroy() {
    this.onBeforeDestroyEvent(
      "on-submited-customer-location",
      this.onUpsertCustomerLocation
    );
    this.onBeforeDestroyEvent(
      "on-submited-customer-sub-account",
      this.onUpsertCustomerSubAccount
    );
    this.onBeforeDestroyEvent("on-upload-import-customer-locations", this.onUploadLocationImport);
    this.onBeforeDestroyEvent("on-cancel-import-customer-locations", this.onCancelLocationImport);
  },
  created() {
    this.viewModel = new CustomersViewModel(this);
    this.onActive();
  },
  methods: {
    onActive() {
      this.viewModel.bindForm(this.formData);
    },
    onSubmitForm(formName) {
      this.config.isLoading = true;
      this.$refs[formName].validate((isValid) => {
        if (isValid) {
          this.onSave(this.formData);
          return true;
        } else {
          this.config.isLoading = false;
          return false;
        }
      });
    },
    onSaveResponse(response) {
      this.config.isLoading = false;
      this.onDoAfterSaveResponse(response.message, "on-submited-customers");
    },
    onSaveError(error) {
      this.config.isLoading = false;
      this.onError(error);
    },
    onCreateCustomerLocation() {
      this.itemCustomerLocation = this.formData.createCustomerLocation();
      this.modalCustomerLocationConfig.isVisible = true;
    },
    onUpsertCustomerLocation(data) {
      this.isTableCustomerLocationLoading = true;
      setTimeout(() => {
        this.formData.upsertCustomerLocation(
          this.customerLocationSelectedIndex,
          data
        );
        this.isTableCustomerLocationLoading = false;
        this.customerLocationSelectedIndex = null;
      }, 400);
    },
    onChangeOptionCustomerLocation(event) {
      if (event.command === "edit") this.onEditCustomerLocation(event);
      else if (event.command === "delete")
        this.onRemoveCustomerLocation(event.index);
    },
    onEditCustomerLocation(event) {
      this.itemCustomerLocation = this.formData.editCustomerLocation(
        event.item
      );
      this.customerLocationSelectedIndex = event.index;
      this.modalCustomerLocationConfig.isVisible = true;
    },
    onRemoveCustomerLocation(index) {
      this.$confirm("¿Desea eliminar el registro seleccionado?", "Eliminar", {
        confirmButtonText: "Eliminar",
        cancelButtonText: "Cancelar",
        type: "warning",
      })
        .then(() => {
          // this.viewModel.deleteCustomerLocation(this.formData.customerLocations, index)
          this.isTableCustomerLocationLoading = true;
          this.formData.removeCustomerLocation(index);
          setTimeout(() => {
            this.isTableCustomerLocationLoading = false;
          }, 400);
        })
        .catch(() => { });
    },
    oncreateCustomerSubAccount() {
      this.itemCustomerSubAccount = this.formData.createCustomerSubAccount();
      this.modalCustomerSubAccountConfig.isVisible = true;
    },
    onUpsertCustomerSubAccount(data) {
      this.isTableCustomerSubAccountLoading = true;
      setTimeout(() => {
        this.formData.upsertCustomerSubAccount(
          this.customerSubAccountSelectedIndex,
          data
        );
        this.isTableCustomerSubAccountLoading = false;
        this.customerSubAccountSelectedIndex = null;
      }, 400);
    },
    onClearFieldForm(formName) {
      if (this.editPass) {
        if (this.$refs[formName].rules["password"]) this.$refs[formName].rules["password"][0].required = this.editPass;
        if (this.$refs[formName].rules["passwordConfirmation"]) this.$refs[formName].rules["passwordConfirmation"][0].required = this.editPass;
      } else {
        if (this.$refs[formName].rules["password"]) this.$refs[formName].rules["password"][0].required = !this.editPass;
        if (this.$refs[formName].rules["passwordConfirmation"]) this.$refs[formName].rules["passwordConfirmation"][0].required = !this.editPass;
      }
      this.$refs[formName].clearValidate(["password", "passwordConfirmation"]);
    },
    onChangeMasterAccount(data) {
      if (!data) this.onClearFieldsMasterAccount("formData");
    },
    onClearFieldsMasterAccount(formName) {
      this.$refs[formName].clearValidate([
        "firstName",
        "lastName",
        "userName",
        "email",
        "password",
        "passwordConfirmation",
      ]);
      this.$refs[formName].resetFields([
        "firstName",
        "lastName",
        "userName",
        "email",
        "password",
        "passwordConfirmation",
      ]);
    },
    onVerifyRFC(rule, value, callback) {
      _.debounce(() => {
        if (value) {
          this.config.isLoading = true;
          this.config.isLoadingText = "Verificando RFC...";
          this.viewModel.verifyRFC(this.formData.fiscalCode, callback);
        } else {
          callback();
        }
      }, 500)();
    },
    onValidateUserName(rule, value, callback) {
      const isMasterAccountActive = this.formData.masterAccount.isActive
      const canUpdatePassword = this.formData.masterAccount.canUpdatePassword
      _.debounce(() => {
        if (isMasterAccountActive || canUpdatePassword) {
          this.viewModel.verifyUserName(this.formData.id, value, callback)
        } else {
          callback();
        }
      }, 500)();
    },
    onValidateEmail(rule, value, callback) {
      const isMasterAccountActive = this.formData.masterAccount.isActive
      const canUpdatePassword = this.formData.masterAccount.canUpdatePassword
      _.debounce(() => {
        if (isMasterAccountActive || !canUpdatePassword) {
          this.viewModel.verifyEmail(this.formData.id, value, callback)
        } else {
          callback();
        }
      }, 500)();
    },
    onValidatePassword(rule, value, callback) {
      _.debounce(() => {
        if (this.formData.masterAccount.isActive && this.isPasswordEnabled()) {
          this.viewModel.verifyPassword(value, callback)
        } else {
          callback();
        }
      }, 500)();
    },
    onValidatePasswordConfirmation(rule, value, callback) {
      _.debounce(() => {
        if (this.formData.masterAccount.isActive && this.isPasswordEnabled()) {
          this.viewModel.verifyPasswordConfirmation(this.formData, value, callback)
        } else {
          callback();
        }
      }, 500)();
    },
    onFindCities(data) {
      if (data) {
        this.viewModel.findCities(`Name=${data}&IsActive=true`);
      } else {
        this.destinations = [];
      }
    },
    onFindCitiesResponse(response) {
      this.destinations = response.data;
    },
    getVerificationCodeQr(id) {
      this.isTableVerificationCodesLoading = true;
      this.viewModel.getVerificationCodeQr(this.formData.id, id);
    },
    onGetVerificationCodeQrResponse(response) {
      setTimeout(() => {
        this.viewModel.addDownloadEvent(
          response.data,
          response.fileName,
          "application/pdf",
          () => {
            this.notifySuccess("Qr de Verificación Generado.");
            this.isTableVerificationCodesLoading = false;
          }
        );
      }, 400);
    },
    onGetVerificationCodeQrError(error) {
      this.isTableVerificationCodesLoading = false;
      this.onError(error);
    },
    onPageChangeSubAccount(data) {
      this.isTableCustomerSubAccountLoading = true;
      this.currentPageSubAccount = data;
      this.viewModel.findSubAccount(
        this.formData.id,
        `CustomerId=${this.formData.id}&Skip=${(this.currentPageSubAccount -
          1) *
        this.limitSubAccount}&Limit=${this.limitSubAccount}`
      );
    },
    onPageSizeChangeSubAccount(data) {
      this.limitSubAccount = data;
      this.viewModel.findSubAccount(
        this.formData.id,
        `CustomerId=${this.formData.id}&Skip=${(this.currentPageSubAccount -
          1) *
        this.limitSubAccount}&Limit=${this.limitSubAccount}`
      );
    },
    onFindSubAccountError(error) {
      this.onError(error);
    },
    onFindSubAccountResponse(headers, response) {
      setTimeout(() => {
        this.paginationSubAccount = this.getPagination(headers);
        this.paginationSubAccount.limit = response.limit;
        this.productsSubAccount = response.data;
        this.formData.customerSubAccounts = response.data;
        this.isTableCustomerSubAccountLoading = false;
      }, 400);
    },
    onPageChangeVerificationCode(data) {
      this.isTableVerificationCodesLoading = true;
      this.currentPageVerificationCode = data;
      this.viewModel.findVerificationCode(
        this.formData.id,
        `CustomerId=${this.formData.id}&Skip=${(this
          .currentPageVerificationCode -
          1) *
        this.limitVerificationCode}&Limit=${this.limitVerificationCode}`
      );
    },
    onFindVerificationCodeError(error) {
      this.onError(error);
    },
    onFindVerificationCodeResponse(headers, response) {
      setTimeout(() => {
        this.paginationVerificationCode = this.getPagination(headers);
        this.paginationVerificationCode.limit = response.limit;
        this.productsVerificationCode = response.data;
        this.formData.customerVerificationCodes = response.data;
        this.isTableVerificationCodesLoading = false;
      }, 400);
    },
    getPagination(headers) {
      if (!headers) throw new Error("Parametro headers requerido.");
      const pagination = JSON.parse(headers["x-pagination"]);
      return {
        totalItems: pagination.totalItems,
        totalPages: pagination.totalPages,
      };
    },
    onSearchRfc: _.debounce(function (rule, value, callback) {
      this.isRfcSearching = true;
      if (value) {
        this.viewModel.verifyRFC(value, (response) => {
          this.isRfcSearching = false;
          if (response.isValid) {
            callback();
          } else {
            callback(new Error(response.message));
          }
        });
      } else {
        setTimeout(() => {
          this.isRfcSearching = false;
          callback();
        }, 400);
      }
    }, 400),
    onSearchEmail: _.debounce(function (rule, value, callback) {
      if (value) {
        let testEmails = value.split(";");
        // eslint-disable-next-line
        let regex = new RegExp(
          "^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,}$"
        );
        if (testEmails.every((email) => regex.test(email))) {
          callback();
        } else {
          testEmails.forEach((email) => {
            if (!regex.test(email)) {
              callback(new Error(`El correo ${email} no es valido.`));
            }
          });
        }
      } else {
        setTimeout(() => {
          callback();
        }, 400);
      }
    }, 400),
    onVerifyDescription: _.debounce(function (rule, value, callback) {
      if (value) {
        this.viewModel.verifyCustomer(
          `CustomerDescription=${value}&CustomerId=${this.formData.id}`,
          (response) => {
            if (response.isValid) {
              callback();
            } else {
              callback(new Error(response.message));
            }
          }
        );
      } else {
        setTimeout(() => {
          callback(new Error("Descripción requerido"));
        }, 400);
      }
    }, 400),
    onFindPostalCodes(data) {
      this.postalCodes = [];
      if (data)
        this.viewModel.findPostalCodes(
          `Code=${data}&Skip=0&Limit=1000&IsActive=true`
        );
    },
    onFindPostalCodesResponse(response) {
      this.postalCodes = this.viewModel.mapPostalCodes(response.data);
    },
    isPasswordEnabled() {
      const isMasterAccountActive = this.formData.masterAccount.isActive
      const canUpdatePassword = this.formData.masterAccount.canUpdatePassword
      return (isMasterAccountActive && this.formData.state === 'create') ||
        (isMasterAccountActive && this.formData.state === 'update' && (!canUpdatePassword || this.editPass))
    },
    showEditPass() {
      const isMasterAccountActive = this.formData.masterAccount.isActive
      const canUpdatePassword = this.formData.masterAccount.canUpdatePassword
      return this.formData.state === 'update' && isMasterAccountActive && canUpdatePassword
    },
    onChangeCustomerLocationOption(option) {
      if (option.command === 'import') {
        this.modalImportLocations.isVisible = true
      }
    },
    onUploadLocationImport(file) {
      this.modalImportLocations.isLoading = true;
      this.viewModel.importLocations(this.formData.id, file);
    },
    onImportLocationsResponse(response) {
      setTimeout(() => {
        this.modalImportLocations.isLoading = false;
        this.modalImportLocations.isVisible = false;
        this.notifySuccess(response.message);
        // this.onSearch();
      }, 800);
    },
    onImportLocationsError(error){
      setTimeout(() => {
        this.modalImportLocations.isLoading = false;
        this.onError(error)
      }, 400);
    },
    onCancelLocationImport() {
      this.modalImportLocations.isLoading = false
      this.modalImportLocations.isVisible = false
    }
  },
};
