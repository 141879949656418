<script>
import _ from "lodash";
import BaseComponent from "./BaseComponent";

export default {
  extends: BaseComponent,
  data() {
    return {
      statusOptions: [
        {
          value: true,
          label: "Si",
        },
        {
          value: false,
          label: "No",
        },
      ],
      defaultStatus: "",
      showEntries: [
        {
          value: 50,
          label: "50",
        },
        {
          value: 75,
          label: "75",
        },
        {
          value: 100,
          label: "100",
        },
      ],
      defaultShowEntry: 50,
      filters: [],
      collection: [],
      currentPage: 1,
      limit: 50,
      isListLoading: false,
      pagination: {
        totalItems: 0,
        totalPages: 0,
      },
      item: null,
      modalConfig: {
        isVisible: false,
        isLoading: false,
      },
    };
  },
  methods: {
    onSearch() {
      this.defaultFilters();
      this.isListLoading = true;
      setTimeout(() => {
        this.viewModel.findCollection(this.filters);
      }, 400);
    },

    onSearchResponse(response, headers) {
      this.isListLoading = false;
      this.pagination = this.getPagination(headers);
      this.pagination.limit = response.limit;
      this.collection = this.viewModel.mapCollection(response.data);
    },
    onPageChange(data) {
      this.currentPage = data;
      this.onSearch();
    },
    onChangeOption(event) {
      if (event.command === "edit") this.onEdit(event.item.id);
      if (event.command === "delete") this.onDelete(event.item.id);
    },
    onEdit(id) {
      this.viewModel.findItem(id);
    },

    onFindItemResponse(response) {
      this.item = this.viewModel.edit(response.data);
      this.modalConfig.isVisible = true;
    },

    onDelete(id) {
      this.$confirm("¿Desea eliminar el registro seleccionado?", "Eliminar", {
        confirmButtonText: "Eliminar",
        cancelButtonText: "Cancelar",
        type: "warning",
      })
        .then(() => {
          this.viewModel.delete(id);
        })
        .catch(() => {});
    },

    onDeleteResponse(response) {
      this.notifySuccess(response.message);
      this.onSearch();
    },

    getPagination(headers) {
      if (!headers) throw new Error("Parametro headers requerido.");
      const pagination = JSON.parse(headers["x-pagination"]);
      return {
        totalItems: pagination.totalItems,
        totalPages: pagination.totalPages,
      };
    },
    addFilter(filterName, filterValue) {
      this.removeFilter(filterName);
      this.filters.push({ filterName: filterName, filterValue: filterValue });
    },
    removeFilter(filterName) {
      _.remove(this.filters, { filterName: filterName });
    },
    defaultFilters() {
      this.addFilter("skip", (this.currentPage - 1) * this.limit);
      this.addFilter("limit", this.limit);
    },
    onChangeEntries(data) {
      this.limit = data;
      this.onSearch();
    },
    onShowImport() {
      this.modalImport.isVisible = true;
    },
    onUploadImport(file) {
      this.modalImport.isLoading = true;
      this.viewModel.import(file);
    },
    onImportResponse(response) {
      setTimeout(() => {
        this.modalImport.isLoading = false;
        this.modalImport.isVisible = false;
        this.notifySuccess(response.message);
        this.onSearch();
      }, 800);
    },
    onCancelImport() {
      this.modalImport.isVisible = false;
    },
    onExport() {},
  },
};
</script>
