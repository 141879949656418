import BaseForm from "../infraestructure/BaseForm";

export default {
  extends: BaseForm,
  data() {
    return {
      viewModel: null,
      rules: { },
    };
  },
  props: {
    config: {
      type: Object,
      required: true,
    },
    formData: {
      type: Object,
      required: true,
    },
  },
  created() {

  },
  methods: {
    
  },
};