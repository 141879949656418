<script>
import BillInformationBoxComponent from "./BillInformationBoxComponent";
// import AddressBoxView from "../../shared/boxes/addressBox/AddressBoxView";

export default {
  components: {
    // AddressBoxView,
  },
  extends: BillInformationBoxComponent,
};
</script>
<template>
  <div v-loading="isBillingLoading">
    <el-row type="flex" class="row-bg" :gutter="20">
      <el-col :span="5">
        <el-form-item
          label="Tipo de Factura"
          prop="sale.saleBill.billType"
          required
        >
          <el-select
            v-model="formData.billType"
            placeholder="Seleccionar"
            clearable
            debounce
            value-key="id"
            size="medium"
          >
            <el-option
              v-for="item in billTypes"
              :key="item.id"
              :label="item.name"
              :value="item"
            />
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :span="5">
        <el-form-item
          label="Metodo de Pago"
          prop="sale.saleBill.paymentMethod"
          required
        >
          <el-select
            v-model="formData.paymentMethod"
            placeholder="Seleccionar"
            clearable
            debounce
            value-key="id"
            size="medium"
          >
            <el-option
              v-for="item in paymentMethods"
              :key="item.id"
              :label="item.name"
              :value="item"
            />
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :span="5">
        <el-form-item
          label="Tipo de Pago"
          prop="sale.saleBill.paymentType"
          required
        >
          <el-select
            v-model="formData.paymentType"
            placeholder="Seleccionar"
            clearable
            debounce
            value-key="id"
            size="medium"
          >
            <el-option
              v-for="item in paymentTypes"
              :key="item.id"
              :label="item.name"
              :value="item"
            />
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :span="5">
        <el-form-item
          label="Uso de CFDI"
          prop="sale.saleBill.billUsingType"
          required
        >
          <el-select
            v-model="formData.billUsingType"
            placeholder="Seleccionar"
            clearable
            debounce
            value-key="id"
            size="medium"
          >
            <el-option
              v-for="item in billUsingTypes"
              :key="item.id"
              :label="item.name"
              :value="item"
            />
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :span="4">
        <el-form-item label="Moneda" prop="sale.saleBill.currency" required>
          <el-select
            v-model="formData.currency"
            placeholder="Seleccionar"
            clearable
            debounce
            value-key="id"
            size="medium"
          >
            <el-option
              v-for="item in currencies"
              :key="item.id"
              :label="item.name"
              :value="item"
            />
          </el-select>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row type="flex" class="row-bg" :gutter="20">
      <el-col :span="24">
        <el-form-item
          label="Condiciones de Pago"
          prop="sale.saleBill.paymentConditions"
        >
          <el-input
            v-model="formData.paymentConditions"
            syze="medium"
            type="textarea"
          />
        </el-form-item>
      </el-col>
    </el-row>
    <el-row type="flex" class="row-bg" :gutter="20">
      <el-col :span="24">
        <el-form-item label="Cliente" prop="sale.saleBill.customerBill">
          <el-select
            v-model="formData.customerBill"
            placeholder="Seleccionar"
            clearable
            debounce
            value-key="id"
            size="medium"
            remote
            :remote-method="onSearchCustomers"
            filterable
            @change="onChangeCustomer"
          >
            <el-option
              v-for="item in customers"
              :key="item.id"
              :label="item.description"
              :value="item"
            />
          </el-select>
        </el-form-item>
      </el-col>
    </el-row>
    <!--<el-row
      type="flex"
      class="row-bg"
      :gutter="20"
      v-if="formData.customerBill"
    >
      <el-col :span="24">
        <el-form-item
          label="Ubicaciones"
          prop="sale.saleBill.customerBillLocation"
        >
          <el-select
            v-model="formData.customerBillLocation"
            placeholder="Seleccionar"
            clearable
            debounce
            value-key="id"
            size="medium"
            filterable
            @change="onChangeBillLocation"
          >
            <el-option
              v-for="item in customerLocations"
              :key="item.id"
              :label="item.description"
              :value="item"
            />
          </el-select>
        </el-form-item>
      </el-col>
    </el-row>-->
    <el-row type="flex" class="row-bg" :gutter="20">
      <el-col :span="12">
        <el-form-item label="Razón Social" prop="sale.saleBill.fiscalName">
          <el-input v-model="formData.fiscalName" size="medium" type="text" />
        </el-form-item>
      </el-col>
      <el-col :span="6">
        <el-form-item label="R.F.C." prop="sale.saleBill.fiscalCode">
          <el-input v-model="formData.fiscalCode" size="medium" type="text" />
        </el-form-item>
      </el-col>
      <el-col :span="6">
        <el-form-item label="Correo" prop="sale.saleBill.fiscalEmail">
          <el-input v-model="formData.fiscalEmail" size="medium" type="text" />
        </el-form-item>
      </el-col>
    </el-row>
    <el-row type="flex" class="row-bg" :gutter="20">
      <el-col :span="16">
        <el-form-item label="Regimen Fiscal" prop="sale.saleBill.taxRegime">
          <el-select
            v-model="formData.taxRegime"
            placeholder="Seleccionar"
            filterable
            clearable
            debounce
            value-key="id"
            size="large"
          >
            <el-option
              v-for="item in taxRegimes"
              :key="item.id"
              :label="item.getFullDescription()"
              :value="item"
            />
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <el-form-item
          label="Dirección Fiscal (C.P.)"
          prop="sale.saleBill.fiscalPostalCode"
        >
          <el-select
            v-model="formData.billAddress.fiscalPostalCode"
            placeholder="Seleccionar"
            filterable
            clearable
            debounce
            value-key="id"
            size="large"
          >
            <el-option
              v-for="item in postalCodes"
              :key="item.id"
              :label="item.code"
              :value="item"
            />
          </el-select>
        </el-form-item>
      </el-col>
    </el-row>
    <!-- <address-box-view
      :loading="isBillingLoading"
      :form-data="formData.billAddress"
      :prop-name="`${propName}.billAddress`"
      orientation="horizontal"
    /> -->
  </div>
</template>
