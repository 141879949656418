<script>
import ModalShipmentComponent from "./ModalShipmentComponent";
import ModalShipmentSearchReceptionView from "./ModalShipmentSearchReceptionView";

export default {
  components: {
    "modal-shipment-search-reception-view": ModalShipmentSearchReceptionView,
  },
  extends: ModalShipmentComponent,
};
</script>

<template>
  <div>
    <el-dialog
      custom-class="dialog-shipment"
      :visible.sync="config.isVisible"
      :before-close="onCancel"
      :close-on-click-modal="false"
      top="2vh"
    >
      <span
        slot="title"
        class="dialog-title"
      >
        <i class="uil-truck" /> Envios
      </span>
      <el-divider content-position="center">
        DATOS GENERALES DE ENVÍO
      </el-divider>
      <el-form
        ref="formData"
        v-loading="config.isLoading"
        :model="formData"
        :rules="rules"
        :label-position="'top'"
        class="shipment"
      >
        <el-row
          type="flex"
          class="row-bg"
          :gutter="20"
        >
          <el-col :span="3">
            <el-form-item
              label="Número Embarque"
              prop="shipmentNumber"
            >
              <el-input
                v-model="formData.shipmentNumber"
                autocomplete="off"
                size="medium"
                disabled
              />
            </el-form-item>
          </el-col>
          <el-col :span="3">
            <el-form-item
              label="Fecha de Envío"
              prop="shipmentDate"
            >
              <el-date-picker
                v-model="formData.shipmentDate"
                type="date"
                format="dd-MM-yyyy"
                clearable
                size="medium"
              />
            </el-form-item>
          </el-col>
          <el-col :span="14">
            <el-form-item
              label="Destinos"
              prop="destination"
            >
              <el-select
                v-model="formData.destination"
                multiple
                placeholder="Buscar Destino por Código y Seleccionar..."
                filterable
                clearable
                value-key="id"
                remote
                size="medium"
                :remote-method="onFindDestinations"
                :loading="loading"
                @change="onChangeDestination"
              >
                <el-option
                  v-for="item in destinations"
                  :key="item.id"
                  :label="item.name"
                  :value="item"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item
              label="Estado"
              align="center"
            >
              <el-select
                v-model="formData.shipmentStatus"
                placeholder="Seleccionar..."
                filterable
                clearable
                value-key="id"
                size="medium"
              >
                <el-option
                  v-for="item in shipmentStatuses"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="10">
          <el-col :span="6">
            <el-form-item
              label="Vehículo"
              prop="vehicle"
            >
              <el-select
                v-model="formData.vehicle"
                placeholder="Seleccionar Vehículo"
                filterable
                clearable
                value-key="id"
                size="medium"
              >
                <el-option
                  v-for="item in vehicles"
                  :key="item.id"
                  :label="item.toCatalog()"
                  :value="item"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item
              label="Operador"
              prop="operator"
            >
              <el-select
                v-model="formData.operator"
                placeholder="Seleccionar Operador"
                filterable
                clearable
                value-key="id"
                remote
                size="medium"
                :remote-method="onFindoperator"
              >
                <el-option
                  v-for="item in operators"
                  :key="item.id"
                  :label="item.firstName + ' ' + item.lastName"
                  :value="item"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item
              label="Responsable"
              prop="responsible"
            >
              <el-select
                v-model="formData.responsible"
                placeholder="Seleccionar Responsable"
                filterable
                clearable
                value-key="id"
                size="medium"
                disabled
              >
                <el-option
                  v-for="item in responsibles"
                  :key="item.id"
                  :label="item.getResponsibleFullName()"
                  :value="item"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item
              label="Recibido en Oficina por"
              prop="receiverEmployee"
            >
              <el-select
                v-model="formData.receiverEmployee"
                placeholder="Seleccionar Receptor"
                filterable
                clearable
                value-key="id"
                remote
                size="medium"
                :remote-method="onFindReceivers"
              >
                <el-option
                  v-for="item in receivers"
                  :key="item.id"
                  :label="item.firstName + ' ' + item.lastName"
                  :value="item"
                />
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row
          type="flex"
          class="row-bg"
          :gutter="20"
        >
          <el-col :span="24">
            <el-form-item
              label="Observaciones"
              prop="commentaries"
            >
              <el-input
                v-model="formData.commentaries"
                type="textarea"
                :rows="2"
                clearable
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-divider class="mb-0">
          INFORMACIÓN DE GUÍAS
        </el-divider>
        <el-row
          type="flex"
          class="row-bg"
          :gutter="5"
          justify="end"
        >
          <el-button
            class="my-2"
            type="primary"
            size="small"
            icon="el-icon-refresh"
            @click="onFindReceptionDestinations(formData.destination)"
          >
            Cargar Guías
          </el-button>
        </el-row>
        <el-row
          type="flex"
          class="row-bg"
          :gutter="20"
        >
          <el-col :span="24">
            <el-collapse>
              <el-collapse-item
                v-for="(shipmentReception,
                        shipmentReceptionIndex) in onFilterShipmentReceptions(formData.shipmentReceptions)"
                :key="shipmentReceptionIndex"
                :name="shipmentReceptionIndex"
              >
                <template slot="title">
                  <el-row
                    type="flex"
                    class="row-bg"
                    style="width: 100%"
                    :gutter="20"
                  >
                    <el-col :span="22">
                      <i class="uil uil-location-pin-alt" />
                      {{ shipmentReception.destination.name }}
                    </el-col>
                    <el-col :span="2">
                      <el-button
                        type="danger"
                        size="mini"
                        icon="el-icon-delete"
                        @click="
                          onDeleteShipmentReception(shipmentReceptionIndex)
                        "
                      >
                        Eliminar
                      </el-button>
                      <i class="header-icon el-icon-information" />
                    </el-col>
                  </el-row>
                </template>
                <el-row
                  type="flex"
                  class="row-bg"
                  :gutter="20"
                >
                  <el-col :span="24">
                    <el-table
                      v-loading="loading"
                      :data="onFilterReceptions(shipmentReception.receptions)"
                      height="350"
                      style="width: 100%"
                      align="center"
                      header-align="center"
                      border
                      :show-summary="true"
                      :summary-method="onGetSummaries"
                    >
                      <el-table-column
                        type="index"
                        label="#"
                        :index="indexMethod(0)"
                        width="55"
                        align="center"
                      />
                      <el-table-column
                        prop="trackingNumber"
                        label="GUIA"
                        width="150"
                        align="center"
                      />
                      <!-- <el-table-column
                        prop="packages"
                        label="CANTIDAD"
                        width="100"
                        align="center"
                      />-->
                      <el-table-column
                        prop="totalPackages"
                        label="PAQUETES"
                        width="110"
                        align="center"
                      >
                        <template #default="scope">
                          {{ parseTotalPackages(scope.row) }}
                        </template>
                      </el-table-column>
                      <el-table-column
                        prop="senderName"
                        label="REMITENTE"
                        align="center"
                      />
                      <el-table-column
                        prop="receiverName"
                        label="DESTINATARIOS"
                        align="center"
                      />
                      <el-table-column
                        prop="paymentAgainstDelivery"
                        label="COD'S"
                        align="center"
                        width="110"
                      >
                        <template #default="scope">
                          {{
                            scope.row.paymentAgainstDelivery | formatMXNCurrency
                          }}
                        </template>
                      </el-table-column>
                      <el-table-column
                        label="FLETE"
                        align="center"
                      >
                        <el-table-column
                          prop="freightPayable"
                          label="ESTADO"
                          align="center"
                          width="150"
                        >
                          <template #default="scope">
                            <el-tag
                              v-if="scope.row.freightPayable"
                              type="success"
                              effect="plain"
                            >
                              <i class="el-icon-circle-check" /> PAGADO
                            </el-tag>
                            <el-tag
                              v-else
                              type="warning"
                              effect="plain"
                            >
                              <i class="el-icon-warning-outline" /> POR PAGAR
                            </el-tag>
                          </template>
                        </el-table-column>
                        <el-table-column
                          prop="total"
                          label="TOTAL"
                          align="center"
                          width="140"
                        >
                          <template #default="scope">
                            {{ parseTotal(scope.row) | formatMXNCurrency }}
                          </template>
                        </el-table-column>
                      </el-table-column>
                      <el-table-column
                        prop="documentReference"
                        label="REFERENCIA"
                        align="center"
                        width="110"
                      >
                        <template #default="scope">
                          {{ scope.row.documentReference }}
                        </template>
                      </el-table-column>
                      <el-table-column
                        prop="receptionHistoryTrace"
                        label="ESTATUS"
                        align="center"
                        width="220"
                      >
                        <template #default="scope">
                          {{ onGetTraceStatus(scope.row.receptionHistoryTrace) }}
                        </template>
                      </el-table-column>
                      <el-table-column
                        label="ACCIÓN"
                        width="120"
                        align="center"
                      >
                        <template #default="scope">
                          <el-button
                            type="danger"
                            icon="el-icon-delete"
                            size="small"
                            @click="
                              onDeleteReceptionGuide(
                                shipmentReceptionIndex,
                                scope.$index
                              )
                            "
                          >
                            Eliminar
                          </el-button>
                        </template>
                      </el-table-column>
                    </el-table>
                  </el-col>
                </el-row>
              </el-collapse-item>
            </el-collapse>
          </el-col>
        </el-row>
      </el-form>
      <el-row
        type="flex"
        justify="center"
        :gutter="12"
        class="my-3"
      >
        <el-col :span="6">
          <el-card
            :body-style="{ padding: '10px' }"
            shadow="never"
            align="center"
          >
            TOTAL DE GUÍAS:
            <span class="fw-bold mx-2"> {{ getTotalReceptions() }}</span>
          </el-card>
        </el-col>
        <el-col :span="6">
          <el-card
            :body-style="{ padding: '10px' }"
            shadow="never"
            align="center"
          >
            TOTAL DE PAQUETES:
            <span class="fw-bold mx-2"> {{ getTotalPackages() }}</span>
          </el-card>
        </el-col>
        <el-col :span="6">
          <el-card
            :body-style="{ padding: '10px' }"
            shadow="never"
            align="center"
          >
            TOTAL DE C.O.D.:
            <span class="fw-bold mx-2">
              {{ getTotalCods() | formatMXNCurrency }}</span>
          </el-card>
        </el-col>
        <el-col :span="6">
          <el-card
            :body-style="{ padding: '10px' }"
            shadow="never"
            align="center"
          >
            TOTAL DE FLETE:
            <span class="fw-bold mx-2">
              {{ getTotalShippingQuote() | formatMXNCurrency }}</span>
          </el-card>
        </el-col>
      </el-row>
      <span
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          size="small"
          @click="onCancel"
        >Cancelar</el-button>
        <el-button
          type="primary"
          size="small"
          :disabled="!onCanSaveOrUpdate()"
          @click="onSubmitForm('formData')"
        >Guardar</el-button>
      </span>
    </el-dialog>
    <modal-shipment-search-reception-view
      v-if="modalViewFormGuide.isVisible"
      :form-data="itemGuide"
      :config="modalViewFormGuide"
    />
  </div>
</template>
