<script>
import ModalReceptionPackageComponent from "./ModalReceptionPackageComponent";

export default {
  extends: ModalReceptionPackageComponent,
};
</script>

<template>
  <el-dialog
    width="40%"
    :visible.sync="config.isVisible"
    :before-close="onCancel"
    :close-on-click-modal="false"
    :append-to-body="true"
  >
    <span slot="title" class="dialog-title">
      <i class="uil-qrcode-scan" /> Paquete
    </span>

    <el-form
      ref="formData"
      v-loading="config.isLoading"
      :model="formData"
      :rules="rules"
      :label-position="'top'"
    >
      <el-tabs type="border-card">
        <el-tab-pane label="Información General">
          <el-row type="flex" class="row-bg" :gutter="20">
            <el-col :span="24">
              <el-form-item label="Categoría de Paquete" prop="productTypePath">
                <el-cascader
                  v-model="formData.productTypePath"
                  placeholder="Buscar..."
                  :options="productTypes"
                  :filter-method="filterOptions"
                  filterable
                  clearable
                  class="w-100"
                  @change="onChangeProductType"
                />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row type="flex" class="row-bg" :gutter="20">
            <el-col :span="24">
              <el-form-item label="Unidad de Medida" prop="measureUnit">
                <el-select
                  v-model="formData.measureUnit"
                  placeholder="Seleccionar"
                  filterable
                  clearable
                  debounce
                  value-key="id"
                  size="medium"
                  :disabled="true"
                >
                  <el-option
                    v-for="item in measureUnits"
                    :key="item.id"
                    :label="item.name"
                    :value="item"
                  />
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row type="flex" class="row-bg" :gutter="20">
            <el-col :span="12">
              <el-form-item label="Valor Mercancia" prop="merchandiseValue">
                <el-input-number
                  ref="merchandiseValue"
                  v-model="formData.merchandiseValue"
                  autocomplete="off"
                  size="small"
                  :disabled="true"
                />
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="Moneda" prop="currency">
                <el-select
                  v-model="formData.currency"
                  placeholder="Seleccionar moneda"
                  filterable
                  clearable
                  debounce
                  value-key="id"
                  size="small"
                  :disabled="true"
                >
                  <el-option
                    v-for="item in currencies"
                    :key="item.id"
                    :label="item.name"
                    :value="item"
                  />
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row type="flex" class="row-bg" :gutter="20">
            <el-col :span="24">
              <el-form-item label="Descripción" prop="description">
                <el-input
                  ref="description"
                  v-model="formData.description"
                  autocomplete="off"
                  autofocus="true"
                  size="medium"
                  type="textarea"
                  :rows="3"
                />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row type="flex" class="row-bg" :gutter="20">
            <el-col :span="8">
              <el-form-item label="Cantidad" prop="quantity">
                <el-input-number
                  ref="quantity"
                  v-model="formData.quantity"
                  autocomplete="off"
                  autofocus="true"
                  size="medium"
                  :precision="0"
                  @input="onCalculateWeight(formData)"
                />
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="Peso Total Fisico (kg)" prop="weight">
                <el-input-number
                  ref="weight"
                  v-model="formData.weight"
                  autocomplete="off"
                  autofocus="true"
                  size="medium"
                  :precision="0"
                  @input="onCalculateWeight(formData)"
                />
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="Peso Volumetrico" prop="volumetricWeight">
                <el-input-number
                  ref="volumetricWeight"
                  v-model="formData.volumetricWeight"
                  autocomplete="off"
                  autofocus="true"
                  size="medium"
                  :disabled="true"
                  :precision="0"
                />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row type="flex" class="row-bg" :gutter="20">
            <el-col :span="8">
              <el-form-item label="Longitud (cm)" prop="length">
                <el-input-number
                  ref="length"
                  v-model="formData.length"
                  autocomplete="off"
                  autofocus="true"
                  size="medium"
                  :min="0"
                  :precision="0"
                  @input="onCalculateWeight(formData)"
                />
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="Ancho (cm)" prop="width">
                <el-input-number
                  ref="width"
                  v-model="formData.width"
                  autocomplete="off"
                  autofocus="true"
                  size="medium"
                  :min="0"
                  :precision="0"
                  @input="onCalculateWeight(formData)"
                />
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="Alto (cm)" prop="height">
                <el-input-number
                  ref="height"
                  v-model="formData.height"
                  autocomplete="off"
                  autofocus="true"
                  size="medium"
                  :min="0"
                  :precision="0"
                  @input="onCalculateWeight(formData)"
                />
              </el-form-item>
            </el-col>
          </el-row>
        </el-tab-pane>
        <el-tab-pane label="Información de Transportación">
          <el-row type="flex" class="row-bg" :gutter="20">
            <el-col :span="24">
              <el-form-item
                label="Bienes Transportados"
                prop="transportMerchandiseType"
              >
                <el-select
                  v-model="formData.transportMerchandiseType"
                  placeholder="Seleccionar bien..."
                  filterable
                  clearable
                  debounce
                  value-key="id"
                  size="medium"
                  :remote-method="onFindTransportMerchandiseTypes"
                  remote
                  :loading="isMerchandiseTypeLoading"
                  @change="onChangeTransportMerchandiseType"
                  @input="onFindTransportMerchandiseTypes"
                >
                  <el-option
                    v-for="item in stccTypes"
                    :key="item.id"
                    :label="item.name"
                    :value="item"
                  />
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row type="flex" class="row-bg" :gutter="20">
            <el-col :span="24">
              <el-form-item label="Embalaje" prop="packagingType">
                <el-select
                  v-model="formData.packagingType"
                  placeholder="Seleccionar embalaje"
                  filterable
                  clearable
                  debounce
                  value-key="id"
                  size="medium"
                  :remote-method="onFindPackagingTypes"
                  remote
                >
                  <el-option
                    v-for="item in packagingTypes"
                    :key="item.id"
                    :label="item.name"
                    :value="item"
                  />
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row type="flex" class="row-bg" :gutter="20">
            <el-col :span="8">
              <el-form-item
                label="¿Material Peligoso?"
                prop="isDangerousMaterial"
              >
                <el-switch
                  v-model="formData.isDangerousMaterial"
                  active-text="SI"
                  inactive-text="NO"
                  @change="onIsDangerousMaterial"
                />
              </el-form-item>
            </el-col>
            <el-col :span="16">
              <el-form-item label="Material Peligroso" prop="dangerousMaterial">
                <el-select
                  v-model="formData.dangerousMaterial"
                  placeholder="Seleccionar material"
                  filterable
                  clearable
                  debounce
                  value-key="id"
                  size="medium"
                  :remote-method="onFindDangerousMaterials"
                  remote
                  :disabled="!formData.isDangerousMaterial"
                >
                  <el-option
                    v-for="item in dangerousMaterialTypes"
                    :key="item.id"
                    :label="item.name"
                    :value="item"
                  />
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
        </el-tab-pane>
      </el-tabs>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button size="small" @click="onCancel">Cancelar</el-button>
      <el-button type="primary" size="small" @click="onSubmitForm('formData')"
        >Guardar</el-button
      >
    </span>
  </el-dialog>
</template>
