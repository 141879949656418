import BaseForm from "../../../infraestructure/BaseForm";
import AddressBoxViewModel from "../../../../core/features/shared/AddressBoxViewModel";
import { _ } from "core-js";

export default {
  extends: BaseForm,
  data() {
    return {
      countries: [],
      districts: [],
      municipalities: [],
      cities: [],
      neighborhoods: [],
      postalCodes: [],
      viewModel: null,
      isAddressLoading: false,
      isNeigborhoodLoading: false,
      showNeighborhoodName: false,
      neighborSelect: null,
      isPostalCodeLoading: false,
      showPostalCodeName: false,
      isPostalCodeNameValid: false,
      neighborhoodName: null,
    };
  },
  created() {
    this.viewModel = new AddressBoxViewModel(this);
    this.onActive();
  },
  props: {
    formData: {
      type: Object,
      required: true,
    },
    propName: {
      type: String,
      required: true,
    },
    orientation: {
      type: String,
      required: true,
    },
    customer: {
      required: false,
    },
    addNeighborhood: {
      type: Boolean,
      required: false,
      default: true,
    },
    eventAfterLoad: {
      type: String,
      required: false,
    },
  },
  watch: {
    "formData.country"(newCountry) {
      if (newCountry)
        this.viewModel.findDistricts(
          `CountryId=${newCountry.id}&Skip=0&Limit=100000&IsActive=true`
        );
    },
    "formData.district"(newDistrict) {
      if (newDistrict) {
        this.viewModel.findMunicipalities(
          `DistrictId=${newDistrict.id}&Skip=0&Limit=100000&IsActive=true`
        );
        this.viewModel.findCities(
          `DistrictId=${newDistrict.id}&Skip=0&Limit=100000&IsActive=true`
        );
      }
    },
    /* 'formData.municipality'(newMunicipality) {
      if(newMunicipality) this.viewModel.findCities(`DistrictId=${this.formData.district.id}&Skip=0&Limit=100000&IsActive=true`)
    }, */
    "formData.postalCode"(newPostalCode) {
      if (newPostalCode) {
        this.viewModel.findNeighborhoods(
          `PostalCodeId=${newPostalCode.id}&Skip=0&Limit=100000&IsActive=true`
        );
        this.postalCodes = newPostalCode
          ? this.viewModel.mapPostalCodes([newPostalCode])
          : [];
      }
    },
  },
  methods: {
    onActive() {
      this.viewModel.bindFormView(this.formData);
    },
    onBindFormViewResponse() {
      if (this.eventAfterLoad) {
        this.onNotifyEvent(this.eventAfterLoad, this.formData);
      }
    },
    onChangeCountry(country) {
      this.isAddressLoading = true;
      this.districts = [];
      this.municipalities = [];
      this.cities = [];
      this.formData.district = null;
      this.formData.municipality = null;
      this.formData.city = null;
      this.formData.neighborhood = null;
      if (country)
        this.viewModel.findDistricts(
          `CountryId=${country.id}&Skip=0&Limit=10000&IsActive=true`
        );
    },
    onFindAddresss(formData) {
      if (formData.postalCode) {
        this.isAddressLoading = true;
        this.viewModel.findAddress(
          `PostalCode=${formData.postalCode.code}&Skip=0&Limit=10000&IsActive=true`
        );
      } else {
        setTimeout(() => {
          this.notifyWarning("Codigo Postal requerido.");
        }, 400);
      }
    },
    onFindAddressResponse(response) {
      this.setAddress(response.data);
      setTimeout(() => {
        this.isAddressLoading = false;
      }, 400);
    },
    onFindDistrictsResponse(response) {
      this.districts = response.data;
      setTimeout(() => {
        this.isAddressLoading = false;
      }, 400);
    },
    onChangeDistrict(district) {
      this.isAddressLoading = true;
      this.formData.municipality = null;
      this.formData.city = null;
      this.$set(this, "municipalities", []);
      this.$set(this, "cities", []);
      if (district) {
        this.viewModel.findMunicipalities(
          `DistrictId=${district.id}&Skip=0&Limit=10000&IsActive=true`
        );
        this.viewModel.findCities(
          `DistrictId=${district.id}&Skip=0&Limit=100000&IsActive=true`
        );
      }
    },
    onFindMunicipalitiesResponse(response) {
      this.municipalities = response.data;
      setTimeout(() => {
        this.isAddressLoading = false;
      }, 400);
    },
    onFindCitiesResponse(response) {
      this.cities = this.viewModel.mapPlaces(response.data);
      setTimeout(() => {
        this.isAddressLoading = false;
      }, 400);
    },
    onChangeNeighborhood(data) {
      this.$set(this.formData, "neighborhood", data);
      this.$forceUpdate();
    },
    onAddNeighborhood() {
      if (this.formData.city) {
        if (this.neighborhoodName) {
          this.isNeigborhoodLoading = true;
          var neighborhood = this.viewModel.addNeighborhood({
            name: this.neighborhoodName,
            postalCode: this.formData.postalCode,
          });
          this.viewModel.createNeighborhood(
            neighborhood,
            this.formData.postalCode.id
          );
        } else {
          this.notifyWarning("El nombre de la colonia es requerido.");
        }
      } else {
        this.notifyWarning("Ciudad requerida.");
      }
    },
    onCreateNeighborhoodResponse(response) {
      setTimeout(() => {
        this.neighborhoodName = null;
        this.$set(this.formData, "neighborhood", response);
        this.$forceUpdate();
        this.isNeigborhoodLoading = false;
        this.showNeighborhoodName = false;
      }, 400);
    },
    onFindPostalCodes(query) {
      this.postalCodes = [];
      if (query)
        this.viewModel.findPostalCodes(
          `Code=${query}&Skip=0&Limit=10000&IsActive=true`
        );
    },
    onFindPostalCodesResponse(response) {
      this.postalCodes = response;
    },
    onChangePostalCode(postalCode) {
      if (!postalCode) {
        this.isAddressLoading = true;
        this.formData.country = null;
        this.formData.district = null;
        this.formData.municipality = null;
        this.formData.city = null;
        this.formData.neighborhood = null;
        setTimeout(() => {
          this.isAddressLoading = false;
        }, 400);
      }
      this.$forceUpdate();
    },
    onFindPostalCodeResponse(response) {
      setTimeout(() => {
        this.postalCodes = response;
        this.isAddressLoading = false;
        this.$forceUpdate();
      }, 400);
    },
    setAddress(data) {
      this.$set(
        this.formData,
        "mainStreet",
        data && data.mainStreet ? data.mainStreet : this.formData.mainStreet
      );
      this.$set(
        this.formData,
        "secondaryStreet",
        data && data.secondaryStreet
          ? data.secondaryStreet
          : this.formData.secondaryStreet
      );
      this.$set(
        this.formData,
        "extNumber",
        data && data.extNumber ? data.extNumber : this.formData.extNumber
      );
      this.$set(
        this.formData,
        "intNumber",
        data && data.intNumber ? data.intNumber : this.formData.intNumber
      );
      this.$set(this.formData, "postalCode", data ? data : "");
      this.$set(
        this.formData,
        "country",
        data && data.country ? data.country : ""
      );
      this.$set(
        this.formData,
        "district",
        data && data.district ? data.district : ""
      );
      this.$set(
        this.formData,
        "municipality",
        data && data.municipality ? data.municipality : ""
      );
      this.$set(this.formData, "city", data && data.city ? data.city : "");
      this.$set(
        this.formData,
        "neighborhood",
        data && data.neighborhood ? data.neighborhood : ""
      );
      this.$set(
        this,
        "neighborhoods",
        data && data.neighborhoods ? data.neighborhoods : []
      );
      if (_.isNull(data)) {
        this.districts = [];
        this.municipalities = [];
        this.cities = [];
        this.neighborhoods = [];
        this.postalCodes = [];
      }
      if (this.eventAfterLoad) {
        this.onNotifyEvent(this.eventAfterLoad, this.formData);
      }
    },
    onChangeCustomerLocation(location) {
      this.isAddressLoading = true;
      if (location) {
        this.viewModel.findCustomerLocation(this.customer, location);
      } else {
        setTimeout(() => {
          this.setAddress(null);
          this.isAddressLoading = false;
        }, 400);
      }
    },
    onFindCustomerLocationResponse(data) {
      setTimeout(() => {
        this.$set(this.formData, "postalCode", data ? data.postalCode : "");
        this.$set(this.formData, "neighborhood", data ? data.neighborhood : "");
        this.$set(this.formData, "mainStreet", data ? data.mainStreet : "");
        this.$set(
          this.formData,
          "secondaryStreet",
          data ? data.secondaryStreet : ""
        );
        this.$set(this.formData, "extNumber", data ? data.extNumber : "");
        this.$set(this.formData, "intNumber", data ? data.intNumber : "");
        // this.viewModel.bindFormView(this.formData);
        this.isAddressLoading = false;
      }, 400);
    },
    onShowNeighborhoodInput() {
      let requirements = this.viewModel.getNeighborhoodRequirements(
        this.formData
      );
      if (requirements.length == 0) {
        this.showNeighborhoodName = true;
        this.$nextTick(() => {
          this.$set(this, "neighborhoodName", "");
        });
      } else {
        this.onShowWarning(requirements);
      }
    },
    onCancelNeighborhoodInput() {
      this.showNeighborhoodName = false;
      this.formData.neighborhoodName = "";
    },
    onShowPostalCodeInput() {
      let requirements = this.viewModel.getPostalCodeRequirements(
        this.formData
      );
      if (requirements.length == 0) {
        this.showPostalCodeName = true;
      } else {
        this.onShowWarning(requirements);
      }
    },
    onCancelPostalCodeInput() {
      this.showPostalCodeName = false;
      this.formData.postalCodeName = "";
    },
    onAddPostalCode() {
      this.onPostalCodeNameValidation(this.formData.postalCodeName, (error) => {
        if (error && error.message) this.notifyWarning(error.message);
      });

      if (this.isPostalCodeNameValid) {
        if (this.formData.city) {
          this.isPostalCodeLoading = true;
          this.viewModel.createPostalCode({
            code: this.formData.postalCodeName,
            name: this.formData.postalCodeName,
            cityId: this.formData.city.id,
            isActive: true,
          });
        }
      }
    },
    onCreatePostalCodeResponse(response) {
      this.postalCodes.push(response);
      setTimeout(() => {
        this.formData.postalCode = response;
        this.formData.postalCodeName = "";
        this.showPostalCodeName = false;
        this.isPostalCodeLoading = false;
      }, 400);
    },
    onCreatePostalCodeError(error) {
      this.isPostalCodeLoading = false;
      this.onError(error);
    },
    onPostalCodeNameValidation(value, callback) {
      if (!value) {
        this.isPostalCodeNameValid = false;
        callback(new Error("El código postal es requerido."));
      } else {
        if (value.length != 5) {
          this.isPostalCodeNameValid = false;
          callback(new Error("El código postal debe tener 5 dígitos."));
        } else if (!this.isNumber(value)) {
          this.isPostalCodeNameValid = false;
          callback(new Error("Solo valores numericos."));
        } else {
          this.isPostalCodeNameValid = true;
          callback();
        }
      }
    },
    isNumber(str) {
      var pattern = /^\d+\.?\d*$/;
      return pattern.test(str);
    },
    onFindNeighborhoodsResponse(response) {
      this.neighborhoods = response.data;
    },
  },
};
