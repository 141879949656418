<script>
import FormComponent from "../../../infraestructure/BaseList";
import PaymentComplementDetailViewVue from "./PaymentComplementDetailView";
import PaymentComplementViewModel from './PaymentComplementViewModel'

export default {
  components: {
    "payment-complement-detail-view": PaymentComplementDetailViewVue,
  },
  extends: FormComponent,
  props: {
    formData: {
      type: Object,
      required: true,
    },
    propName: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      viewModel: null,
      isDetailLoading: false,
    };
  },

  created() {
    this.viewModel = new PaymentComplementViewModel(this)
    this.onActive();
  },
  methods: {
    onActive() {},
    onAddPayment() {
      this.isDetailLoading = true;
      setTimeout(() => {
        this.formData.addSaleBillPayment({
          paymentDate: null,
          paymentType: null,
          currency: null,
          exchangeRate: 1,
          amount: 0,
          bankName: null,
          bankAccount: null,
          operationNumber: null,
          saleBillPaymentRelateds: []
        });
        this.isDetailLoading = false;
      }, 400);
    },
    onRemovePayment(index) {
        this.isDetailLoading = true;
        setTimeout(() => {
            this.formData.deleteSaleBillPayment(index)
            this.isDetailLoading = false;
        }, 400);
        
    }
  },
};
</script>
<template>
  <div class="sale_payment_complement">
    
      <el-row type="flex" class="row-bg reception-steps-wrapper" :gutter="20">
      <el-col :span="24" align="right">
        <el-button size="medium" @click="onAddPayment" type="primary" icon="el-icon-circle-plus-outline">
          Agregar Pago
        </el-button>
      </el-col>
    </el-row>
    <el-row type="flex" class="row-bg reception-steps-wrapper" :gutter="20">
      <el-col v-loading="isDetailLoading" :span="24">
        <el-form-item :prop="propName">
          <el-collapse>
            <payment-complement-detail-view
              v-for="(item, index) in formData.getSaleBillPayments()"
              :key="index"
              :form-data="item"
              :current-index="index"
              :on-remove="onRemovePayment"
            />
          </el-collapse>
        </el-form-item>
      </el-col>
    </el-row>
  </div>
</template>
