import _ from 'lodash';
import BaseList from "../infraestructure/BaseList";
import ShipmentViewModel from "../../core/features/shipments/ShipmentViewModel";

export default {
    extends: BaseList,
    data() {
        return {
            modulePermissions: 'Delivery',
            viewModel: null,
            isMoreFilterVisible: false,
            filtersSelected: {
                shipmentNumber: '',
                beginingDate: '',
                destinyName: '',
                responsible: '',
                operator: '',
                shipmentStatus: '',
            },
            itemDestination: {},
            itemSelected: null,
            destinations: [],
            receivers: [],
            operators: [],
            responsibles: [],
            shipmentStatuses: ShipmentViewModel.shipmentStatuses,
            modalViewModalDestinations: {
                isActive: false,
                isLoading: false,
            },
            modalImport: {
                isVisible: false,
                isLoading: false,
                title: "IMPORTAR ENVIOS",
                onUploadImport: "on-upload-import-destinations",
                onCancelImport: "on-cancel-import-destinations",
            },
        };
    },
    mounted() {
        this.onMountedEvent("on-submited-shipment", this.onSearch);
        this.onMountedEvent("on-upload-import-destinations", this.onUploadImport);
        this.onMountedEvent("on-cancel-import-destinations", this.onCancelImport);
    },
    beforeDestroy() {
        this.onBeforeDestroyEvent("on-submited-shipment", this.onSearch);
        this.onBeforeDestroyEvent(
            "on-upload-import-destinations",
            this.onUploadImport
        );
        this.onBeforeDestroyEvent(
            "on-cancel-import-destinations",
            this.onCancelImport
        );
    },
    created() {
        this.viewModel = new ShipmentViewModel(this);
        this.onActive();
    },
    methods: {
        onActive() {
            this.isListLoading = true;
            this.viewModel.bindList()
        },
        onSelectShipment() { },
        onCreate() {
            this.item = this.viewModel.create({
                isActive: false,
                deliveryDate: new Date(),
                shipmentReceptions: [],
                shipmentStatus: 1
            });
            this.modalConfig.isVisible = true;
        },
        onCreateSearchFormDestinations() {
            this.modalViewModalDestinations.isActive = true;
        },
        onMoreFilters() {
            this.isMoreFilterVisible = true
        },
        onSearchByShipmentNumber: _.debounce(function (text) {
            if (text) {
                this.addFilter('ShipmentNumber', text)
            } else {
                this.removeFilter('ShipmentNumber')
            }
            this.onSearch();
        }, 500),
        onSearchBeginingDate: _.debounce(function (text) {
            if (text) {
                this.addFilter('BeginningDate', text[0])
                this.addFilter('EndingDate', text[1])
            } else {
                this.removeFilter('BeginningDate')
                this.removeFilter('EndingDate')
            }
            this.onSearch();
        }, 500),
        searchDestinationFilter(data) {
            if (data) {
                this.addFilter('DestinationPlaceId', data)
            } else {
                this.removeFilter('DestinationPlaceId')
            }
            this.onSearch()
        },
        onChangeRisponsable(data) {
            if (data) {
                this.addFilter("ResponsibleId", data);
            } else {
                this.removeFilter("ResponsibleId");
            }
            this.onSearch();
        },
        onChangeOperator(data) {
            if (data) {
                this.addFilter("OperatorId", data);
            } else {
                this.removeFilter("OperatorId");
            }
            this.onSearch();
        },
        searchShipmentStatusFilter(data) {
            if (data !== null) {
                this.addFilter('ShipmentStatus', data)
            } else {
                this.removeFilter('ShipmentStatus')
            }
            this.onSearch()
        },
        onClearFilters() {
            this.viewModel.allClearFilters();
        },
        onChangeOption(event) {
            if (event.command === "edit") this.onEdit(event.item.id);
            if (event.command === "delete") this.onDelete(event.item.id);
            if (event.command === "print-manifest") this.onPrintManifest(event.item.id);
        },
        onPrintManifest(deliveryId) {
            this.isListLoading = true
            this.viewModel.printManifest(deliveryId);
        },
        onPrintManifestResponse(response) {
            setTimeout(() => {
                this.viewModel.addDownloadEvent(response.data, response.fileName, 'application/pdf', () => {
                    this.notifySuccess("Manifiesto Generado.")
                    this.isListLoading = false
                })
            }, 400);
        },
    },
};
