import { MXN } from '../../helpers/MoneyHelper'

export default class CustomerReception {
    constructor(options, state) {
      if(state){
        this.state = state
      }
      Object.assign(this, options)
    }
    
    getDescriptionFullAddress() {
        return `${this.mainStreet || ''}, ${this.secondaryStreet || ''} ${this.extNumber || 'SN'}, ${this.neighborhood} C.P: ${this.postalCode.name} ${this.city}`
    }
   
    getDescriptionFullAddressReceiver() {
      return `${this.mainStreet || ''}, ${this.secondaryStreet || ''} ${this.extNumber || 'SN'}, ${this.neighborhood.name} C.P: ${this.postalCode.name} ${this.city.name}`
    }

    getFullDescription() {
      return `Código: ${this.code}  |  Origen: ${this.origin} - Destino: ${this.destination}  |  Precio: ${MXN(this.amount).format()} MXN`
    }

    toCreate() {
      return {
        customerId: this.customerId,
        sender: this.mapShipmentContact(this.sender),
        receiver: this.mapShipmentContact(this.receiver),
        orderFiscal: this.mapOrderFiscal(this.orderFiscal),
        serviceCostId: (this.serviceCost) ? this.serviceCost.id : null,
        receptionServiceType: this.receptionServiceType,
        customerSolvencyId: this.customerSolvencyId,
        traces: this.receptionPackages,
      }
    }

    mapShipmentContact(contact) {
      return {
        name: contact.contactName,
        email: contact.contactEmail,
        phone: contact.contactPhone,
        observation: contact.observation,
        addressId: (contact.customerLocation) ? contact.customerLocation.id : null,
        mainStreet: (contact.customerLocation) ? contact.customerLocation.mainStreet : null,
        secondaryStreet: (contact.customerLocation) ? contact.customerLocation.secondaryStreet : null,
        intNumber: (contact.customerLocation) ? contact.customerLocation.intNumber : null,
        extNumber: (contact.customerLocation) ? contact.customerLocation.extNumber : null,
        postalCodeId: (contact.customerLocation && contact.customerLocation.postalCode) ? contact.customerLocation.postalCode.id : null,
        neighborhoodId: (contact.customerLocation && contact.customerLocation.neighborhood) ? contact.customerLocation.neighborhood.id : null
      }
    }
  
    mapOrderFiscal(orderFiscal) {
      return {
        fiscalCustomerId: orderFiscal.fiscalCustomerId
        // fiscalName: orderFiscal.fiscalName,
        // fiscalCode: orderFiscal.fiscalCode,
        // fiscalEmail: orderFiscal.fiscalEmail,
        // taxRegimeId: (this.taxRegime) ? this.taxRegime.id : null,
        // mainStreet: (orderFiscal.fiscalLocation) ? orderFiscal.fiscalLocation.mainStreet : null,
        // secondaryStreet: (orderFiscal.fiscalLocation) ? orderFiscal.fiscalLocation.secondaryStreet : null,
        // intNumber: (orderFiscal.fiscalLocation) ? orderFiscal.fiscalLocation.intNumber : null,
        // extNumber: (orderFiscal.fiscalLocation) ? orderFiscal.fiscalLocation.extNumber : null,
        // postalCodeId: (orderFiscal.fiscalLocation && orderFiscal.fiscalLocation.postalCode) ? orderFiscal.fiscalLocation.postalCode.id : null,
        // neighborhoodId: (orderFiscal.fiscalLocation && orderFiscal.fiscalLocation.neighborhood) ? orderFiscal.fiscalLocation.neighborhood.id: null
      }
    }


    toSend() {
      return {
        customerId: this.customerId,
        
        paymentType: this.paymentType,
        payerResponsible: this.payerResponsible,
        receptionType: this.receptionType,
        receptionServiceType: this.receptionServiceType,

        receptionDate: this.receptionDate,
        receiverName: this.receiverName,
        receiverEmail: this.receiverEmail,
        receiverObservation: this.receiverObservation,
        receiverPhone: this.receiverPhone,
        
        senderName: this.senderName,
        senderEmail: this.senderEmail,
        senderObservation: this.senderObservation,
        senderPhone: this.senderPhone,
        serviceCostId: (this.serviceCost) ? this.serviceCost.id: null,
       
        customerSolvencyId: this.customerSolvencyId,
        serviceDeliveryPrice: this.serviceDeliveryPrice,
        paymentAgainstDelivery: this.paymentAgainstDelivery,
        
        originPlaceId: (this.originPlace) ? this.originPlace.id : null,
        destinationPlaceId: (this.destinationPlace) ? this.destinationPlace.id : null,
        
        senderLocationId: this.mapSenderLocation(),
        receiverLocationId: this.mapReceiverLocation(),
        receptionPackages: this.mapReceptionPackages(this.receptionPackages)
      }
    }

    mapSenderLocation() {
      if(this.senderLocationId) return this.senderLocationId
      else if(this.senderCustomerLocation) return this.senderCustomerLocation.id
      else return null
    }
  
    mapReceiverLocation() {
      if(this.receiverLocationId) return this.receiverLocationId
      else if(this.receiverCustomerLocation) return this.receiverCustomerLocation.id
      else return null
    }
    
    mapReceptionPackages(collection) {
    var result = []
    collection.forEach((item) => {
      for (let index = 0; index < item.quantity; index++) {
        result.push({
          description: item.description,
          weight: item.weight,
          height: item.height,
          width: item.width,
          length: item.length,
          quantity: item.quantity,
          volumetricWeight: item.volumetricWeight,
          subtotal: item.subtotal,
          measureUnitId: (item.measureUnit) ? item.measureUnit.id: null,
          productTypeId: (item.productType) ? item.productType.id: null
        })
      }
    })
    return result
    }
}