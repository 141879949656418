<script>
import BillConceptsBoxComponent from "./BillConceptsBoxComponent";

export default {
  extends: BillConceptsBoxComponent,
};
</script>
<template>
  <div>
    <el-row type="flex" class="row-bg" :gutter="20">
      <el-col :span="24" align="right">
        <el-button type="primary" @click="onAddConcept"
          size="medium" icon="el-icon-circle-plus-outline">Agregar Concepto</el-button
        >
      </el-col>
    </el-row>
    <el-row
      type="flex"
      class="row-bg"
      :gutter="20"
    >
      <el-col :span="24">
        <el-form-item prop="concepts">
          <el-table
            v-loading="isLoadingTable"
            :data="formData.getConcepts()"
            style="width: 100%"
          >
            <el-table-column
              label="CONCEPTO"
              width="350"
            >
              <template slot-scope="scope">
                <el-select
                  v-model="scope.row.billConcept"
                  filterable
                  clearable
                  placeholder="Seleccionar..."
                  value-key="id"
                  size="medium"
                  @change="onBillConceptChange(scope.row)"
                  @focus="onFocusConcept(scope.$index)"
                >
                  <el-option
                    v-for="item in billConcepts"
                    :key="item.id"
                    :label="item.name"
                    :value="item"
                  />
                </el-select>
              </template>
            </el-table-column>
            <el-table-column label="DESCRIPCIÓN">
              <template slot-scope="scope">
                <el-input
                  v-model="scope.row.description"
                  size="medium"
                  type="textarea"
                  @focus="onFocusConcept(scope.$index)"
                />
              </template>
            </el-table-column>
            <el-table-column
              label="CANTIDAD"
              width="180"
              align="center"
            >
              <template slot-scope="scope">
                <el-input-number
                  v-model="scope.row.qty"
                  size="medium"
                  :min="1"
                  @focus="onFocusConcept(scope.$index)"
                  @change="onFocusConcept(scope.$index)"
                  :precision="3"
                />
              </template>
            </el-table-column>
            <el-table-column
              label="V. UNITARIO"
              width="180"
              align="center"
            >
              <template slot-scope="scope">
                <el-input-number
                  v-model="scope.row.amount"
                  size="medium"
                  :min="0"
                  :precision="3"
                  @focus="onFocusConcept(scope.$index)"
                  @change="onFocusConcept(scope.$index)"
                />
              </template>
            </el-table-column>
            <el-table-column
              label="SUBTOTAL"
              width="180"
              align="center"
            >
              <template slot-scope="scope">
                {{ scope.row.qty * scope.row.amount | formatMXNCurrency }}
              </template>
            </el-table-column>
            <el-table-column
                  label="Opciones"
                  width="120"
                  align="center"
                  show-overflow-tooltip
                >
                  <template #default="scope">
                    <el-dropdown @command="onChangeOption">
                      <el-button size="small">
                        <i class="el-icon-more" />
                      </el-button>
                      <template #dropdown>
                        <el-dropdown-menu>
                          <!-- <el-dropdown-item
                            :command="{ command:'edit',item: scope.row, index: scope.$index }"
                            v-if="scope.row.id"
                          >
                            <i class="el-icon-edit" /> Mostrar
                          </el-dropdown-item>-->
                          <el-dropdown-item
                            :command="{ command:'delete',item: scope.row, index: scope.$index }"
                          >
                            <i class="el-icon-delete" /> Eliminar
                          </el-dropdown-item>
                        </el-dropdown-menu>
                      </template>
                    </el-dropdown>
                  </template>
                </el-table-column>
          </el-table>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row
      type="flex"
      class="row-bg"
      :gutter="20"
    >
      <el-col :span="18" />
      <el-col
        :span="3"
        align="center"
      >
        Subtotal
      </el-col>
      <el-col
        :span="3"
        align="center"
      >
      {{ formData.getSubtotal() | formatMXNCurrency }}
      </el-col>
    </el-row>
    <el-row
      v-if="isDiscountAvailable()"
      type="flex"
      class="row-bg"
      :gutter="20"
    >
      <el-col :span="18" />
      <el-col
        :span="3"
        align="center"
      >
        Descuento
      </el-col>
      <el-col
        :span="3"
        align="center"
      >
      {{ formData.getDiscount() | formatMXNCurrency }}
      </el-col>
    </el-row>
    <el-row
      v-if="isDiscountAvailable()"
      type="flex"
      class="row-bg"
      :gutter="20"
    >
      <el-col :span="18" />
      <el-col
        :span="3"
        align="center"
      >
        Subtotal con Descuento
      </el-col>
      <el-col
        :span="3"
        align="center"
      >
      {{ formData.getSubtotal() - formData.getDiscount() | formatMXNCurrency }}
      </el-col>
    </el-row>
    <el-row
      type="flex"
      class="row-bg"
      :gutter="20"
    >
      <el-col :span="18" />
      <el-col
        :span="3"
        align="center"
      >
        I.V.A
      </el-col>
      <el-col
        :span="3"
        align="center"
      >
      {{ formData.getFederalTax() | formatMXNCurrency }}
      </el-col>
    </el-row>
    <el-row
      type="flex"
      class="row-bg"
      :gutter="20"
    >
      <el-col :span="18" />
      <el-col
        :span="3"
        align="center"
      >
        Total
      </el-col>
      <el-col
        :span="3"
        align="center"
      >
      {{ formData.getTotal() | formatMXNCurrency }}
      </el-col>
    </el-row>
  </div>
</template>
