<script>
import ModalCustomerLocationComponent from "./ModalCustomerLocationComponent";
import AddressBoxView from "../shared/boxes/addressBox/AddressBoxView";

export default {
  components: {
    "address-box-view": AddressBoxView,
  },
  extends: ModalCustomerLocationComponent,
};
</script>

<template>
  <el-dialog custom-class="dialog-customer-location dialog" :visible.sync="config.isVisible" :before-close="onCancel"
    :close-on-click-modal="false">
    <span slot="title" class="dialog-title">
      <i class="uil-comment-notes" /> Ubicación de Cliente
    </span>

    <el-form ref="formData" v-loading="config.isLoading" :model="formData" :rules="rules" :label-position="'top'">
      <el-row type="flex" class="row-bg" :gutter="20">
        <el-col :span="6">
          <el-form-item label="Código Ubicación" prop="code">
            <el-input ref="code" v-model="formData.code" autocomplete="off" autofocus="true" size="medium"
              :disabled="true" />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="Descripción" prop="description">
            <el-input v-model="formData.description" autocomplete="off" size="medium" />
          </el-form-item>
        </el-col>
        <el-col :span="6" align="center">
          <el-form-item label="Estado">
            <el-switch v-model="formData.isActive" active-text="Activo" inactive-text="Inactivo" />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row type="flex" class="row-bg" :gutter="20">
        <el-col :span="6">
          <el-form-item label="Código de Cliente" prop="code">
            <el-input ref="customerCode" v-model="formData.customerCode" autocomplete="off" autofocus="true"
              size="medium" />
          </el-form-item>
        </el-col>
        <el-col :span="11" align="center">
          <el-form-item label="¿Mostrar código de cliente en documentación?">
            <el-switch v-model="formData.isCustomerCodeDocUsed" active-text="Si" inactive-text="No" />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row type="flex" class="row-bg" :gutter="20">
        <el-col :span="24">
          <address-box-view v-if="formData" :form-data="formData" prop-name="" orientation="vertical" />
        </el-col>
      </el-row>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button size="small" @click="onCancel">Cancelar</el-button>
      <el-button type="primary" size="small" @click="onSubmitForm('formData')">Guardar</el-button>
    </span>
  </el-dialog>
</template>
