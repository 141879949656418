<script>
import ModalBillConceptComponent from "./ModalBillConceptComponent";

export default {
  extends: ModalBillConceptComponent,
};
</script>

<template>
  <el-dialog
    custom-class="dialog-bill-concept"
    :visible.sync="config.isVisible"
    :before-close="onCancel"
    :close-on-click-modal="false"
  >
    <span
      slot="title"
      class="dialog-title"
    >
      <i class="uil-briefcase-alt" /> Concepto de Facturación
    </span>

    <el-form
      ref="formData"
      v-loading="config.isLoading"
      :model="formData"
      :rules="rules"
      :label-position="'top'"
    >
      <el-row
        type="flex"
        class="row-bg"
        :gutter="20"
      >
        <el-col :span="14">
          <el-form-item
            label="Código"
            prop="code"
          >
            <el-input
              ref="code"
              v-model="formData.code"
              autocomplete="off"
              autofocus="true"
              size="medium"
            />
          </el-form-item>
        </el-col>
        <el-col
          :span="10"
          align="center"
        >
          <el-form-item label="Estado">
            <el-switch
              v-model="formData.isActive"
              active-text="Activo"
              inactive-text="Inactivo"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row
        type="flex"
        class="row-bg"
        :gutter="20"
      >
        <el-col :span="24">
          <el-form-item
            label="Nombre"
            prop="name"
          >
            <el-input
              v-model="formData.name"
              autocomplete="off"
              size="medium"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row
        type="flex"
        class="row-bg"
        :gutter="20"
      >
        <el-col :span="24">
          <el-form-item
            label="Plantilla"
            prop="template"
          >
            <el-input
              v-model="formData.template"
              autocomplete="off"
              size="medium"
              type="textarea"
            />
            <small>Código Origen = {origin} | Código Destino = {destination} | No. Guía = {trackingNumber}</small><br />
            <small>Cantidad Sobrepeso = {overweight} | Guía Física = {physicalTrackingNumber}</small>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row
        type="flex"
        class="row-bg"
        :gutter="20"
      >
        <el-col :span="24">
          <el-form-item
            label="Producto / Servicio"
            prop="billConceptKey"
          >
            <el-select
              v-model="formData.billConceptKey"
              size="medium"
              filterable
              remote
              reserve-keyword
              placeholder="Buscar..."
              :remote-method="onFindBillConceptKeys"
              value-key="id"
            >
              <el-option
                v-for="item in billConceptKeys"
                :key="item.id"
                :label="item.toCatalog()"
                :value="item"
              />
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row
        type="flex"
        class="row-bg"
        :gutter="20"
      >
        <el-col :span="18">
          <el-form-item
            label="Unidad de Medida"
            prop="measureUnit"
          >
            <el-select
              v-model="formData.measureUnit"
              size="medium"
              filterable
              remote
              reserve-keyword
              placeholder="Buscar..."
              :remote-method="onFindMeasureUnits"
              value-key="id"
            >
              <el-option
                v-for="item in measureUnits"
                :key="item.id"
                :label="item.toCatalog()"
                :value="item"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col
          :span="6"
          align="center"
        >
          <el-form-item label="¿Es Sobre Peso?">
            <el-switch
              v-model="formData.isOverWeight"
              active-text="Si"
              inactive-text="No"
            />
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <span
      slot="footer"
      class="dialog-footer"
    >
      <el-button
        size="small"
        @click="onCancel"
      >Cancelar</el-button>
      <el-button
        type="primary"
        size="small"
        :disabled="!onCanSaveOrUpdate()"
        @click="onSubmitForm('formData')"
      >Guardar</el-button>
    </span>
  </el-dialog>
</template>
