import BaseModel from "../../infraestructure/BaseModel";

export default class SaleConcept extends BaseModel {
  constructor(options, state) {
    super(options, state);
  }

  static DECIMAL_PRECISION = 2;

  getTotal() {
    return this.amount;
  }

  formatTransportation(options) {
    const { template, trackingNumber, origin, destination, physicalTrackingNumber } = options || {};

    this.description = `${template}`
      .replace("{trackingNumber}", trackingNumber)
      .replace("{origin}", origin)
      .replace("{destination}", destination)
      .replace("{physicalTrackingNumber}", physicalTrackingNumber);
  }

  calculateTransportation(options) {
    const { qtyPackages, deliveryPrice } = options || {};
    this.qty = qtyPackages
    let totalTransportation = (qtyPackages * deliveryPrice)
    this.total = parseFloat(totalTransportation);
    let totalAmount = (this.total / this.qty)
    this.amount = parseFloat(totalAmount);
  }

  formatOverWeight(options) {
    const { template, overWeight } = options || {};
    this.description = `${template}`.replace(
      "{overweight}",
      overWeight.toFixed(SaleConcept.DECIMAL_PRECISION)
    );
  }

  calculateOverWeight(options) {
    const { qty, overWeight, extraWeightCost } = options || {};
    this.qty = qty;
    let totalOverWeight = (overWeight * extraWeightCost)
    this.total = parseFloat(totalOverWeight);
    let totalAmount = (this.total / this.qty)
    this.amount = parseFloat(totalAmount);
  }

  reset() {
    this.description = "";
    this.amount = 0;
    this.qty = 0;
    this.unitPrice = 0;
    this.total = 0
  }

  
}
