export default class TaxRegime {
    constructor(options, state) {
        if (state) {
            this.state = state
        }
        Object.assign(this, options)
    }

    toSend() {
        return {
            id: this.id,
            code: this.code,
            name: this.name,
            description: this.description,
            isPhysicalPerson: this.isPhysicalPerson,
            isMoral: this.isMoral,
            isActive: this.isActive
        }
    }

    getFullDescription(){
        return `${this.code} - ${this.name}`
    }
}