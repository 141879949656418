<script>
import TrackingDeliveryPageComponent from "./TrackingDeliveryPageComponent";
import HeroSection from "../components/HeroSection";
import WebsiteHeaderView from "@/components/shared/headers/WebsiteHeaderView";
import WebsiteFooterView from "@/components/shared/footers/WebsiteFooterView";
import PreloaderSpinner from "@/components/preloader/PreloaderSpinner.vue";


export default {
  name: "TrackingDeliveryPageView",
  components: {
    "hero-section": HeroSection,
    "website-header-view": WebsiteHeaderView,
    "website-footer-view": WebsiteFooterView,
    "preloader-spinner": PreloaderSpinner,
  },
  extends: TrackingDeliveryPageComponent,
};
</script>
<template>
  <div>
    <preloader-spinner v-if="loading === true" />
    <!-- Start .header-1-->
    <website-header-view />
    <!-- Start #page-title-->
    <hero-section
      :is-home="false"
      url="/website/images/sliders/rastreo.jpg"
      title-sub-heading="Rastreo de envío"
      title-heading="Localiza E Informate Del Estado De Tu Envío"
      :breadcrumb-item="[
        {
          href: 'index.html',
          name: 'Inicio',
        },
        {
          href: '',
          name: 'Servicios',
        },
        {
          href: '',
          name: 'Rastreo De Envío',
        },
      ]"
    />
    <!-- End #page-title-->
    <!--
      ============================
      Track Shimpent Section
      ============================
      -->
    <section
      id="track-shipment"
      class="track-shipment request-quote contact-card"
    >
      <div class="container">
        <div class="row">
          <!-- Start .col-lg-4-->
          <div class="col-sm-12 col-md-12 col-lg-4 order-2 order-lg-0">
            <div class="sidebar sidebar-case-study">
              <!-- Start .widget-categories-->
              <div class="widget widget-categories">
                <div class="widget-title">
                  <h5>Rastreo de envíos</h5>
                  <p>
                    Sigue estos sencillos pasos para conocer el estado de tu
                    envío
                  </p>
                </div>
                <div class="widget-content">
                  <ul class="list-unstyled">
                    <div class="my-3">
                      <li class="media pt-3 px-3 bg-white">
                        <img
                          src="/website/images/rastreo/codigo.png"
                          class="mr-3"
                          alt="..."
                        >
                        <div class="media-body">
                          <h6 class="mt-0 mb-1">
                            Código
                          </h6>
                          <p>
                            Ten cerca tu código de 10 digitos o número de guía
                            de 12
                          </p>
                        </div>
                      </li>
                    </div>
                    <div class="my-3">
                      <li class="media pt-3 px-3 bg-white">
                        <img
                          src="/website/images/rastreo/formulario.png"
                          class="mr-3"
                          alt="..."
                        >
                        <div class="media-body">
                          <h6 class="mt-0 mb-1">
                            Ingresa tu Código
                          </h6>
                          <p>
                            En el formulario ingresa tu código o número de guía
                            y da clic en rastrear
                          </p>
                        </div>
                      </li>
                    </div>
                    <div class="my-3">
                      <li class="media pt-3 px-3 bg-white">
                        <img
                          src="/website/images/rastreo/Estatus.png"
                          class="mr-3"
                          alt="..."
                        >
                        <div class="media-body">
                          <h6 class="mt-0 mb-1">
                            Estatus del envío
                          </h6>
                          <p>
                            Conocé si tu envío ya ha sido entregado o se
                            encuentra en ruta
                          </p>
                        </div>
                      </li>
                    </div>
                    <div class="my-3">
                      <li class="media pt-3 px-3 bg-white">
                        <img
                          src="/website/images/rastreo/ruta.png"
                          class="mr-3"
                          alt="..."
                        >
                        <div class="media-body">
                          <h6 class="mt-0 mb-1">
                            En ruta
                          </h6>
                          <p>
                            Da clic en
                            <span class="text-dark">"bitácora"</span> y conocé
                            el último registro de su ubicación de tu envío.
                          </p>
                        </div>
                      </li>
                    </div>
                  </ul>
                </div>
              </div>
              <!-- End .widget-categories -->
              <div class="widget widget-reservation">
                <img
                  src="assets/images/blog/sidebar/reservation.jpg"
                  alt="Background Image"
                >
                <div class="widget-content">
                  <h5>¿No localizas tu envío?</h5>
                  <p>
                    Si tienes problemas para localizar tu envío o presenta algún
                    inconveniente ponte en contacto con nosotros. Estamos para
                    asesorarte y ayudarte.
                  </p>
                  <a
                    class="btn btn--transparent btn--inverse btn--block"
                    href="javascript:void(0)"
                  >Contacto</a>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 col-lg-8">
            <div class="track-form quote-form contact-body trackFormActive">
              <h5 class="card-heading">
                Formulario de rastreo
              </h5>
              <el-form
                ref="formDataTracking"
                :model="formDataTracking"
                :rules="rules"
                label-width="120px"
                class="demo-dynamic"
                :label-position="'top'"
              >
                <el-form-item
                  prop="trackingNumberForm"
                  label="Código Rastreo"
                >
                  <small>Ingresé los número de guías seperados por una coma
                    ','.</small>
                  <el-input
                    v-model="formDataTracking.trackingNumberForm"
                    type="textarea"
                    placeholder="Ingresé los número de guías seperados por una coma ','."
                    :rows="10"
                    clearable
                  />
                </el-form-item>
                <el-form-item class="d-flex justify-content-center">
                  <el-button
                    type="primary"
                    @click="submitForm('formDataTracking')"
                  >
                    Rastrear envío
                  </el-button>
                  <el-button
                    @click="resetForm('formDataTracking')"
                  >
                    Limpiar
                  </el-button>
                </el-form-item>
              </el-form>
            </div>

            <div
              v-if="formData !== null"
              class="mt-5"
            >
              <el-collapse
                v-if="searchResult"
                v-model="activeName"
                accordion
              >
                <el-collapse-item
                  v-for="(item, index) in formData"
                  :key="index"
                  :name="index"
                >
                  <template slot="title">
                    Guia {{ item.trackingNumber
                    }}<i class="header-icon el-icon-information" />
                  </template>
                  <div class="reception-info-wrapper">
                    <el-row
                      type="flex"
                      class="row-bg mb-3"
                      :gutter="20"
                    >
                      <el-col :span="12">
                        <el-card class="box-card">
                          <div
                            slot="header"
                            class="clearfix text-center"
                          >
                            <h6>
                              <i class="el-icon-discover fs-2" /><br>
                              Número de Guía
                            </h6>
                          </div>
                          <div class="text-center">
                            {{ item.trackingNumber }}
                          </div>
                        </el-card>
                      </el-col>
                      <el-col :span="12">
                        <el-card class="box-card">
                          <div
                            slot="header"
                            class="clearfix text-center"
                          >
                            <h6>
                              <i class="el-icon-map-location fs-2" /><br>
                              Origen
                            </h6>
                          </div>
                          <div class="text-center">
                            {{ item.origin }}
                          </div>
                        </el-card>
                      </el-col>
                    </el-row>
                    <el-row
                      type="flex"
                      class="row-bg mb-3"
                      :gutter="20"
                    >
                      <el-col :span="12">
                        <el-card class="box-card">
                          <div
                            slot="header"
                            class="clearfix text-center"
                          >
                            <h6>
                              <i class="el-icon-location fs-2" /><br>
                              Destino
                            </h6>
                          </div>
                          <div class="text-center">
                            {{ item.destiny }}
                          </div>
                        </el-card>
                      </el-col>
                      <el-col :span="12">
                        <el-card class="box-card">
                          <div
                            slot="header"
                            class="clearfix text-center"
                          >
                            <h6>
                              <i class="el-icon-date fs-2" /><br>
                              Fecha de Recepción
                            </h6>
                          </div>
                          <div class="text-center">
                            {{ item.receptionDate | formatDate }}
                          </div>
                        </el-card>
                      </el-col>
                    </el-row>
                    <!-- <el-row
                      type="flex"
                      class="row-bg "
                      :gutter="20"
                    >
                      <el-col :span="12">
                        <el-card class="box-card">
                          <div
                            slot="header"
                            class="clearfix text-center"
                          >
                            <h6>
                              <i class="el-icon-user-solid fs-2" /><br>
                              Remitente | Teléfono
                            </h6>
                          </div>
                          <div class="text-center">
                            {{ item.senderName }} |
                            {{ item.senderPhone }}
                          </div>
                        </el-card>
                      </el-col>
                      <el-col :span="12">
                        <el-card class="box-card">
                          <div
                            slot="header"
                            class="clearfix text-center"
                          >
                            <h6>
                              <i class="el-icon-user fs-2" /><br>
                              Destinatario | Teléfono
                            </h6>
                          </div>
                          <div class="text-center">
                            {{ item.receiverName }} |
                            {{ item.receiverPhone }}
                          </div>
                        </el-card>
                      </el-col>
                    </el-row> -->
                    <el-row
                      type="flex"
                      class="row-bg "
                    >
                      <el-col :span="24">
                        <el-tabs
                          v-model="activeTabName"
                          class="px-3"
                        >
                          <el-tab-pane
                            label="Historial"
                            name="historyTrace"
                          >
                            <el-timeline class="px-2">
                              <el-timeline-item
                                v-for="(itemHistory,
                                        index) in item.historyTrace"
                                :key="index"
                                :icon="onGetIconTrackingStatus(itemHistory.status)"
                                :color="onGetColorTrackingStatus(itemHistory.status)"
                                size="large"
                                :timestamp="itemHistory.date | formatDate"
                              >
                                {{ onGetNameTrackingStatus(itemHistory.status) }}
                              </el-timeline-item>
                            </el-timeline>
                          </el-tab-pane>
                        </el-tabs>
                      </el-col>
                    </el-row>
                  </div>
                </el-collapse-item>
              </el-collapse>
              
              <div v-if="formData.length === 0 && searchResult === false">
                <transition name="fade">
                  <div
                    v-if="!loading"
                    class="card bg-custom-charts"
                  >
                    <div class="card-body">
                      <div class="row align-items-center">
                        <div class="col-sm-5">
                          <h4 class="text-white">
                            No se encontraron registros
                          </h4>
                        </div>
                        <div class="col-sm-7">
                          <div class="mt-4 mt-sm-0">
                            <img
                              src="@/assets/website/images/404/notfound.png"
                              class="img-fluid"
                              alt=""
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                  <!-- end card-body-->
                  </div>
                </transition>
              </div>
            </div>
          </div>
        </div>
        <!-- End .row-->
      </div>
      <!-- End .container-->
    </section>
    <!--
      ============================
      Footer #1
      ============================
      -->
    <website-footer-view />
  </div>
</template>
