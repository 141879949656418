<script>
import ListReceptionsComponent from "./ListReceptionsComponent";
import ModalReceptionView from "./ModalReceptionView";
import ModalImportView from "../shared/modals/ModalImportView";

export default {
  components: {
    "modal-reception-view": ModalReceptionView,
    "modal-import-view": ModalImportView,
  },
  extends: ListReceptionsComponent,
};
</script>

<template>
  <div>
    <div class="row mb-2">
      <div class="d-flex justify-content-between">
        <div>
          <div
            id="entradas"
            class="mb-3"
          >
            <span class="mx-2">Mostrar</span>
            <el-select
              v-model="defaultShowEntry"
              placeholder="Opción"
              size="small"
              @change="onChangeEntries"
            >
              <el-option
                v-for="item in showEntries"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
            <span class="mx-2">registros </span>
          </div>
        </div>
        <div class="d-flex justify-content-between">
          <div
            id="buttonlist"
            class="mb-3"
          >
            <el-button type="danger" class="filter-btn" icon="fa fa-filter" size="medium" @click="onMoreFilters">
              Filtrar por:
            </el-button>
            <el-dropdown
              size="medium"
              class="more-options-action"
            >
              <el-button
                type="plain"
                size="medium"
              >
                <i class="uil-ellipsis-v" /> Más Opciones
                <i class="el-icon-arrow-down el-icon--right" />
              </el-button>
              <el-dropdown-menu slot="dropdown">
                
                <!-- <el-dropdown-item class="my-2">
                  <el-button
                    size="medium"
                    class="w-100 text-start"
                    @click="onShowImport"
                  >
                    <i class="el-icon-upload2" /> Importar
                  </el-button>
                </el-dropdown-item>
                <el-dropdown-item class="my-2">
                  <el-button
                    size="medium"
                    class="w-100 text-start"
                    @click="onExport"
                  >
                    <i class="el-icon-download" /> Exportar
                  </el-button>
                </el-dropdown-item> -->
              </el-dropdown-menu>
            </el-dropdown>
            <el-button
              v-if="onUserPermission('Permission.Reception.Create')"
              type="primary"
              size="medium"
              class="main-action"
              :disabled="!onCanCreate(modulePermissions)"
              @click="onCreate"
            >
              <i class="fas fa-plus-circle" /> Crear Recepción
            </el-button>
          </div>
        </div>
      </div>
    </div>
    <!-- end row -->
    <div class="table-responsive mb-4">
      <el-table
        v-if="onUserPermission('Permission.Reception.Read')"
        ref="multipleTable"
        v-loading="isListLoading"
        class="table table-centered table-nowrap mb-0"
        :data="collection"
        style="width: 100%"
        @selection-change="onSelectBranchOffice"
      >
        <el-table-column
          type="selection"
          width="55"
        />
        <el-table-column
          property="trackingNumber"
          label="No. Guía"
          align="center"
          width="190"
        >
          <template #default="scope">
            {{ scope.row.trackingNumber }}
          </template>
        </el-table-column>
        <el-table-column
          label="Fecha Recepción"
          property="receptionDate"
          show-overflow-tooltip
          align="center"
          width="150"
        >
          <template slot-scope="scope">
            <el-tag
              type="primary"
              effect="plain"
            >
              <i class="el-icon-date" />
              <span class="mx-2">
                {{ scope.row.receptionDate | formatDate }}
              </span>
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column
          label="Tipo de Recepción"
          property="receptionType"
          show-overflow-tooltip
          align="center"
          width="140"
        >
          <template #default="scope">
            <el-tag
              type="primary"
              effect="plain"
            >
              <i class="el-icon-success" />
              {{ scope.row.receptionType }}
            </el-tag>
          </template>
        </el-table-column>
        
        <el-table-column
          label="Tipo de Servicio"
          property="receptionServiceType"
          show-overflow-tooltip
          align="center"
          width="140"
        >
          <template #default="scope">
            <el-tag
              type="primary"
              effect="plain"
            >
              <i class="el-icon-success" />
              {{ scope.row.receptionServiceType }}
            </el-tag>
          </template>
        </el-table-column>
        <!-- <el-table-column
          label="Estatus de Pago"
          show-overflow-tooltip
          align="center"
          width="140"
        >
          <template #default="scope">
            <el-tag
              v-if="scope.row.freightPayable"
              type="success"
              effect="plain"
            >
              <i class="el-icon-success" />
              {{ scope.row.freightPayableStatus }}
            </el-tag>
            <el-tag
              v-else
              type="danger"
              effect="plain"
            >
              <i class="el-icon-error" />
              {{ scope.row.freightPayableStatus }}
            </el-tag>
          </template>
        </el-table-column> -->
        
        <el-table-column
          property="senderPlace"
          label="Origen"
          show-overflow-tooltip
          align="center"
        />
        <el-table-column
          property="receiverPlace"
          label="Destino"
          show-overflow-tooltip
          align="center"
        />
        <el-table-column
          property="documentReference"
          label="Documento Referencia"
          show-overflow-tooltip
          align="center"
        />

        <!--<el-table-column
          label="Seguimiento"
          show-overflow-tooltip
          align="center"
          width="200"
        >
          <template #default="scope">
            <el-tag
              v-if="scope.row.lastStatus"
              :type="onGetStyleTrackingStatus(scope.row.status)"
              effect="plain"
            >
              <i :class="onGetIconTrackingStatus(scope.row.status)" />
              {{ scope.row.lastStatus }}
            </el-tag>
          </template>
        </el-table-column>-->
        <el-table-column
          label="Opciones"
          width="120"
          align="center"
          show-overflow-tooltip
        >
          <template #default="scope">
            <el-dropdown @command="onChangeOption">
              <el-button size="small">
                <i class="el-icon-more" />
              </el-button>
              <template #dropdown>
                <el-dropdown-menu>
                  <el-dropdown-item
                    :command="{
                      command: 'generate-tracking-document',
                      item: scope.row,
                    }"
                  >
                    <i class="mdi mdi-file-pdf-outline" /> Generar Guía
                  </el-dropdown-item>
                  <el-dropdown-item
                    :command="{
                      command: 'generate-tracking-labels',
                      item: scope.row,
                    }"
                  >
                    <i class="mdi mdi-file-pdf-outline" /> Generar Etiquetas
                  </el-dropdown-item>
                  
                  <el-dropdown-item
                    v-if="onUserPermission('Permission.Reception.Update')"
                    :disabled="!onCanRead(modulePermissions)"
                    :command="{ command: 'edit', item: scope.row }"
                  >
                    <i class="el-icon-edit" /> Mostrar
                  </el-dropdown-item>
                  <el-dropdown-item
                    v-if="onUserPermission('Permission.Reception.Delete')"
                    :disabled="!onCanDelete(modulePermissions)"
                    :command="{ command: 'delete', item: scope.row }"
                  >
                    <i class="el-icon-delete" /> Eliminar
                  </el-dropdown-item>
                </el-dropdown-menu>
              </template>
            </el-dropdown>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="row mt-4">
      <div class="col-sm-6">
        <div>
          <p class="mb-sm-0">
            Mostrar 1 a {{ pagination.limit }} de
            {{ pagination.totalItems }} registros
          </p>
        </div>
      </div>
      <div class="col-sm-6">
        <div class="float-sm-end">
          <el-pagination
            :page-count="pagination.totalPages"
            :current-page.sync="currentPage"
            layout="prev, pager, next"
            @current-change="onPageChange"
          />
        </div>
      </div>
    </div>
    <modal-import-view :conf="modalImport" />
    <modal-reception-view
      v-if="modalConfig.isVisible"
      :form-data="item"
      :config="modalConfig"
    />
    <el-drawer
      custom-class="filters-drawer"
      :visible.sync="isMoreFilterVisible"
      direction="rtl"
      size="20%"
    >
      <template slot="title">
        <span class="title">Filtros</span>
        <el-button class="btn-text" type="text" @click="onClearFilters"
          >Borrar todos los filtros</el-button
        >
      </template>
      <el-row class="row-bg wrapper-more-filters" :gutter="20">
        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
          <div class="block">
            <span class="block-title">Guía:</span>
            <el-input
              type="text"
              placeholder="Buscar por Guía"
              v-model="filtersSelected.receptionNumber"
              clearable
              size="small"
              v-on:input="onSearchByReceptionNumber"
            ></el-input>
          </div>
        </el-col>
      </el-row>
      <el-row class="row-bg wrapper-more-filters" :gutter="20">
        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
          <div class="block">
            <span class="block-title">Origen:</span>
            <el-select
              v-model="filtersSelected.originName"
              placeholder="Buscar Origen"
              filterable
              clearable
              debounce
              value-key="id"
              size="medium"
              @change="searchOriginFilter"
            >
              <el-option
                v-for="item in origins"
                :key="item.id"
                :label="item.name"
                :value="item"
              />
            </el-select>
          </div>
        </el-col>
      </el-row>
      <el-row class="row-bg wrapper-more-filters" :gutter="20">
        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
          <div class="block">
            <span class="block-title">Destino:</span>
            <el-select
              v-model="filtersSelected.destinyName"
              placeholder="Buscar Destino"
              filterable
              clearable
              debounce
              value-key="id"
              size="medium"
              @change="searchDestinationFilter"
            >
              <el-option
                v-for="item in destinations"
                :key="item.id"
                :label="item.name"
                :value="item"
              />
            </el-select>
          </div>
        </el-col>
      </el-row>
      <el-row class="row-bg wrapper-more-filters" :gutter="20">
        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
          <div class="block">
            <span class="block-title">Fecha:</span>
            <el-date-picker
              v-model="filtersSelected.beginingDate"
              type="daterange"
              size="small"
              clearable
              range-separator="-"
              start-placeholder="F. Inicio"
              end-placeholder="F. Fin"
              value-format="yyyy-MM-dd"
              @input="onSearchBeginingDate"
            />
          </div>
        </el-col>
      </el-row>
      <el-row class="row-bg wrapper-more-filters" :gutter="20">
        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
          <div class="block">
            <span class="block-title">Tipo Recepción:</span>
            <el-select
              v-model="filtersSelected.receptionType"
              placeholder="Seleccionar"
              :popper-append-to-body="false"
              clearable
              @change="searchReceptionTypeFilter"
            >
              <el-option
                v-for="item in receptionTypes"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </div>
        </el-col>
      </el-row>
      <el-row class="row-bg wrapper-more-filters" :gutter="20">
        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
          <div class="block">
            <span class="block-title">Tipo de Servicio:</span>
            <el-select
              v-model="filtersSelected.receptionServiceType"
              placeholder="Seleccionar"
              :popper-append-to-body="false"
              clearable
              @change="searchReceptionServiceTypeFilter"
            >
              <el-option
                v-for="item in receptionServiceTypes"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </div>
        </el-col>
      </el-row>
      <el-row class="row-bg wrapper-more-filters" :gutter="20">
        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
          <div class="block">
            <span class="block-title">Seguimiento:</span>
            <el-select
              v-model="filtersSelected.statusTracking"
              placeholder="Seleccionar"
              :popper-append-to-body="false"
              clearable
              @change="serachStatusTrackingFilter"
            >
              <el-option
                v-for="item in receptionTrackingStatus"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </div>
        </el-col>
      </el-row>
    </el-drawer>
  </div>
</template>
