import BaseViewModel from '../../infraestructure/BaseViewModel'
import CustomerSolvency from './CustomerSolvency'
import Customer from '../customers/Customer'
import PrepaidPlan from '../prepaidPlans/PrepaidPlan';
import ReceptionHelper from '../../helpers/ReceptionHelper';

export default class CustomerSolvencyViewModel extends BaseViewModel{
    constructor(view) {
        super(view)
    }

    static CONSTANT_SOLVENCY_TYPES = ReceptionHelper.CONSTANT_SOLVENCY_TYPES

    static solvencyTypes = ReceptionHelper.solvencyTypes

    static paymentTypes = ReceptionHelper.paymentTypes;

    create(data) {
        return new CustomerSolvency(data, 'create')
    }

    edit(data) {
        return new CustomerSolvency(data, 'update')
    }

    save(data) {
        if (data.state === 'create') {
            this.api.customerSolvencies.create(data.toSend())
            .then((response) => this.view.onSaveResponse(response.data))
            .catch(this.view.onError)
            
        } else {
            this.api.customerSolvencies.update(data.id, data.toSend())
            .then((response) => this.view.onSaveResponse(response.data))
            .catch(this.view.onError)
        }
    }

    delete(id) {
        this.api.customerSolvencies.delete(id)
            .then((response) => this.view.onDeleteResponse(response.data))
            .catch(this.view.onError)
    }

    findCollection(customerId, filters) {
        this.api.customerSolvencies.find(customerId, this.getQueryParameters(filters))
            .then((response) => this.view.onSearchResponse(response.data, response.headers))
            .catch(this.view.onError)
    }

    findItem(id) {
        this.api.customerSolvencies.findOne(id)
            .then((response) => this.view.onFindItemResponse(response.data))
            .catch(this.view.onError)
    }

    bindForm(formData) {
        if (formData.state === 'update') {
            this.view.customers = [formData.customer]
            this.view.prepaidPlans = (formData.prepaidPlan) ? [formData.prepaidPlan] : []
        }
    }

    //#region 
    
    mapCollection(collection){
        return collection.map((item) => new CustomerSolvency(item))
    }

    mapCustomers(collection) {
        return collection.map((item) => new Customer(item))
    }

    mapPrepaidPlans(collection) {
        return collection.map((item) => new PrepaidPlan(item))
    }

    //#endregion

    //#region QUERIES
    
    findCustomers(criteria) {
        this.api.customers.find(criteria)
            .then((response) => this.view.onFindCustomersResponse(response.data))
            .catch(this.view.onError)
    }

    findPrepaidPlans(criteria) {
        this.api.prepaidPlans.find(criteria)
            .then((response) => this.view.onFindPrepaidPlansResponse(response.data))
            .catch(this.view.onError)
    }

    findReceptionsBySolvency(customerId, customerSolvencyId) {
        this.api.customerSolvencies.findReceptionsBySolvency(customerId, customerSolvencyId)
            .then((response) => this.view.onFindReceptionsBySolvencyResponse(response.data))
            .catch(this.view.onError)
    }

    //#endregion

    //#region VERIFICATIONS

    isPrepaid(type) {
        return type && type == ReceptionHelper.CONSTANT_SOLVENCY_TYPES.PREPAID
    }
}
