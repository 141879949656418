import _ from "lodash";
import moment from "moment";
import BaseViewModel from "../../infraestructure/BaseViewModel";
import Reception from "./Reception";
import ReceptionPackage from "./ReceptionPackage";
import Customer from "../customers/Customer";
import CustomerLocation from "../customerLocation/CustomerLocation";
import ServiceCost from "../serviceCosts/ServiceCost";
import Destination from "../destinations/Destination";
import Neighborhood from "../neighborhoods/Neighborhood";
import ReceptionHelper from "../../helpers/ReceptionHelper";
import RegexHelper from "../../helpers/RegexHelper";
import User from "../users/User";
import ConsignmentNote from "../sales/ConsignmentNote";
import Sale from "../sales/Sale";
import Vehicle from "../vehicles/Vehicle";
import TaxRegime from "../taxRegimes/TaxRegime";

export default class ReceptionsViewModel extends BaseViewModel {
  constructor(view) {
    super(view);
  }

  static SolvencyType = ReceptionHelper.SolvencyType;
  static receptionTypes = ReceptionHelper.receptionTypes;
  static receptionServiceTypes = ReceptionHelper.receptionServiceTypes;
  static receptionTrackingStatus = ReceptionHelper.receptionTrackingStatus;
  static payerResponsibles = [
    {
      id: "sender",
      name: "Remitente",
      icon: "uil-sign-out-alt",
    },
    {
      id: "receiver",
      name: "Destinatario",
      icon: "uil-sign-in-alt",
    },
  ];

  //#region RECEPTION

  create(data) {
    let item = new Reception(data, "create");
    item.consignmentNote = new ConsignmentNote(data.consignmentNote);
    item.sale = new Sale(data.sale);
    return item;
  }

  edit(data) {
    let item = new Reception(data, "update");
    item.consignmentNote = new ConsignmentNote(data.consignmentNote);
    item.senderLocation = item.senderLocation
      ? new CustomerLocation(item.senderLocation, "update")
      : null;
    item.receiverLocation = item.receiverLocation
      ? new CustomerLocation(item.receiverLocation, "update")
      : null;
    return item;
  }

  save(data) {
    let currentReceptionId = null;
    if (data.state === "create") {
      this.api.receptions
        .create(data.toSend())
        .then((response) => {
          currentReceptionId = response.data.id;
          data.sale.receptions = [{ id: currentReceptionId }];
          // data.sale.consignmentNote = data.consignmentNote.toSend();
          return this.api.sales.create(data.sale.toSend());
        })
        .then((response) => {
          response.data.receptionId = currentReceptionId;
          this.view.onSaveResponse(response.data);
        })
        .catch(this.view.onSaveErrorResponse);
    } else {
      this.api.receptions
        .update(data.id, data.toEditSend())
        .then((response) => this.view.onSaveResponse(response.data))
        .catch(this.view.onSaveErrorResponse);
    }
  }

  doSale(data) {
    this.api.sales
      .create(data.toSend())
      .then((response) => this.view.onDoSaleResponse(response.data))
      .catch(this.view.onDoSaleError);
  }

  delete(id) {
    this.api.receptions
      .delete(id)
      .then((response) => this.view.onDeleteResponse(response.data))
      .catch(this.view.onError);
  }

  findCollection(filters) {
    this.api.receptions
      .find(this.getQueryParameters(filters))
      .then((response) =>
        this.view.onSearchResponse(response.data, response.headers)
      )
      .catch(this.view.onError);
  }

  findItem(id) {
    this.api.receptions
      .findOne(id)
      .then((response) => this.view.onFindItemResponse(response.data))
      .catch(this.view.onError);
  }

  bindForm() { }

  bindFormFinancialHistory() {
    this.api.destinations
      .find(`Skip=0&Limit=1000000&IsDestination=true&IsActive=true`)
      .then((response) => {
        this.view.destinations = this.mapDestinations(response.data.data);
        setTimeout(() => {
          this.view.config.isLoading = false;
        }, 400);
      })
      .catch(this.view.onError);
  }

  bindList() {
    this.api.places
      .find(`Skip=0&Limit=1000000&IsDestination=true&IsActive=true`)
      .then((response) => {
        this.view.origins = this.mapPlaces(response.data.data);
        this.view.destinations = this.mapPlaces(response.data.data);
        return this.api.paymentTypes.find(`skip=0&limit=50000&IsActive=true`);
      })
      .then((response) => {
        this.view.paymentTypes = response.data.data;
        setTimeout(() => {
          this.view.isListLoading = false;
          this.view.onSearch();
        }, 400);
      })
      .catch(this.view.onError);
  }

  mapCollection(collection) {
    return collection.map((item) => new Reception(item));
  }

  exportPdf(id) {
    this.api.receptions
      .exportToPdf(id)
      .then((response) => this.view.onExportPdfReponse(response.data))
      .catch(this.view.onError);
  }

  allClearFilters() {
    this.view.filtersSelected = {
      receptionNumber: "",
      originName: "",
      destinyName: "",
      beginingDate: "",
      endingDate: "",
      receptionType: "",
      paymentType: "",
      statusTracking: "",
      status: "",
    };

    this.view.removeFilter("ReceptionNumber");
    this.view.removeFilter("OriginPlaceId");
    this.view.removeFilter("DestinationPlaceId");
    this.view.removeFilter("ReceptionType");
    this.view.removeFilter("PaymentType");
    this.view.removeFilter("StatusTracking");
    this.view.removeFilter("BeginingDate");
    this.view.removeFilter("EndingDate");
    this.view.removeFilter("IsActive");
    this.view.onSearch();
  }

  //#endregion

  //#region QUERIES

  findOrigins(criteria) {
    this.api.places
      .find(criteria)
      .then((response) => this.view.onFindOriginsResponse(response.data))
      .catch(this.view.onError);
  }

  findDestinations(criteria) {
    this.api.places
      .find(criteria)
      .then((response) => this.view.onFindDestinationsResponse(response.data))
      .catch(this.view.onError);
  }

  findFinancialHistory(criteria) {
    this.api.receptions
      .findFinancialHistory(criteria)
      .then((response) =>
        this.view.onFindFinancialHistoryResponse(response.data)
      )
      .catch(this.view.onFindFinancialHistoryError);
  }

  printFinancialHistory(criteria) {
    this.api.receptions
      .printFinancialHistory(criteria)
      .then((response) =>
        this.view.onPrintFinancialHistoryResponse(response.data)
      )
      .catch(this.view.onError);
  }

  /* findCustomers(criteria) {
        this.api.customers.find(criteria)
            .then((response) => this.view.onFindCustomerResponse(response.data))
            .catch(this.view.onError)
    } */

  findProductypesFilterTree() {
    this.api.productypes
      .findFilterTree()
      .then((response) => this.view.onFindItemFilterTreeResponse(response.data))
      .catch(this.view.onError);
  }
  //#endregion QUERIES

  //#region Neighborhoods

  findNeighborhoods(query) {
    this.api.neighborhoods
      .find(query)
      .then((response) => this.view.onFindNeighborhoodsResponse(response.data))
      .catch(this.view.onError);
  }

  addNeighborhood(data) {
    return new Neighborhood(data);
  }

  createNeighborhood(data) {
    this.api.neighborhoods
      .create(data.toSend())
      .then((response) => {
        return this.api.neighborhoods.findOne(response.data.id);
      })
      .then((response) => this.view.onCreateNeighborhoodResponse(response.data))
      .catch(this.view.onError);
  }

  //#endregion Neighborhoods

  //#region RECEPTION CUSTOMER

  bindCustomerFormView(formData) {
    if (formData.customer) {
      this.view.customers = [formData.customer];
      formData.customerLocations = this.mapCustomerLocations(
        formData.customerLocations
      );
    }
  }

  async bindStepTwoFormView() {
    try {
      const specialPrices = await this.findSpecialPrices()
      const serviceCostResponse = await this.api.serviceCosts.find(`Skip=0&Limit=10000&IsActive=true`)
      const serviceCosts = this.mapSpecialPricesWithServiceCosts(specialPrices, serviceCostResponse.data.data)
      this.view.serviceCosts = this.mapServiceCosts(serviceCosts);
      const weightCostsResponse = await this.api.weightCosts.find();
      
      if (weightCostsResponse.data.data.length === 0) {
        throw new Error("No hay parametros de peso configurados");
      } else {
        this.view.weightCostConfig = weightCostsResponse.data.data[0];
      }
      
      setTimeout(() => {
        this.view.$attrs.config.isLoading = false;
      }, 400);

    } catch (error) {
      this.view.onError(error)
    }
  }

  async findSpecialPrices() {
    try {
      const reception = this.view.formData
      const customerId = (reception.payerResponsible === 'sender') ? reception.sender.customer.id : reception.receiver.customer.id
      const specialPricesResponse = await this.api.customerSpecialPrices.find(`CustomerId=${customerId}&Skip=0&Limit=10000&IsActive=true`)
      return specialPricesResponse.data.data
    } catch (error) {
      this.view.onError(error)
    }
  }

  mapSpecialPricesWithServiceCosts(specialPrices, serviceCosts) {
    console.log(specialPrices)
    return serviceCosts.map((serviceCost) => {
      console.log(serviceCost)
      const specialPrice = _.find(specialPrices, ((item) => item.serviceCost?.id === serviceCost.id))
      if(specialPrice) {
        serviceCost.amount = specialPrice.amount
      }
      return serviceCost
    })
  }

  async bindStepThreeView(formData) {
    try {
      setTimeout(() => {
        this.view.$set(
          formData.consignmentNote,
          "version",
          this.view.consignmentNotesVersions[0]
        );
        this.view.$set(
          formData.consignmentNote,
          "internationalTransport",
          this.view.internationalTransports[1]
        );
        this.view.$set(
          formData.consignmentNote,
          "traveledDistance",
          formData.serviceCost.distance
        );
        this.view.$attrs.config.isLoading = false;
      }, 400);
    } catch (error) {
      this.view.onError(error);
    }
  }

  bindLocationBoxFormView() {
    this.api.countries
      .find(`Skip=0&Limit=10000&IsActive=true`)
      .then((response) => {
        this.view.countries = response.data.data;
        return this.api.consignmentLocationTypes.find(
          `Skip=0&Limit=10000&IsActive=true`
        );
      })
      .then((response) => {
        this.view.consignmentLocationTypes = response.data.data;
        this.view.onBindLocationBoxFormViewResponse();
      })
      .catch(this.view.onError);
  }

  async bindBillingInformationFormView(formData) {
    try {
      const requests = [
        this.api.paymentMethods.find(`Skip=0&Limit=10000&IsActive=true`),
        this.api.paymentTypes.find(`Skip=0&Limit=10000&IsActive=true`),
        this.api.currencies.find(`Skip=0&Limit=10000&IsActive=true`),
        this.api.billUsingTypes.find(`Skip=0&Limit=10000&IsActive=true`),
        this.api.billTypes.find(`Skip=0&Limit=10000&IsActive=true`),
        this.api.measureUnits.find(`Skip=0&Limit=10000&IsActive=true`),
        this.api.taxRegimes.find(`Skip=0&Limit=10000&IsActive=true`)
      ];

      if (formData.customerBill) {
        this.view.customers = [formData.customerBill];
        requests.push(this.api.customers.findOne(formData.customerBill.id));
      }

      const [
        paymentMethods,
        paymentTypes,
        currencies,
        billUsingTypes,
        billTypes,
        measureUnits,
        taxRegimes,
        customerResponse
      ] = await Promise.all(requests);

      this.view.paymentMethods = paymentMethods.data.data;
      this.view.paymentTypes = paymentTypes.data.data;
      this.view.currencies = currencies.data.data;
      this.view.billUsingTypes = billUsingTypes.data.data;
      this.view.billTypes = billTypes.data.data;
      this.view.weightUnits = measureUnits.data.data;
      this.view.taxRegimes = this.mapTaxRegimes(taxRegimes.data.data);

      if (customerResponse?.data?.data) {
        this.view.onFindCustomerLocationsResponse(customerResponse.data);
      }

      setTimeout(() => {
        this.bindBillInfoField(
          formData,
          "billType",
          billTypes.data.data.find((x) => x.isDefault)
        );
        this.bindBillInfoField(
          formData,
          "paymentType",
          paymentTypes.data.data.find((x) => x.isDefault)
        );
        this.bindBillInfoField(
          formData,
          "currency",
          currencies.data.data.find((x) => x.isDefault)
        );
      }, 400);
    } catch (error) {
      this.view.onError(error);
    }
  }

  bindBillInfoField(formData, fieldName, defaultValue) {
    this.view.$set(formData, fieldName, defaultValue);
  }

  findDistricts(query) {
    this.api.districts
      .find(query)
      .then((response) => this.view.onFindDistrictsResponse(response.data))
      .catch(this.view.onError);
  }

  findMunicipalities(query) {
    this.api.municipalities
      .find(query)
      .then((response) => this.view.onFindMunicipalitiesResponse(response.data))
      .catch(this.view.onError);
  }

  findCities(query) {
    this.api.places
      .find(query)
      .then((response) => this.view.onFindCitiesResponse(response.data))
      .catch(this.view.onError);
  }

  //#endregion CUSTOMER

  //#region RECEPTION CANCELLATION

  //#endregion

  //#region RECEPTION PACKAGE

  createReceptionPackage(data) {
    return new ReceptionPackage(data, "create");
  }

  editReceptionPackage(data) {
    return new ReceptionPackage(data, "update");
  }

  deleteReceptionPackage(collection, index) {
    collection.splice(index, 1);
  }

  upsertReceptionPackage(collection, data, selectedIndex) {
    if (data.state === "update") {
      this.view.$set(collection, selectedIndex, data);
    } else {
      collection.push(data);
    }
    selectedIndex = null;
  }

  calculateTotalOverWeight(receptionPackages) {
    return _.sumBy(receptionPackages, "extraWeight");
  }

  calculateTotalPrice(collection) {
    return _.sum(collection, (item) => {
      return this.formatPrecision(item.subtotal, 2);
    });
  }

  getTotalPackages(collection) {
    return _.sumBy(collection, "quantity");
  }

  getBillConceptSubtotal(collection, deliveryPriceCost) {
    let totalPackages = _.sumBy(collection, "quantity");
    return this.formatPrecision(totalPackages * deliveryPriceCost, 2);
  }

  getBillConceptUnitPrice(collection, deliveryPriceCost) {
    let totalPackages = _.sumBy(collection, "quantity");
    return this.formatPrecision(deliveryPriceCost / totalPackages, 2);
  }

  getSubtotal(collection) {
    return _.sumBy(collection, (saleConcept) => {
      if (!saleConcept.billConcept) return 0;
      return this.formatPrecision(saleConcept.total, 2);
    });
  }

  getIva(subtotal, weightCostConfig) {
    return this.formatPrecision(
      subtotal * (weightCostConfig.extraWeightTax / 100),
      2
    );
  }

  //#endregion

  findCustomers(criteria) {
    this.api.customers
      .find(criteria)
      .then((response) => this.view.onFindCustomersResponse(response.data))
      .catch(this.view.onError);
  }

  findCustomer(id) {
    this.api.customers
      .findOne(id)
      .then((response) => this.view.onFindCustomerResponse(response.data))
      .catch(this.view.onError);
  }

  findCustomerLocations(id) {
    this.api.customers
      .findOne(id)
      .then((response) =>
        this.view.onFindCustomerLocationsResponse(response.data)
      )
      .catch(this.view.onError);
  }

  updateCustomerLocation(customerLocation) {
    let data = {
      description: customerLocation.description,
      mainstreet: customerLocation.mainStreet,
      secondaryStreet: customerLocation.secondaryStreet,
      extNumber: customerLocation.extNumber,
      intNumber: customerLocation.intNumber,
      locationId: customerLocation.id,
      postalCodeId: customerLocation.postalCode.id,
      neighborhoodId: customerLocation.neighborhood.id,
    };

    this.api.customerLocation.update(customerLocation.customer.id, customerLocation.id, data)
      .then((response) => this.view.onUpdateCustomerResponse(response.data))
      .catch(this.view.onError);
  }

  findWeightCosts() {
    this.api.weightCosts
      .find()
      .then((response) => this.view.onFindWeightCostsResponse(response.data))
      .catch(this.view.onError);
  }

  findAvailableCustomerSolvency(customerId, solvencyType) {
    this.api.solvencies
      .findAvailableSolvencyByCustomer(customerId, solvencyType)
      .then((response) =>
        this.view.onFindByAilableCustomerSolvencyResponse(response.data)
      )
      .catch(this.view.onError);
  }

  findProductTypes(criteria) {
    this.api.productypes
      .find(criteria)
      .then((response) => this.view.onFindProductTypesResponse(response.data))
      .catch(this.view.onError);
  }

  findProductType(id) {
    this.api.productypes
      .findOne(id)
      .then((response) => this.view.onFindProductTypeResponse(response.data))
      .catch(this.view.onError);
  }

  findVehicle(id) {
    this.api.vehicles
      .findOne(id)
      .then((response) => this.view.onFindVehiclesResponse(response.data))
      .catch(this.view.onError);
  }

  findOperator(id) {
    this.api.employees
      .findOne(id)
      .then((response) => this.view.onFindOperatorResponse(response.data))
      .catch(this.view.onError);
  }

  //#region MAPPING

  mapCustomers(collection) {
    return collection.map((item) => new Customer(item));
  }

  mapCustomerLocations(collection) {
    return collection.map((item) => new CustomerLocation(item));
  }

  mapServiceCosts(collection) {
    return collection.map((item) => new ServiceCost(item));
  }

  mapVehicles(collection) {
    return collection.map((item) => new Vehicle(item));
  }

  mapDestinations(collection) {
    return collection.map((item) => new Destination(item));
  }

  mapReceptionData(data) {
    let item = new Reception(data, "update");
    item.senderLocation = new CustomerLocation(item.senderLocation, "update");
    item.receiverLocation = new CustomerLocation(
      item.receiverLocation,
      "update"
    );
    return item;
  }

  mapFilterCustomers(collection) {
    return collection.join(",");
  }

  mapFilterSenderCustomers(collection) {
    return collection.join(",");
  }

  mapPlaces(collection) {
    return collection.map((item) => new Destination(item));
  }

  mapUsers(collection) {
    return collection.map((item) => new User(item));
  }

  mapTaxRegimes(collection) {
    return collection.map((item) => new TaxRegime(item));
  }

  //#endregion

  formatDateRangeDate(dateRange) {
    return {
      startDate: moment(dateRange[0]).format("YYYY-MM-DD"),
      endDate: moment(dateRange[1]).format("YYYY-MM-DD"),
    };
  }

  bindCreateForm() {
    this.api.serviceCosts
      .find(`skip=0&limit=1000000&IsActive=true`)
      .then((response) => {
        this.view.serviceCosts = this.mapServiceCosts(response.data.data);
        return this.api.paymentMethods.find(`skip=0&limit=50000&IsActive=true`);
      })
      .then((response) => {
        this.view.paymentMethods = response.data.data;
        return this.api.paymentTypes.find(`skip=0&limit=50000&IsActive=true`);
      })
      .then((response) => {
        this.view.paymentTypes = response.data.data;
        return this.api.currencies.find(`skip=0&limit=50000&IsActive=true`);
      })
      .then((response) => {
        this.view.currencies = response.data.data;
        return this.api.billUsingTypes.find(`skip=0&limit=50000&IsActive=true`);
      })
      .then((response) => {
        this.view.billUsingTypes = response.data.data;
        return this.api.billTypes.find(`skip=0&limit=50000&IsActive=true`);
      })
      .then((response) => {
        this.view.billTypes = response.data.data;
        return this.api.vehicles.find(`skip=0&limit=50000&IsActive=true`);
      })
      .then((response) => {
        this.view.vehicles = response.data.data;
        return this.api.employees.find(
          `skip=0&limit=50000&IsActive=true&isDriver=true`
        );
      })
      .then((response) => {
        this.view.operators = this.mapUsers(response.data.data);
        return this.api.transportationTypes.find(
          `skip=0&limit=50000&IsActive=true`
        );
      })
      .then((response) => {
        this.view.transportationTypes = response.data.data;
        return this.api.sctPermissionTypes.find(
          `skip=0&limit=50000&IsActive=true`
        );
      })
      .then((response) => {
        this.view.sctPermissionTypes = response.data.data;
        return this.api.weightCosts.find();
      })
      .then((response) => {
        setTimeout(() => {
          this.view.config.isLoading = false;
          if (!_.isEmpty(response.data.data)) {
            this.view.weightCostConfig = response.data.data[0];
          } else {
            throw new Error("No hay parametros de peso configurados");
          }
        }, 400);
      })
      .catch(this.view.onError);
  }

  //#region VERIFICATIONS && VALIDATIONS

  isStepValid(formData, stepActive) {
    var isValid = false;
    switch (stepActive) {
      case 0:
        if (!this.isReceptionServiceTypeSelected(formData))
          throw new Error("Tipo de Servicio requerido");
        if (!this.isSenderAdded(formData))
          throw new Error("Datos de Remitente requerido");
        if (!this.isReceiverAdded(formData))
          throw new Error("Datos de Destinatario requerido");
        isValid = true;
        break;
      case 1:
        if (!this.isReceptionTypeSelected(formData))
          throw new Error("Tipo de Recepción requerido");
        if (!this.isSolvencyPrepaid(formData)) {
          if (!this.isServiceCostSelected(formData))
            throw new Error("Flete requerido");
        } else {
          this.view.$refs.formDataStep1.validateField("originPlace");
          this.view.$refs.formDataStep1.validateField("destinationPlace");
          if (!this.isOriginAndDestinationPlaceSelected(formData))
            throw new Error("Lugar de Origen y/o Destino requerido");
        }
        if (!this.hasPackages(formData))
          throw new Error("Al menos 1 paquete requerido.");
        isValid = true;
        break;
      case 2:
        if (!this.isPaymentTypeSelected(formData))
          throw new Error("Forma de Pago requerido");
        if (!this.isReceptionStatusSelected(formData))
          throw new Error("Estado de Recepción requerido");
        isValid = true;
        break;
      default:
        break;
    }
    return isValid;
  }

  isReceptionTypeSelected(formData) {
    return !_.isNil(formData.receptionType);
  }

  isServiceCostSelected(formData) {
    return !_.isNil(formData.serviceCost) && !_.isEmpty(formData.serviceCost);
  }

  isSolvencyPrepaid(formData) {
    return (
      formData.solvencyType &&
      formData.solvencyType === ReceptionsViewModel.SolvencyType.Prepaid
    );
  }

  isOriginAndDestinationPlaceSelected(formData) {
    return formData.originPlace && formData.destinationPlace;
  }

  hasPackages(formData) {
    return (
      !_.isNil(formData.receptionPackages) &&
      !_.isEmpty(formData.receptionPackages)
    );
  }

  isReceptionServiceTypeSelected(formData) {
    return !_.isNil(formData.receptionServiceType);
  }

  isSenderAdded(formData) {
    return (
      !_.isNil(formData.senderName) &&
      !_.isEmpty(formData.senderName) &&
      !_.isNil(formData.senderPhone) &&
      !_.isEmpty(formData.senderPhone) &&
      !_.isNil(formData.senderObservation) &&
      !_.isEmpty(formData.senderObservation)
    );
  }

  isReceiverAdded(formData) {
    return (
      !_.isNil(formData.receiverName) &&
      !_.isEmpty(formData.receiverName) &&
      !_.isNil(formData.receiverPhone) &&
      !_.isEmpty(formData.receiverPhone) &&
      !_.isNil(formData.receiverObservation) &&
      !_.isEmpty(formData.receiverObservation)
    );
  }

  isPaymentTypeSelected(formData) {
    return (
      !_.isNil(formData.paymentType) &&
      (_.isNumber(formData.paymentType) || !_.isEmpty(formData.paymentType))
    );
  }

  isReceptionStatusSelected(formData) {
    return (
      !_.isNil(formData.receptionStatus) &&
      (_.isNumber(formData.receptionStatus) ||
        !_.isEmpty(formData.receptionStatus))
    );
  }

  //#endregion

  printTrackingDocument(receptionId) {
    this.api.receptions
      .printTrackingDocument(receptionId)
      .then((response) =>
        this.view.onPrintTrackingDocumentResponse(response.data)
      )
      .catch(this.view.onError);
  }

  printTrackingLabels(receptionId) {
    this.api.receptions
      .printTrackingLabels(receptionId)
      .then((response) =>
        this.view.onPrintTrackingLabelsResponse(response.data)
      )
      .catch(this.view.onError);
  }

  calculatePackages(receptionPackages, weightCostConfig) {
    return receptionPackages.map((receptionPackage) => {
      receptionPackage.volumetricWeight = this.formatPrecision(
        ReceptionHelper.calculateVolumetricWeight(
          receptionPackage.quantity,
          receptionPackage.length,
          receptionPackage.height,
          receptionPackage.width
        )
      );
      receptionPackage.finalWeight = this.formatPrecision(
        ReceptionHelper.getFinalWeight(
          receptionPackage.weight,
          receptionPackage.volumetricWeight
        )
      );

      receptionPackage.extraWeight = this.formatPrecision(
        ReceptionHelper.getExtraWeight(
          receptionPackage.finalWeight,
          weightCostConfig.weightBase,
          receptionPackage.quantity
        )
      );
      return receptionPackage;
    });
  }

  calculateSummary(param) {
    const { columns, data } = param;
    const sums = [];
    columns.forEach((column, index) => {
      if (index === 0) {
        sums[index] = "Total";
        return;
      }
      const values = data.map((item) => Number(item[column.property]));
      if (!values.every((value) => isNaN(value))) {
        if (column.label === "Peso") {
          let totalWeight = values.reduce((prev, curr) => {
            const value = Number(curr);
            if (!isNaN(value)) {
              return prev + curr;
            } else {
              return prev;
            }
          }, 0);
          sums[index] = this.formatPrecision(totalWeight);
        } else if (column.label === "Sobre Peso") {
          let totalOverweight = values.reduce((prev, curr) => {
            const value = Number(curr);
            if (!isNaN(value)) {
              return prev + curr;
            } else {
              return prev;
            }
          }, 0);
          sums[index] = this.formatPrecision(totalOverweight);
        } else {
          sums[index] = values.reduce((prev, curr) => {
            const value = Number(curr);
            if (!isNaN(value)) {
              return prev + curr;
            } else {
              return prev;
            }
          }, 0);
        }
      } else {
        sums[index] = "";
      }
    });

    return sums;
  }

  getNameReceptionType(type) {
    return ReceptionHelper.getNameReceptionType(type);
  }

  getNamePaymentType(type) {
    return ReceptionHelper.getNamePaymentType(type);
  }

  getNameClientType(type) {
    return ReceptionHelper.getNameClientType(type);
  }

  getIconTrackingStatus(status) {
    return ReceptionHelper.getIconTrackingStatus(status);
  }

  getStyleTrackingStatus(status) {
    return ReceptionHelper.getStyleTrackingStatus(status);
  }

  getNameTrackingStatus(status) {
    return ReceptionHelper.getNameTrackingStatus(status);
  }

  isPrepaid(receptionServiceType) {
    return (
      receptionServiceType &&
      receptionServiceType === ReceptionHelper.CONSTANT_SOLVENCY_TYPES.PREPAID
    );
  }

  verifyRFC(value) {
    return RegexHelper.verifyRFC(value);
  }

  formatServiceCost(serviceCost, receptionServiceType, serviceDeliveryPrice) {
    let resultAmount = this.isPrepaid(receptionServiceType)
      ? serviceDeliveryPrice
      : serviceCost.amount;
    return serviceCost.formatFullDescription(resultAmount);
  }

  getServiceDeliveryPrice(formData, serviceCostAmount) {
    return this.isPrepaid(formData.receptionServiceType)
      ? formData.serviceDeliveryPrice
      : serviceCostAmount;
  }

  nextTrackingNumber(serviceCostId, callback) {
    return this.api.receptions
      .nextTrackingNumber(serviceCostId)
      .then((response) => {
        callback(response.data);
      })
      .catch(this.view.onError);
  }

  hasDangerousMaterials(receptionPackages) {
    return receptionPackages.some(
      (receptionPackage) => receptionPackage.isDangerousMaterial
    );
  }

  verifySaleConcepts(collection) {
    return _.every(collection, (item) => item.billConcept != null);
  }

  verifyServicePayed(serviceType) {
    return ReceptionHelper.SolvencyType.Prepaid == serviceType;
  }

  verifyReceptionServiceIsAnContract(serviceType) {
    return _.some(ReceptionHelper.solvencyTypes, { id: serviceType });
  }

  /**
   * Metodo para saber si el servicio al cliente va a ser por credito o prepago
   * @param {*} formData
   * @param {*} customerSolvency
   */
  setCustomerSolvency(formData, customerSolvency) {
    const {
      customerSolvencyId,
      paymentType,
      serviceDeliveryPrice,
      solvencyType,
      message,
    } = customerSolvency || {};

    this.view.$set(formData, "customerSolvencyId", customerSolvencyId || null);
    this.view.$set(formData, "paymentType", paymentType || null);
    this.view.$set(
      formData,
      "serviceDeliveryPrice",
      serviceDeliveryPrice || null
    );
    this.view.$set(formData, "solvencyType", solvencyType || null);
    this.view.$set(formData, "solvencyMessage", message || null);
    this.view.$set(formData, "freightPayable", this.isPayedDefault(formData));
  }

  /**
   * Metodo para saber si el servicio ya esta pagado
   * @param {*} formData
   * @returns
   */
  isPayedDefault(formData) {
    return (
      formData.receptionServiceType.id ==
      ReceptionsViewModel.SolvencyType.CONSTANT_SOLVENCY_TYPES.CASH ||
      formData.solvencyType === ReceptionsViewModel.SolvencyType.Prepaid
    );
  }

  resetCustomer(formData, formStep, properties) {
    this.resetCustomerData(formData);
    this.resetFormValidation(formStep, properties);
  }

  /**
   * Metodo para limpiar los valores de los campos de clientes (Emisores/Receptores)
   * @param {*} formData
   */
  resetCustomerData(formData) {
    this.view.$set(formData, "customer", null);
    this.view.$set(formData, "customerLocation", null);
    this.view.$set(formData, "customerLocations", []);
    this.view.$set(formData, "name", null);
    this.view.$set(formData, "phone", null);
    this.view.$set(formData, "email", null);
    this.view.$set(formData, "secondaryStreet", null);
    this.view.$set(formData, "intNumber", null);
    this.view.$set(formData, "extNumber", null);
    this.view.$set(formData, "country", null);
    this.view.$set(formData, "district", null);
    this.view.$set(formData, "municipality", null);
    this.view.$set(formData, "city", null);
    this.view.$set(formData, "neighborhood", null);
    this.view.$set(formData, "postalCode", null);
  }

  /**
   * Metodo para eliminar los mensajes de las validaciones de los clientes (Emisores/Receptores)
   * @param {*} formStep
   * @param {*} properties
   */
  resetFormValidation(formStep, properties) {
    setTimeout(() => {
      properties.forEach((property) => {
        formStep.clearValidate(property);
      });
    }, 50);
  }
}
