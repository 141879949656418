import BaseForm from "../infraestructure/BaseForm";
import ReceptionsViewModel from "../../core/features/receptions/ReceptionsViewModel";

export default {
  extends: BaseForm,
  data() {
    return {
      receptionData: null,
      viewModel: null,
      isLoading: false,
      weightCostConfig: null,
      rulesStep0: {
        payerResponsible: [
          {
            required: true,
            message: "Responsable de pago requerido.",
            trigger: "blur",
          },
        ],
        receptionServiceType: [
          {
            validator: this.onReceptionServiceTypeValidation,
            trigger: "blur, change",
          },
        ],
        sender: {
          name: [
            {
              required: true,
              message: "Nombre requerido.",
              trigger: "blur",
            },
          ],
          phone: [
            {
              required: true,
              message: "Telefono requerido.",
              trigger: "blur",
            },
          ],
          email: [
            {
              validator: this.onSearchEmail,
              trigger: "blur",
            },
          ],
          observation: [
            {
              required: true,
              message: "Referencia/Observación requerido.",
              trigger: "blur",
            },
          ],
          customer: [
            {
              validator: this.onSenderCustomerValidation,
              trigger: "blur",
            },
          ],
          customerLocation: [
            {
              validator: this.onSenderLocationValidation,
              trigger: "blur, change",
            },
          ],
          mainStreet: [
            {
              required: true,
              message: "Calle principal requerido.",
              trigger: "blur",
            },
          ],
          postalCode: [
            {
              required: true,
              message: "Código postal requerido.",
              trigger: "blur, change",
            },
          ],
          country: [
            {
              required: true,
              message: "País requerido.",
              trigger: "blur, change",
            },
          ],
          district: [
            {
              required: true,
              message: "Estado requerido.",
              trigger: "blur, change",
            },
          ],
          municipality: [
            {
              required: true,
              message: "Municipio requerido.",
              trigger: "blur, change",
            },
          ],
          city: [
            {
              required: true,
              message: "Ciudad requerido.",
              trigger: "blur, change",
            },
          ],
          neighborhood: [
            {
              required: true,
              message: "Colonia requerido.",
              trigger: "blur, change",
            },
          ],
        },
        receiver: {
          name: [
            {
              required: true,
              message: "Nombre requerido.",
              trigger: "blur",
            },
          ],
          phone: [
            {
              required: true,
              message: "Telefono requerido.",
              trigger: "blur",
            },
          ],
          email: [
            {
              validator: this.onSearchEmail,
              trigger: "blur",
            },
          ],
          observation: [
            {
              required: true,
              message: "Referencia/Observación requerido.",
              trigger: "blur",
            },
          ],
          customer: [
            {
              validator: this.onReceiverCustomerValidation,
              trigger: "blur",
            },
          ],
          customerLocation: [
            {
              validator: this.onReceiverLocationValidation,
              trigger: "blur, change",
            },
          ],
          mainStreet: [
            {
              required: true,
              message: "Calle principal requerido.",
              trigger: "blur",
            },
          ],
          postalCode: [
            {
              required: true,
              message: "Código postal requerido.",
              trigger: "blur, change",
            },
          ],
          country: [
            {
              required: true,
              message: "País requerido.",
              trigger: "blur, change",
            },
          ],
          district: [
            {
              required: true,
              message: "Estado requerido.",
              trigger: "blur, change",
            },
          ],
          municipality: [
            {
              required: true,
              message: "Municipio requerido.",
              trigger: "blur, change",
            },
          ],
          city: [
            {
              required: true,
              message: "Ciudad requerido.",
              trigger: "blur, change",
            },
          ],
          neighborhood: [
            {
              required: true,
              message: "Colonia requerido.",
              trigger: "blur, change",
            },
          ],
        },
      },
      rulesStep1: {
        receptionType: [
          {
            validator: this.onReceptionTypeValidation,
            trigger: "blur, change",
          },
        ],
        serviceCost: [
          {
            validator: this.onServiceCostValidation,
            trigger: "blur, change",
          },
        ],
        receptionPackages: [
          {
            validator: this.onReceptionPackagesValidation,
            trigger: "blur, change",
          },
        ],
      },
      rulesStep2: {
        sale: {
          saleBill: {
            fiscalName: [
              {
                required: true,
                message: "Razón social requerido.",
                trigger: "blur",
              },
            ],
            fiscalCode: [
              {
                required: true,
                message: "R.F.C. requerido.",
                trigger: "blur",
              },
            ],
            fiscalEmail: [
              {
                validator: this.onSearchEmail,
                trigger: "blur",
              },
            ],
            billUsingType: [
              {
                required: true,
                message: "Uso de cfdi requerido.",
                trigger: "blur, change",
              },
            ],
            paymentType: [
              {
                required: true,
                message: "Forma de pago requerido.",
                trigger: "blur, change",
              },
            ],
            paymentMethod: [
              {
                required: true,
                message: "Metodo de pago requerido.",
                trigger: "blur, change",
              },
            ],
            billType: [
              {
                required: true,
                message: "Tipo de factura requerido.",
                trigger: "blur, change",
              },
            ],
            currency: [
              {
                required: true,
                message: "Moneda requerido.",
                trigger: "blur, change",
              },
            ],
            customerBill: [
              {
                required: true,
                message: "Cliente requerido.",
                trigger: "blur, change",
              },
            ],
            customerBillLocation: [
              {
                required: true,
                message: "Ubicación requerido.",
                trigger: "blur, change",
              },
            ],
          },
          saleConcepts: [
            {
              validator: this.onConceptsValidation,
              trigger: "blur, change",
            },
          ],
        },
        consignmentNote: {
          version: [
            {
              required: true,
              message: "Carta porte versión requerido.",
              trigger: "blur, change",
            },
          ],
          internationalTransport: [
            {
              required: true,
              message: "Transporte internacional requerido.",
              trigger: "blur, change",
            },
          ],
          traveledDistance: [
            {
              required: true,
              message: "Distancia recorrida requerido.",
              trigger: "blur, change",
            },
          ],
          weightUnit: [
            {
              required: true,
              message: "Unidad de peso requerido.",
              trigger: "blur, change",
            },
          ],
          origin: {
            name: [
              {
                required: true,
                message: "Nombre remitente requerido.",
                trigger: "blur, change",
              },
            ],
            federalTaxPayerRegistration: [
              {
                required: true,
                message: "RFC remitente requerido.",
                trigger: "blur, change",
              },
            ],
            exchangeDate: [
              {
                required: true,
                message: "Fecha y hora de salida.",
                trigger: "blur, change",
              },
            ],
          },
          destination: {
            name: [
              {
                required: true,
                message: "Nombre destinatario requerido.",
                trigger: "blur, change",
              },
            ],
            federalTaxPayerRegistration: [
              {
                required: true,
                message: "RFC remitente requerido.",
                trigger: "blur, change",
              },
            ],
            exchangeDate: [
              {
                required: true,
                message: "Fecha y hora de salida.",
                trigger: "blur, change",
              },
            ],
          },
          sctPermissionType: [
            {
              required: true,
              message: "Tipo de Permiso SCT requerido.",
              trigger: "blur, change",
            },
          ],
          sctPermissionNumber: [
            {
              required: true,
              message: "Numero de Permiso SCT requerido.",
              trigger: "blur, change",
            },
          ],
          autoTransportations: [
            {
              validator: this.onAutoTransportationsValidation,
              trigger: "blur, change",
            },
          ],
          transportationType: [
            {
              required: true,
              message: "Clave de transporte requerido.",
              trigger: "blur, change",
            },
          ],
          transportationFigures: [
            {
              validator: this.onTransportationFiguresValidation,
              trigger: "blur, change",
            },
          ],
        },
        documentReference: [
          {
            required: true,
            message: "Referencia guía fisica requerido.",
            trigger: "blur",
          }
        ]
      },
      itemReceptionsCustomer: "",
      stepActive: 0,
      receptionPackageSelectedIndex: null,
      receptionTrackingStatus: ReceptionsViewModel.receptionTrackingStatus,
      currentReceptionId: null,
    };
  },
  props: {
    formData: {
      type: Object,
      required: true,
    },
    config: {
      type: Object,
      required: true,
    },
  },
  created() {
    this.viewModel = new ReceptionsViewModel(this);
    this.config.isLoading = true;
    this.onActive();
  },
  methods: {
    onActive() {
      this.viewModel.bindCreateForm();
    },
    isFirstStep() {
      return this.stepActive === 0;
    },
    onPrevStep() {
      this.stepActive -= 1;
      if (this.stepActive == 1) {
        this.$set(this.formData.sale, "saleConcepts", []);
      }
    },
    onNextStep() {
      this.config.isLoading = true;
      this.$forceUpdate();
      if (this.stepActive < 3) {
        this.isStepValid(this.stepActive, (isValid) => {
          if (isValid) {
            if (this.stepActive == 2) {
              this.viewModel.save(this.formData);
            } else {
              this.stepActive += 1;
            }
          } else {
            this.config.isLoading = false;
          }
        });
      }
    },
    isStepValid(stepActive, callback) {
      this.$forceUpdate();
      this.$refs[`formDataStep${stepActive}`].clearValidate();
      this.$refs[`formDataStep${stepActive}`].validate((isValid, errors) => {
        if (!isValid) {
          this.onShowWarningList(errors);
        }
        callback(isValid);
      });
    },
    onSaveResponse(response) {
      this.viewModel.findItem(response.receptionId);
    },
    onSaveErrorResponse(error) {
      this.config.isLoading = false;
      this.stepActive = 2;
      this.onError(error);
    },
    onFindItemResponse(response) {
      setTimeout(() => {
        this.stepActive = 3;
        this.receptionData = this.viewModel.mapReceptionData(response.data);
        this.config.isLoading = false;
        this.onDoAfterLocalSaveNoHideResponse(
          this.receptionData,
          "on-submited-reception"
        );
      }, 2000);
    },
    onGetNameClientType(type) {
      return this.viewModel.getNameClientType(type);
    },
    onGetIconTrackingStatus(status) {
      return this.viewModel.getIconTrackingStatus(status);
    },
    onPrint() {
      this.config.isLoading = true;
      this.viewModel.printTrackingDocument(this.receptionData.id);
    },
    onPrintTrackingDocumentResponse(response) {
      setTimeout(() => {
        this.viewModel.addDownloadEvent(
          response.data,
          response.fileName,
          "application/pdf",
          () => {
            this.config.isLoading = false;
            this.onDoAfterSaveResponse(
              "Guía Generada.",
              "on-submited-receptions"
            );
          }
        );
      }, 400);
    },
    onReceptionServiceTypeValidation(rule, value, callback) {
      if (!value) {
        callback("Tipo de Servicio requerido");
      }
      callback();
    },
    onReceptionTypeValidation(rule, value, callback) {
      if (!value) {
        callback("Tipo de recepción requerido");
      }
      callback();
    },
    onServiceCostValidation(rule, value, callback) {
      if (!this.formData.solvencyType || this.formData.solvencyType !== 5) {
        if (!value) {
          callback("Flete requerido");
        }
      }
      callback();
    },
    onReceptionPackagesValidation(rule, value, callback) {
      if (value && value.length > 0) {
        callback();
      } else {
        callback(new Error("Al menos un paquete requerido"));
      }
    },
    onSenderCustomerValidation(rule, value, callback) {
      if (!value) {
        callback("Cliente requerido");
      } else if (
        this.formData.payerResponsible === "sender" &&
        this.formData.receptionServiceType !== 3 &&
        !this.formData.customerSolvencyId
      ) {
        callback(this.formData.solvencyMessage);
      } else {
        callback();
      }
    },
    onReceiverCustomerValidation(rule, value, callback) {
      if (!value) {
        callback("Cliente requerido");
      } else if (
        this.formData.payerResponsible === "receiver" &&
        this.formData.receptionServiceType !== 3 &&
        !this.formData.customerSolvencyId
      ) {
        callback(this.formData.solvencyMessage);
      } else {
        callback();
      }
    },
    onSenderLocationValidation(rule, value, callback) {
      if (this.formData.sender.customer) {
        if (!value) {
          callback("Dirección requerido");
        } else {
          callback();
        }
      }
      callback();
    },
    onReceiverLocationValidation(rule, value, callback) {
      if (this.formData.receiver.customer) {
        if (!value) {
          callback("Dirección requerido");
        } else {
          callback();
        }
      }
      callback();
    },
    onTransportationFiguresValidation(rule, value, callback) {
      if (value && value.length > 0) {
        callback();
      } else {
        callback(new Error("Al menos un operador requerido"));
      }
    },
    onConceptsValidation(rule, value, callback) {
      if (value && value.length > 0) {
        if (this.viewModel.verifySaleConcepts(value)) {
          callback();
        } else {
          callback(
            new Error(
              "Verificar que los conceptos de venta no se encuentren vacíos."
            )
          );
        }
      } else {
        callback(new Error("Al menos un concepto de venta requerido"));
      }
    },
    onAutoTransportationsValidation(rule, value, callback) {
      if (value && value.length > 0) {
        callback();
      } else {
        callback(new Error("Al menos un vehículo requerido"));
      }
    },
    onSearchEmail(rule, value, callback) {
      if (value) {
        let testEmails = value.split(";");
        // eslint-disable-next-line
        let regex = new RegExp(
          "^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,}$"
        );
        if (testEmails.length === 0) callback();
        if (testEmails.every((email) => regex.test(email))) callback();
        testEmails.forEach((email) => {
          if (!regex.test(email)) {
            callback(new Error(`El correo ${email} no es valido.`));
          }
        });
      } else {
        callback();
      }
    },
  },
};
