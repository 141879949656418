import EndPoints from './EndPoints';
import ApiWrapper from './ApiWrapper';

// API AUTHORIZATION && AUTHENTICATION
import UsersApi from '../features/users/UsersApi'
import RolesApi from '../features/roles/RolesApi'
import PermissionsApi from '../features/permissions/PermissionsApi'
import AuthorizationApi from '../features/authorization/AuthorizationApi';
import EmployeesApi from '../features/employees/EmployeesApi';


// API CONFIGURATIONS CATALOGS
import MeasureUnitApi from '../features/measureUnits/MeasureUnitApi'
import DestinationApi from '../features/destinations/DestinationApi'
import TraceStatusReasonApi from '../features/traceStatusReason/TraceStatusReasonApi'
import BranchOfficesApi from '../features/branchOffices/BranchOfficeApi'
import BankAccountApi from '../features/bankAccounts/BankAccountApi'
import VehiclesApi from '../features/vehicles/VehiclesApi'
import VehicleTypeApi from '../features/vehicleTypes/VehicleTypeApi'
import ServiceCostApi from '../features/serviceCosts/ServiceCostApi'
import PrepaidPlanApi from '../features/prepaidPlans/PrepaidPlanApi'
import WorkRoutesApi from '../features/workRoutes/WorkRoutesApi'
import CollectionRouteApi from '../features/collectionRoutes/CollectionRouteApi'
import CollectionRouteTraceApi from '../features/collectionRouteTraces/CollectionRouteTraceApi'
import ProductTypesApi from '../features/productTypes/ProductTypesApi'
import NeighborhoodApi from '../features/neighborhoods/NeighborhoodApi'
import SolvencyApi from '../features/solvencies/SolvencyApi'
import CurrencyApi from '../features/currencies/CurrencyApi'
import CountryApi from '../features/countries/CountryApi'
import DistrictApi from '../features/districts/DistrictApi'
import MunicipalityApi from '../features/municipalities/MunicipalityApi'
import PostalCodeApi from '../features/postalCodes/PostalCodeApi'
import PlaceApi from '../features/places/PlaceApi'

// API RECEPTIONS
import ReceptionsApi from '../features/receptions/ReceptionsApi'
import ShipmentApi from '../features/shipments/ShipmentApi'
import TrackingApi from '../features/trackings/TrackingApi'


// API CUSTOMERS
import CustomersApi from '../features/customers/CustomersApi'
import CustomersLocationApi from '../features/customerLocation/CustomersLocationApi'
import CustomersAccountApi from '../features/customerAccount/CustomersAccountApi'
import CustomerReceptionsApi from '../features/customersReceptions/CustomerReceptionsApi'
import CustomerSolvencyApi from '../features/customerSolvencies/CustomerSolvencyApi'
import CustomerDashboardApi from '../features/customerDashboard/CustomerDashboardApi'
import CustomerContactApi from '../features/customerContact/CustomerContactApi'
import CustomerVerificationCodeApi from '../features/customerVerificationCodes/CustomerVerificationCodeApi';
import CustomerSpecialPriceApi from '../features/customerSpecialPrices/CustomerSpecialPricesApi'

// API WEIGHTCOSTS
import WeightCostApi from '../features/weightCosts/WeightCostApi'

//API RESUMEN
import DashboardApi from '../features/dashboard/DashboardApi'

//API TAXES
import TaxTypeApi from '../features/taxTypes/TaxTypeApi'
import TaxFeeApi from '../features/taxFees/TaxFeeApi'
import TaxFactorTypeApi from '../features/taxFactorTypes/TaxFactorTypeApi'
import TaxRegimeApi from '../features/taxRegimes/TaxRegimeApi'

// API PAYMENTS
import PaymentMethodApi from '../features/paymentMethods/PaymentMethodApi'
import PaymentTypeApi from '../features/paymentTypes/PaymentTypeApi'

// API BILLS
import BillTypeApi from '../features/billTypes/BillTypeApi'
import BillConceptApi from '../features/billConcepts/BillConceptApi'
import BillUsingTypeApi from '../features/billUsingTypes/BillUsingTypeApi'
import BillRelationApi from '../features/billRelations/BillRelationApi'
import SaleApi from '../features/sales/SaleApi'
import UnitKeyApi from '../features/unitKeys/UnitKeyApi';
import BillConceptKeyApi from '../features/billConceptKeys/BillConceptKeyApi';
import SaleBillStatusApi from '../features/saleBillStatuses/SaleBillStatusApi';
import BillApi from '../features/bills/BillApi';

//API TRANSPORTATIONS
import TransportationTypeApi from '../features/transportationTypes/TransportationTypeApi'
import DangerousMaterialApi from '../features/dangerousMaterials/DangerousMaterialApi'
import PackagingTypeApi from '../features/packagingTypes/PackagingTypeApi'
import TransportMerchandiseApi from '../features/transportMerchandiseTypes/TransportMerchandiseApi'
import SctPermissionTypeApi from '../features/sctPermissionTypes/SctPermissionTypeApi'
import TransportFigureTypeApi from '../features/transportFigureTypes/TransportFigureTypeApi'
import ConsignmentLocationTypeApi from '../features/consignmentLocationTypes/ConsignmentLocationTypeApi'

// API ORDERS
import OrderApi from '../features/orders/OrderApi'
import OrderStatusApi from '../features/orderStatuses/OrderStatusApi';

// API GPS TRACKING
import GpsTrackingApi from '../features/gpsTracking/GpsTrackingApi'

const apiWrapper = new ApiWrapper();

export default {
    authorization: new AuthorizationApi(apiWrapper, `${EndPoints.API}${EndPoints.SECURITY}`),
    users: new UsersApi(apiWrapper, `${EndPoints.API}${EndPoints.USERS}`),
    roles: new RolesApi(apiWrapper, `${EndPoints.API}${EndPoints.ROLES}`),
    permissions: new PermissionsApi(apiWrapper, `${EndPoints.API}${EndPoints.SECURITY}`),
    measureUnits: new MeasureUnitApi(apiWrapper, `${EndPoints.API}${EndPoints.MEASURE_UNITS}`),
    workroutes: new WorkRoutesApi(apiWrapper, `${EndPoints.API}${EndPoints.WORK_ROUTES}`),
    productTypes: new ProductTypesApi(apiWrapper, `${EndPoints.API}${EndPoints.PRODUCT_TYPES}`),
    destinations: new DestinationApi(apiWrapper, `${EndPoints.API}${EndPoints.DESTINATIONS}`),
    traceStatusReasons: new TraceStatusReasonApi(apiWrapper, `${EndPoints.API}${EndPoints.TRACE_STATUS_REASONS}`),
    branchOffices: new BranchOfficesApi(apiWrapper, `${EndPoints.API}${EndPoints.BRANCH_OFFICES}`),
    bankAccounts: new BankAccountApi(apiWrapper, `${EndPoints.API}${EndPoints.BANK_ACCOUNTS}`),
    vehicles: new VehiclesApi(apiWrapper, `${EndPoints.API}${EndPoints.VEHICLES}`),
    vehicleTypes: new VehicleTypeApi(apiWrapper, `${EndPoints.API}${EndPoints.VEHICLE_TYPES}`),
    serviceCosts: new ServiceCostApi(apiWrapper, `${EndPoints.API}${EndPoints.SERVICE_DELIVERY_PRICES}`),
    customers: new CustomersApi(apiWrapper, `${EndPoints.API}${EndPoints.CUSTOMERS}`),
    customerLocation: new CustomersLocationApi(apiWrapper, `${EndPoints.API}${EndPoints.CUSTOMERS}`),
    customersAccount: new CustomersAccountApi(apiWrapper, `${EndPoints.API}${EndPoints.CUSTOMERS}`),
    customersContact: new CustomerContactApi(apiWrapper, `${EndPoints.API}${EndPoints.CUSTOMERS}`),
    customerReceptions: new CustomerReceptionsApi(apiWrapper, `${EndPoints.API}${EndPoints.CUSTOMERS}`),
    customerSolvencies: new CustomerSolvencyApi(apiWrapper, `${EndPoints.API}${EndPoints.CUSTOMERS}`),
    customerVerificationCodes: new CustomerVerificationCodeApi(apiWrapper, `${EndPoints.API}${EndPoints.CUSTOMERS}`),
    receptions: new ReceptionsApi(apiWrapper, `${EndPoints.API}${EndPoints.RECEPTIONS}`),
    shipments: new ShipmentApi(apiWrapper, `${EndPoints.API}${EndPoints.SHIPMENTS}`),
    trackings: new TrackingApi(apiWrapper, `${EndPoints.API}${EndPoints.TRACKINGS}`),
    solvencies: new SolvencyApi(apiWrapper, `${EndPoints.API}${EndPoints.SOLVENCIES}`),
    weightCosts: new WeightCostApi(apiWrapper, `${EndPoints.API}${EndPoints.WEIGHT_COSTS}`),
    dashboard: new DashboardApi(apiWrapper, `${EndPoints.API}${EndPoints.RESUMEN}`),
    prepaidPlans: new PrepaidPlanApi(apiWrapper, `${EndPoints.API}${EndPoints.PREPAID_PLAN}`),
    employees: new EmployeesApi(apiWrapper, `${EndPoints.API}${EndPoints.EMPLOYEES}`),
    neighborhoods: new NeighborhoodApi(apiWrapper, `${EndPoints.API}${EndPoints.NEIGHBORHOODS}`),
    collectionRoutes: new CollectionRouteApi(apiWrapper, `${EndPoints.API}${EndPoints.COLLECTION_ROUTES}`),
    collectionRouteTraces: new CollectionRouteTraceApi(apiWrapper, `${EndPoints.API}${EndPoints.COLLECTION_ROUTE_TRACES}`),
    customerDashboard: new CustomerDashboardApi(apiWrapper, `${EndPoints.API}${EndPoints.CUSTOMER_DASHBOARD}`),
    currencies: new CurrencyApi(apiWrapper, `${EndPoints.API}${EndPoints.CURRENCIES}`),
    taxTypes: new TaxTypeApi(apiWrapper, `${EndPoints.API}${EndPoints.TAX_TYPES}`),
    taxFees: new TaxFeeApi(apiWrapper, `${EndPoints.API}${EndPoints.TAX_FEES}`),
    taxFactorTypes: new TaxFactorTypeApi(apiWrapper, `${EndPoints.API}${EndPoints.TAX_FACTOR_TYPES}`),
    paymentMethods: new PaymentMethodApi(apiWrapper, `${EndPoints.API}${EndPoints.PAYMENT_METHODS}`),
    paymentTypes: new PaymentTypeApi(apiWrapper, `${EndPoints.API}${EndPoints.PAYMENT_TYPES}`),
    billTypes: new BillTypeApi(apiWrapper, `${EndPoints.API}${EndPoints.BILL_TYPES}`),
    billConcepts: new BillConceptApi(apiWrapper, `${EndPoints.API}${EndPoints.BILL_CONCEPTS}`),
    billUsingTypes: new BillUsingTypeApi(apiWrapper, `${EndPoints.API}${EndPoints.BILL_USING_TYPES}`),
    billRelations: new BillRelationApi(apiWrapper, `${EndPoints.API}${EndPoints.BILL_RELATIONS}`),
    countries: new CountryApi(apiWrapper, `${EndPoints.API}${EndPoints.COUNTRIES}`),
    districts: new DistrictApi(apiWrapper, `${EndPoints.API}${EndPoints.DISTRICTS}`),
    municipalities: new MunicipalityApi(apiWrapper, `${EndPoints.API}${EndPoints.MUNICIPALITIES}`),
    postalCodes: new PostalCodeApi(apiWrapper, `${EndPoints.API}${EndPoints.POSTAL_CODES}`),
    sales: new SaleApi(apiWrapper, `${EndPoints.API}${EndPoints.SALES}`),
    packagingTypes: new PackagingTypeApi(apiWrapper, `${EndPoints.API}${EndPoints.PACKAGING_TYPES}`),
    transportationTypes: new TransportationTypeApi(apiWrapper, `${EndPoints.API}${EndPoints.TRANSPORTATION_TYPES}`),
    dangerousMaterials: new DangerousMaterialApi(apiWrapper, `${EndPoints.API}${EndPoints.DANGEROUS_MATERIALS}`),
    transportMechandiseTypes: new TransportMerchandiseApi(apiWrapper, `${EndPoints.API}${EndPoints.TRANSPORT_MERCHANDISE_TYPES}`),
    sctPermissionTypes: new SctPermissionTypeApi(apiWrapper, `${EndPoints.API}${EndPoints.SCT_PERMISSION_TYPES}`),
    unitKeys: new UnitKeyApi(apiWrapper, `${EndPoints.API}${EndPoints.UNIT_KEYS}`),
    billConceptKeys: new BillConceptKeyApi(apiWrapper, `${EndPoints.API}${EndPoints.BILL_CONCEPT_KEYS}`),
    transportFigureTypes: new TransportFigureTypeApi(apiWrapper, `${EndPoints.API}${EndPoints.TRANSPORT_FIGURE_TYPES}`),
    consignmentLocationTypes: new ConsignmentLocationTypeApi(apiWrapper, `${EndPoints.API}${EndPoints.CONSIGNMENT_LOCATION_TYPES}`),
    saleBillStatuses: new SaleBillStatusApi(apiWrapper, `${EndPoints.API}${EndPoints.SALE_BILL_STATUSES}`),
    bills: new BillApi(apiWrapper, `${EndPoints.API}${EndPoints.BILLS}`),
    orders: new OrderApi(apiWrapper, `${EndPoints.API}${EndPoints.ORDERS}`),
    orderStatuses: new OrderStatusApi(apiWrapper, `${EndPoints.API}${EndPoints.ORDER_STATUSES}`),
    places: new PlaceApi(apiWrapper, `${EndPoints.API}${EndPoints.PLACES}`),
    deviceLocations: new GpsTrackingApi(apiWrapper, `${EndPoints.API}${EndPoints.GPS_DEVICE_LOCATIONS}`),
    taxRegimes: new TaxRegimeApi(apiWrapper, `${EndPoints.API}${EndPoints.TAX_REGIMES}`),
    customerSpecialPrices: new CustomerSpecialPriceApi(apiWrapper, `${EndPoints.API}${EndPoints.CUSTOMER_SPECIAL_PRICES}`)
}
