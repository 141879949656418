<script>
import ListSaleComponent from "./ListSaleComponent";
import ModalSaleView from "./ModalSaleView";
import ModalBillingView from "./ModalBillingView";
import ModalImportView from "../shared/modals/ModalImportView";
import ModalFinancialHistoryView from "./financialHistory/ModalFinancialHistoryView";

export default {
  components: {
    "modal-sale-view": ModalSaleView,
    "modal-import-view": ModalImportView,
    "modal-billing-view": ModalBillingView,
    "modal-financial-history": ModalFinancialHistoryView,
  },
  extends: ListSaleComponent,
};
</script>

<template>
  <div>
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="row mb-2">
              <div class="d-flex justify-content-between">
                <div class="col-2">
                  <div id="entradas" class="mb-3">
                    <span class="mx-2">Mostrar</span>
                    <el-select
                      v-model="defaultShowEntry"
                      placeholder="Opción"
                      size="small"
                      @change="onChangeEntries"
                    >
                      <el-option
                        v-for="item in showEntries"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      />
                    </el-select>
                    <span class="mx-2">registros </span>
                  </div>
                </div>
                <div class="col-6 me-2"></div>
                <div class="col-4">
                  <div id="buttonlist" class="mb-3">
                    <el-button
                      type="danger"
                      class="filter-btn"
                      icon="fa fa-filter"
                      size="medium"
                      @click="onMoreFilters"
                    >
                      Filtrar por:
                    </el-button>
                    <el-dropdown size="medium" class="more-options-action">
                      <el-button type="plain" size="medium">
                        <i class="uil-ellipsis-v" /> M&aacute;s Opciones
                        <i class="el-icon-arrow-down el-icon--right" />
                      </el-button>
                      <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item class="my-2" align="center">
                          <el-button
                            size="medium"
                            class="w-100 text-start"
                            @click="onShowBilling"
                          >
                            <i class="uil-receipt" /> Facturación De Manifiestos
                          </el-button>
                        </el-dropdown-item>
                        <!-- <el-dropdown-item class="my-2">
                          <el-button
                            size="medium"
                            class="w-100 text-start"
                            @click="onExport"
                          >
                            <i class="uil-comparison" /> Reportes de Facturación
                          </el-button>
                        </el-dropdown-item>
                        -->
                        <el-dropdown-item class="my-2">
                          <el-button
                            size="medium"
                            class="w-100 text-start"
                            :disabled="!onCanRead(modulePermissions)"
                            @click="onOpenFinancialHistory"
                          >
                            <i class="uil uil-invoice" /> Resumen de Financiero
                            de Servicios
                          </el-button>
                        </el-dropdown-item>
                        <!--<el-dropdown-item class="my-2">
                          <el-button
                            size="medium"
                            class="w-100 text-start"
                            :disabled="!onCanRead(modulePermissions)"
                            @click="onRestructureLocations"
                          >
                            <i class="el-icon-refresh" /> Restructurar Direcciones
                          </el-button>
                        </el-dropdown-item>-->
                      </el-dropdown-menu>
                    </el-dropdown>
                    <el-button
                      v-if="onUserPermission('Permission.Sale.Show')"
                      type="primary"
                      size="medium"
                      class="main-action"
                      @click="onCreate"
                    >
                      <i class="fas fa-plus-circle" /> Crear Comprobante Fiscal
                    </el-button>
                  </div>
                </div>
              </div>
            </div>
            <!-- end row -->
            <div class="table-responsive mb-4">
              <el-table
                ref="multipleTable"
                v-loading="isListLoading"
                class="table table-centered table-nowrap mb-0"
                :data="collection"
                style="width: 100%"
                @selection-change="onSelectSale"
              >
                <el-table-column type="selection" width="55" />
                <el-table-column
                  property="folNumber"
                  label="Folio"
                  width="80"
                  align="center"
                />
                <el-table-column
                  property="receptionTrackingNumber"
                  label="No. Guía"
                  width="140"
                  align="center"
                >
                  <template slot-scope="scope">
                    <el-tag
                      effect="plain"
                      type="primary"
                      v-if="scope.row.receptionTrackingNumber"
                    >
                      <i class="el-icon-ticket" />
                      {{ scope.row.receptionTrackingNumber }}
                    </el-tag>
                  </template>
                </el-table-column>

                <el-table-column
                  label="Tipo Comprobante"
                  property="billType"
                  show-overflow-tooltip
                  align="center"
                  width="160"
                />
                <el-table-column
                  label="Fecha"
                  show-overflow-tooltip
                  align="center"
                  width="140"
                >
                  <template slot-scope="scope">
                    <el-tag effect="plain" type="primary">
                      <i class="el-icon-date" />
                      {{ scope.row.billDate | formatDate }}
                    </el-tag>
                  </template>
                </el-table-column>

                <el-table-column
                  label="Cliente"
                  property="customer"
                  show-overflow-tooltip
                  align="center"
                />

                <el-table-column
                  label="Metodo de Pago"
                  property="paymentMethod"
                  show-overflow-tooltip
                  align="center"
                  width="150"
                />
                <!-- <el-table-column
                  property="paymentType"
                  label="Tipo de Pago"
                  show-overflow-tooltip
                  align="center"
                  width="160"
                /> -->
                <el-table-column
                  label="Estatus de Pago"
                  show-overflow-tooltip
                  align="center"
                  width="140"
                >
                  <template #default="scope">
                    <el-tag
                      v-if="scope.row.isPaid"
                      type="success"
                      effect="plain"
                    >
                      <i class="el-icon-success" />
                      {{ scope.row.freightPayableStatus }}
                    </el-tag>
                    <el-tag v-else type="danger" effect="plain">
                      <i class="el-icon-error" />
                      {{ scope.row.freightPayableStatus }}
                    </el-tag>
                  </template>
                </el-table-column>
                <!-- <el-table-column
                  property="subtotal"
                  label="Subtotal"
                  show-overflow-tooltip
                  align="center"
                  width="130"
                >
                  <template slot-scope="scope">
                    <el-tag effect="plain" type="primary">
                      {{ scope.row.subtotal | formatCurrency }}
                    </el-tag>
                  </template>
                </el-table-column>
                <el-table-column
                  property="totalFederalTax"
                  label="Impuestos"
                  show-overflow-tooltip
                  align="center"
                  width="130"
                >
                  <template slot-scope="scope">
                    <el-tag effect="plain" type="primary">
                      {{ scope.row.totalFederalTax | formatCurrency }}
                    </el-tag>
                  </template>
                </el-table-column> -->
                <!-- <el-table-column
                  property="discount"
                  label="Descuento"
                  show-overflow-tooltip
                  align="center"
                  width="130"
                >
                  <template slot-scope="scope">
                    <el-tag effect="plain" type="primary">
                      {{ scope.row.discount | formatCurrency }}
                    </el-tag>
                  </template>
                </el-table-column> -->
                <el-table-column
                  property="total"
                  label="Total"
                  show-overflow-tooltip
                  align="center"
                  width="140"
                >
                  <template slot-scope="scope">
                    <el-tag effect="plain" type="primary">
                      {{ scope.row.total | formatCurrency }}
                    </el-tag>
                  </template>
                </el-table-column>

                <el-table-column
                  label="Estado Comprobante"
                  show-overflow-tooltip
                  align="center"
                  width="180"
                >
                  <template slot-scope="scope">
                    <el-tag
                      effect="plain"
                      :type="getBillStatusStyle(scope.row.billStatus)"
                    >
                      <i :class="getBillStatusIcon(scope.row.billStatus)" />
                      {{ scope.row.billStatusLabel }}
                    </el-tag>
                  </template>
                </el-table-column>

                <el-table-column
                  label="Opciones"
                  width="120"
                  align="center"
                  show-overflow-tooltip
                >
                  <template #default="scope">
                    <el-dropdown @command="onChangeOption">
                      <el-button size="small">
                        <i class="el-icon-more" />
                      </el-button>
                      <template #dropdown>
                        <el-dropdown-menu>
                          <!-- <el-dropdown-item
                            :command="{ command:'do-bill',item: scope.row }"
                          >
                            <i class="el-icon-edit" /> Generar Factura
                          </el-dropdown-item> -->
                          <el-dropdown-item
                            v-if="isBillActive(scope.row.billStatus)"
                            :disabled="!onCanRead(modulePermissions)"
                            :command="{
                              command: 'download-pdf',
                              item: scope.row,
                            }"
                          >
                            <i class="mdi mdi-file-pdf-outline" /> Descargar Pdf
                          </el-dropdown-item>
                          <el-dropdown-item
                            v-if="isBillActive(scope.row.billStatus)"
                            :disabled="!onCanRead(modulePermissions)"
                            :command="{
                              command: 'download-xml',
                              item: scope.row,
                            }"
                          >
                            <i class="mdi mdi-xml" /> Descargar Xml
                          </el-dropdown-item>
                          <el-dropdown-item
                            v-if="!isBillActive(scope.row.billStatus)"
                            :disabled="!onCanRead(modulePermissions)"
                            :command="{ command: 'do-bill', item: scope.row }"
                          >
                            <i class="mdi mdi-file-pdf-outline" /> Timbrar
                            Comprobante
                          </el-dropdown-item>
                          <el-dropdown-item
                            v-if="isBillActive(scope.row.billStatus)"
                            :disabled="!onCanRead(modulePermissions)"
                            :command="{
                              command: 'do-bill-cancellation',
                              item: scope.row,
                            }"
                          >
                            <i class="mdi mdi-file-pdf-outline" /> Cancelar
                            Comprobante
                          </el-dropdown-item>
                          <el-dropdown-item
                            :disabled="!onCanRead(modulePermissions)"
                            :command="{ command: 'edit', item: scope.row }"
                          >
                            <i class="el-icon-edit" /> Mostrar
                          </el-dropdown-item>
                          <el-dropdown-item
                            :disabled="!onCanDelete(modulePermissions)"
                            :command="{ command: 'delete', item: scope.row }"
                          >
                            <i class="el-icon-delete" /> Eliminar
                          </el-dropdown-item>
                        </el-dropdown-menu>
                      </template>
                    </el-dropdown>
                  </template>
                </el-table-column>
              </el-table>
            </div>
            <div class="row mt-4">
              <div class="col-sm-6">
                <div>
                  <p class="mb-sm-0">
                    Mostrar 1 a {{ pagination.limit }} de
                    {{ pagination.totalItems }} registros
                  </p>
                </div>
              </div>
              <div class="col-sm-6">
                <div class="float-sm-end">
                  <el-pagination
                    :page-count="pagination.totalPages"
                    :current-page.sync="currentPage"
                    layout="prev, pager, next"
                    @current-change="onPageChange"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <modal-import-view :conf="modalImport" />
    <modal-sale-view
      v-if="modalConfig.isVisible"
      :form-data="item"
      :config="modalConfig"
    />
    <modal-billing-view v-if="modalBilling.isVisible" :config="modalBilling" />
    <modal-financial-history
      v-if="modalFinancialHistory.isVisible"
      :config="modalFinancialHistory"
    />
    <el-drawer
      custom-class="filters-drawer"
      :visible.sync="isMoreFilterVisible"
      direction="rtl"
      size="20%"
    >
      <template slot="title">
        <span class="title">Filtros</span>
        <el-button class="btn-text" type="text" @click="onClearFilters"
          >Borrar todos los filtros</el-button
        >
      </template>
      <el-row class="row-bg wrapper-more-filters" :gutter="20">
        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
          <div class="block">
            <span class="block-title">Folio:</span>
            <el-input
              type="text"
              placeholder="Buscar por folio"
              v-model="filtersSelected.folNumber"
              clearable
              size="small"
              v-on:input="onSearchByFolNumber"
            ></el-input>
          </div>
        </el-col>
      </el-row>
      <el-row class="row-bg wrapper-more-filters" :gutter="20">
        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
          <div class="block">
            <span class="block-title">Guía:</span>
            <el-input
              type="text"
              placeholder="Buscar por Guía"
              v-model="filtersSelected.receptionNumber"
              clearable
              size="small"
              v-on:input="onSearchByReceptionNumber"
            ></el-input>
          </div>
        </el-col>
      </el-row>
      <el-row class="row-bg wrapper-more-filters" :gutter="20">
        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
          <el-tabs v-model="activeName">
            <el-tab-pane label="Buscar por Total Mínimo y Máximo" name="total">
              <el-slider
                v-model="filtersSelected.total"
                class="px-2"
                range
                :max="maxTotal"
                :format-tooltip="formatTooltip"
                @change="searchTotalFilter"
              />
            </el-tab-pane>
          </el-tabs>
        </el-col>
      </el-row>
      <el-row class="row-bg wrapper-more-filters" :gutter="20">
        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
          <div class="block">
            <span class="block-title">Fecha:</span>
            <el-date-picker
              v-model="filtersSelected.saleDate"
              type="daterange"
              size="small"
              clearable
              range-separator="-"
              start-placeholder="F. Inicio"
              end-placeholder="F. Fin"
              value-format="yyyy-MM-dd"
              @input="searchSaleDateFilter"
            />
          </div>
        </el-col>
      </el-row>
      <el-row class="row-bg wrapper-more-filters" :gutter="20">
        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
          <div class="block">
            <span class="block-title">Tipo de pago:</span>
            <el-select
              v-model="filtersSelected.paymentType"
              placeholder="Seleccionar"
              :popper-append-to-body="false"
              clearable
              @change="searchpaymentTypeFilter"
            >
              <el-option
                v-for="item in paymentTypeStatus"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </div>
        </el-col>
      </el-row>
      <el-row class="row-bg wrapper-more-filters" :gutter="20">
        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
          <div class="block">
            <span class="block-title">Estado de Factura:</span>
            <el-select
              v-model="filtersSelected.billStatus"
              placeholder="Seleccionar"
              :popper-append-to-body="false"
              clearable
              @change="serachBillStatusFilter"
            >
              <el-option
                v-for="item in saleBillStatus"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </div>
        </el-col>
      </el-row>
    </el-drawer>
  </div>
</template>
