<script>
import AddressBoxComponent from "./AddressBoxComponent";

export default {
  extends: AddressBoxComponent,
};
</script>
<template>
  <el-row
    v-loading="isAddressLoading"
    type="flex"
    class="row-bg reception-steps-wrapper"
    :gutter="20"
  >
    <el-col v-if="orientation === 'vertical'" :span="24">
      <el-row v-if="customer" class="row-bg" :gutter="20">
        <el-col :span="24">
          <el-form-item
            label="Ubicaciones"
            :prop="`${propName}.customerLocation`"
          >
            <el-select
              v-model="formData.customerLocation"
              placeholder="Seleccionar"
              filterable
              clearable
              debounce
              value-key="id"
              size="medium"
              @change="onChangeCustomerLocation"
            >
              <el-option
                v-for="item in formData.customerLocations"
                :key="item.id"
                :label="item.getNameWithCode() || item.description"
                :value="item"
              />
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row class="row-bg" :gutter="20">
        <el-col v-if="!showPostalCodeName" :xs="24" :sm="12" :xl="12">
          <el-form-item label="Código Postal" :prop="`${propName}.postalCode`">
            <!--<el-input
              v-model="formData.postalCode"
              autocomplete="off"
              size="medium"
            />-->
            <el-select
              v-model="formData.postalCode"
              placeholder="Seleccionar C.P."
              value-key="id"
              size="medium"
              filterable
              clearable
              remote
              :remote-method="onFindPostalCodes"
              autocomplete="new-text"
              @change="onChangePostalCode"
            >
              <el-option
                v-for="item in postalCodes"
                :key="item.id"
                :label="item.getFullDescription()"
                :value="item"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col v-else :xs="24" :sm="12" :xl="12">
          <el-form-item
            v-loading="isPostalCodeLoading"
            label="Agregar código postal"
            prop="postalCodeName"
          >
            <el-input
              v-model="formData.postalCodeName"
              placeholder="Ingresar codigo postal nuevo"
              class="input-with-select"
              type="text"
              autocomplete="new-text"
            />
          </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="12" :xl="12" align="center">
          <el-button-group class="actions">
            <el-button
              v-if="!showPostalCodeName"
              size="medium"
              icon="el-icon-search"
              @click="onFindAddresss(formData)"
              type="primary"
            >
              Autocompletar
            </el-button>
            <!-- <el-button
              v-if="!showPostalCodeName"
              size="medium"
              type="primary"
              icon="el-icon-circle-plus-outline"
              @click="onShowPostalCodeInput"
            >
              Agregar C.P.
            </el-button>-->

            <el-button
              v-if="showPostalCodeName"
              size="medium"
              type="primary"
              icon="el-icon-document-add"
              @click="onAddPostalCode()"
            >
              Guardar
            </el-button>

            <el-button
              v-if="showPostalCodeName"
              size="medium"
              icon="el-icon-error"
              type="danger"
              @click="onCancelPostalCodeInput"
            />
          </el-button-group>
        </el-col>
      </el-row>
      <el-row class="row-bg" :gutter="20">
        <el-col :xs="24" :sm="12">
          <el-form-item label="País" :prop="`${propName}.country`">
            <el-select
              v-model="formData.country"
              placeholder="Seleccionar País"
              value-key="id"
              size="medium"
              filterable
              autocomplete="new-text"
              @change="onChangeCountry"
            >
              <el-option
                v-for="item in countries"
                :key="item.id"
                :label="item.name"
                :value="item"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="12">
          <el-form-item label="Estado" :prop="`${propName}.district`">
            <el-select
              v-model="formData.district"
              placeholder="Seleccionar estado"
              value-key="id"
              size="medium"
              filterable
              autocomplete="new-text"
              @change="onChangeDistrict"
            >
              <el-option
                v-for="item in districts"
                :key="item.id"
                :label="item.name"
                :value="item"
              />
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row class="row-bg" :gutter="20">
        <el-col :xs="24" :sm="12">
          <el-form-item label="Municipio" :prop="`${propName}.municipality`">
            <el-select
              v-model="formData.municipality"
              placeholder="Seleccionar municipio"
              value-key="id"
              size="medium"
              filterable
              autocomplete="new-text"
            >
              <el-option
                v-for="item in municipalities"
                :key="item.id"
                :label="item.name"
                :value="item"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="12">
          <el-form-item label="Ciudad" :prop="`${propName}.city`">
            <el-select
              v-model="formData.city"
              placeholder="Seleccionar ciudad"
              value-key="id"
              size="medium"
              filterable
              autocomplete="new-text"
            >
              <el-option
                v-for="item in cities"
                :key="item.id"
                :label="item.name"
                :value="item"
              />
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row class="row-bg" :gutter="20">
        <el-col v-if="!showNeighborhoodName" :xs="24" :sm="16">
          <el-form-item label="Colonia" :prop="`${propName}.neighborhood`">
            <el-select
              v-model="formData.neighborhood"
              placeholder="Seleccionar colonia"
              value-key="id"
              size="medium"
              filterable
              clearable
              style="width:100% !important;"
              @change="onChangeNeighborhood"
            >
              <el-option
                v-for="item in neighborhoods"
                :key="item.id"
                :label="item.name"
                :value="item"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col v-else :xs="24" :sm="16">
          <el-form-item
            v-loading="isNeigborhoodLoading"
            label="Agregar la colonia en el siguiente campo."
          >
            <el-input
              v-model="neighborhoodName"
              placeholder="Ingresar nueva colonia"
            />
          </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="8" align="center">
          <el-button-group class="actions">
            <el-button
              v-if="!showNeighborhoodName"
              size="medium"
              type="primary"
              icon="el-icon-circle-plus-outline"
              @click="onShowNeighborhoodInput"
            >
              Agregar Colonia
            </el-button>

            <el-button
              v-if="showNeighborhoodName"
              size="medium"
              type="primary"
              icon="el-icon-document-add"
              @click="onAddNeighborhood"
            >
              Guardar
            </el-button>

            <el-button
              v-if="showNeighborhoodName"
              size="medium"
              icon="el-icon-error"
              type="danger"
              @click="onCancelNeighborhoodInput"
            />
          </el-button-group>
        </el-col>
      </el-row>
      <el-row class="row-bg" :gutter="20">
        <el-col :xs="24" :sm="12">
          <el-form-item
            label="Calle Principal"
            :prop="`${propName}.mainStreet`"
          >
            <el-input
              v-model="formData.mainStreet"
              placeholder="Ingresar información..."
              size="medium"
            />
          </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="12">
          <el-form-item
            label="Entre Calles"
            :prop="`${propName}.secondaryStreet`"
          >
            <el-input
              v-model="formData.secondaryStreet"
              placeholder="Ingresar información..."
              size="medium"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row class="row-bg" :gutter="20">
        <el-col :xs="24" :sm="12">
          <el-form-item label="No. Exterior" :prop="`${propName}.extNumber`">
            <el-input
              v-model="formData.extNumber"
              placeholder="Ingresar información..."
              size="medium"
            />
          </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="12">
          <el-form-item label="No. Interior" :prop="`${propName}.intNumber`">
            <el-input
              v-model="formData.intNumber"
              placeholder="Ingresar información..."
              size="medium"
            />
          </el-form-item>
        </el-col>
      </el-row>
    </el-col>
    <el-col v-if="orientation === 'horizontal'" :span="24">
      <el-row v-if="customer" type="flex" class="row-bg" :gutter="20">
        <el-col :span="24">
          <el-form-item
            label="Ubicaciones"
            :prop="`${propName}.customerLocation`"
          >
            <el-select
              v-model="formData.customerLocation"
              placeholder="Seleccionar"
              filterable
              clearable
              debounce
              value-key="id"
              size="medium"
              @change="onChangeCustomerLocation"
            >
              <el-option
                v-for="item in formData.customerLocations"
                :key="item.id"
                :label="item.description"
                :value="item"
              />
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row type="flex" class="row-bg" :gutter="20">
        <el-col v-if="!showPostalCodeName" :span="8">
          <el-form-item label="Código Postal" :prop="`${propName}.postalCode`">
            <!--<el-input
              v-model="formData.postalCode"
              autocomplete="off"
              size="medium"
            />-->
            <el-select
              v-model="formData.postalCode"
              placeholder="Seleccionar C.P."
              value-key="id"
              size="medium"
              filterable
              clearable
              remote
              :remote-method="onFindPostalCodes"
              autocomplete="new-text"
              @change="onChangePostalCode"
            >
              <el-option
                v-for="item in postalCodes"
                :key="item.id"
                :label="item.getFullDescription()"
                :value="item"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col v-else :span="10">
          <el-form-item
            v-loading="isPostalCodeLoading"
            label="Agregar código postal"
            prop="postalCodeName"
          >
            <el-input
              v-model="formData.postalCodeName"
              placeholder="Ingresar codigo postal nuevo"
              class="input-with-select"
              type="text"
              autocomplete="new-text"
            />
          </el-form-item>
        </el-col>
        <el-col :span="4" align="center">
          <el-button-group style="margin-top:60px;">
            <el-button
              v-if="!showPostalCodeName"
              size="medium"
              icon="el-icon-search"
              @click="onFindAddresss(formData)"
              type="primary"
            >
              Autocompletar
            </el-button>
            <!-- <el-button
              v-if="!showPostalCodeName"
              size="medium"
              type="primary"
              icon="el-icon-circle-plus-outline"
              @click="onShowPostalCodeInput"
            >
              Agregar C.P.
            </el-button>-->

            <el-button
              v-if="showPostalCodeName"
              size="medium"
              type="primary"
              icon="el-icon-document-add"
              @click="onAddPostalCode()"
            >
              Guardar
            </el-button>

            <el-button
              v-if="showPostalCodeName"
              size="medium"
              icon="el-icon-error"
              type="danger"
              @click="onCancelPostalCodeInput"
            />
          </el-button-group>
        </el-col>
        <el-col :span="6">
          <el-form-item label="País" :prop="`${propName}.country`">
            <el-select
              v-model="formData.country"
              placeholder="Seleccionar País"
              value-key="id"
              size="medium"
              filterable
              autocomplete="new-text"
              @change="onChangeCountry"
            >
              <el-option
                v-for="item in countries"
                :key="item.id"
                :label="item.name"
                :value="item"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="Estado" :prop="`${propName}.district`">
            <el-select
              v-model="formData.district"
              placeholder="Seleccionar estado"
              value-key="id"
              size="medium"
              filterable
              autocomplete="new-text"
              @change="onChangeDistrict"
            >
              <el-option
                v-for="item in districts"
                :key="item.id"
                :label="item.name"
                :value="item"
              />
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row type="flex" class="row-bg" :gutter="20">
        <el-col :span="6">
          <el-form-item label="Municipio" :prop="`${propName}.municipality`">
            <el-select
              v-model="formData.municipality"
              placeholder="Seleccionar municipio"
              value-key="id"
              size="medium"
              filterable
              autocomplete="new-text"
            >
              <el-option
                v-for="item in municipalities"
                :key="item.id"
                :label="item.name"
                :value="item"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="Ciudad" :prop="`${propName}.city`">
            <el-select
              v-model="formData.city"
              placeholder="Seleccionar ciudad"
              value-key="id"
              size="medium"
              filterable
              autocomplete="new-text"
            >
              <el-option
                v-for="item in cities"
                :key="item.id"
                :label="item.name"
                :value="item"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col v-if="showNeighborhoodName" :span="8">
          <el-form-item
            v-loading="isNeigborhoodLoading"
            label="Puede agregar la colonia en el siguiente campo"
          >
            <el-input
              v-model="neighborhoodName"
              placeholder="Ingresar nueva colonia"
              class="input-with-select"
            />
          </el-form-item>
        </el-col>
        <el-col v-else :span="8">
          <el-form-item label="Colonia" :prop="`${propName}.neighborhood`">
            <el-select
              v-model="formData.neighborhood"
              placeholder="Seleccionar colonia"
              value-key="id"
              size="medium"
              filterable
              clearable
              style="width:100% !important;"
              @change="onChangeNeighborhood"
            >
              <el-option
                v-for="item in neighborhoods"
                :key="item.id"
                :label="item.name"
                :value="item"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="4" align="center">
          <el-button-group style="margin-top:60px;">
            <el-button
              v-if="!showNeighborhoodName"
              size="medium"
              type="primary"
              icon="el-icon-circle-plus-outline"
              @click="onShowNeighborhoodInput"
            >
              Agregar Colonia
            </el-button>

            <el-button
              v-if="showNeighborhoodName"
              size="medium"
              type="primary"
              icon="el-icon-document-add"
              @click="onAddNeighborhood"
            >
              Guardar
            </el-button>

            <el-button
              v-if="showNeighborhoodName"
              size="medium"
              icon="el-icon-error"
              type="danger"
              @click="onCancelNeighborhoodInput"
            />
          </el-button-group>
        </el-col>
      </el-row>
      <el-row type="flex" class="row-bg" :gutter="20"> </el-row>
      <el-row type="flex" class="row-bg" :gutter="20">
        <el-col :span="8">
          <el-form-item
            label="Calle Principal"
            :prop="`${propName}.mainStreet`"
          >
            <el-input
              v-model="formData.mainStreet"
              placeholder="Ingresar información..."
              size="medium"
            />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item
            label="Entre Calles"
            :prop="`${propName}.secondaryStreet`"
          >
            <el-input
              v-model="formData.secondaryStreet"
              placeholder="Ingresar información..."
              size="medium"
            />
          </el-form-item>
        </el-col>
        <el-col :span="4">
          <el-form-item label="No. Exterior" :prop="`${propName}.extNumber`">
            <el-input
              v-model="formData.extNumber"
              placeholder="Ingresar información..."
              size="medium"
            />
          </el-form-item>
        </el-col>
        <el-col :span="4">
          <el-form-item label="No. Interior" :prop="`${propName}.intNumber`">
            <el-input
              v-model="formData.intNumber"
              placeholder="Ingresar información..."
              size="medium"
            />
          </el-form-item>
        </el-col>
      </el-row>
    </el-col>
  </el-row>
</template>
