<script>
import ModalFinancialHistoryComponent from "./ModalFinancialHistoryComponent";


export default {
  extends: ModalFinancialHistoryComponent
};
</script>

<template>
  <el-dialog custom-class="dialog-reception-financial-history" :visible.sync="config.isVisible" :before-close="onCancel"
    :close-on-click-modal="false" width="85%">
    <span slot="title" class="dialog-title">
      <i class="uil-receipt" /> Resumen De Servicios de Transporte
    </span>

    <el-form ref="formData" v-loading="config.isLoading" :model="formData" :rules="rules" :label-position="'top'">
      <el-row type="flex" class="row-bg" :gutter="20">
        <el-col :span="24">
          <el-row type="flex" class="row-bg" :gutter="20">
            <el-col :span="6" align="center">
              <el-radio-group v-model="formData.freightPayable" size="medium" @change="onChangeFreightPayable">
                <el-radio-button :label="null">TODOS</el-radio-button>
                <el-radio-button :label="true">PAGADOS</el-radio-button>
                <el-radio-button :label="false">POR COBRAR</el-radio-button>
              </el-radio-group>
            </el-col>
            <el-col :span="6" align="center">
              <el-form-item prop="dateRange">
                <el-date-picker v-model="formData.dateRange" size="medium" type="daterange" clearable range-separator="a"
                  start-placeholder="Fecha Inicio" end-placeholder="Fecha Final" @change="onChangeDateRange" />
              </el-form-item>
            </el-col>

            <el-col :span="12">
              <el-form-item>
                <el-select v-model="formData.destinations" placeholder="Buscar por nombre o código de destino" filterable
                  clearable debounce value-key="id" remote size="medium" multiple @change="onChangeDestinations">
                  <i slot="prefix" class="el-input__icon el-icon-search" />
                  <el-option v-for="item in destinations" :key="item.id" :label="item.name" :value="item.name" />
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row type="flex" class="row-bg" :gutter="20">
            <el-col :span="18">
              <el-form-item prop="customers">
                <el-select v-model="formData.customers" placeholder="Buscar Clientes" filterable clearable debounce
                  value-key="id" remote size="medium" multiple :remote-method="onFindCustomers"
                  @change="onChangeCustomers">
                  <i slot="prefix" class="el-input__icon el-icon-search" />
                  <el-option v-for="item in customers" :key="item.id" :label="item.fiscalName || item.description"
                    :value="item.id" />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="6" align="center">
              <el-button-group>
                <el-button type="primary" size="medium" icon="el-icon-search" @click="onSubmitForm('formData')">
                  Buscar
                </el-button>
                <el-button type="default" size="medium" icon="el-icon-printer" @click="onPrint('formData')">
                  PDF
                </el-button>
                <el-button type="default" size="medium" icon="el-icon-document" @click="onExport('formData')">
                  Excel
                </el-button>
              </el-button-group>
            </el-col>
          </el-row>

          <el-row type="flex" class="row-bg" :gutter="20">
            <el-col :span="24">
              <el-collapse>
                <el-collapse-item v-for="(item, index) in result" :key="index" :title="item.customer" :name="index"
                  class="reception-history-primary">
                  <template slot="title">
                    <el-col :span="20">
                      <i class="uil uil-user-check" /> {{ item.customer }}
                    </el-col>
                    <!-- <el-col :span="4" align="center">
                                Subtotal: {{place.subtotal | formatMXNCurrency}}
                            </el-col> -->
                    <el-col :span="4" align="center">
                      Total: {{ item.total | formatMXNCurrency }}
                    </el-col>
                  </template>
                  <el-collapse>
                    <el-collapse-item v-for="(place, indexPlace) in item.destinations" :key="indexPlace"
                      :title="place.destination" :name="indexPlace" class="reception-history-secondary">
                      <template slot="title">
                        <el-col :span="20">
                          <i class="uil uil-location-point" /> DESTINO:
                          {{ place.destination }}
                        </el-col>
                        <!-- <el-col :span="4" align="center">
                                Subtotal: {{place.subtotal | formatMXNCurrency}}
                            </el-col> -->
                        <el-col :span="4" align="center">
                          Subtotal: {{ place.subtotal | formatMXNCurrency }}
                        </el-col>
                      </template>
                      <el-table :data="place.traces" style="width: 100%" height="200">
                        <el-table-column prop="trackingNumber" label="Guía" width="180" />
                        <el-table-column prop="locationNameReference" label="Area/Remitente" />

                        <el-table-column prop="quantity" label="Cantidad" width="90" align="center" />
                        <el-table-column prop="originCode" label="Origen" width="90" align="center" />
                        <el-table-column prop="destinationCode" label="Destino" width="90" align="center" />
                        <el-table-column label="P/U" width="110" align="center">
                          <template #default="scope">
                            {{ scope.row.serviceDeliveryPrice | formatMXNCurrency }}
                          </template>
                        </el-table-column>
                        <el-table-column label="Importe" width="110" align="center">
                          <template #default="scope">
                            {{ scope.row.subtotal | formatMXNCurrency }}
                          </template>
                        </el-table-column>
                        <el-table-column label="I.V.A." width="110" align="center">
                          <template #default="scope">
                            {{ scope.row.taxes | formatMXNCurrency }}
                          </template>
                        </el-table-column>
                        <el-table-column label="Total" width="110" align="center">
                          <template #default="scope">
                            {{ scope.row.total | formatMXNCurrency }}
                          </template>
                        </el-table-column>

                        <!-- <el-table-column
                          prop="senderName"
                          label="Nombre Remitente"
                        />
                        <el-table-column
                          prop="receiverName"
                          label="Nombre Destinatario"
                        />-->
                        <!-- <el-table-column
                          prop="documentReference"
                          label="Referencia"
                        />-->
                        <!-- <el-table-column
                          label="Costo Servicio"
                          width="140"
                          align="center"
                        >
                          <template #default="scope">
                            {{
                              scope.row.serviceDeliveryPrice | formatMXNCurrency
                            }}
                          </template>
                        </el-table-column>-->
                        <!-- <el-table-column
                          label="Estatus de Pago"
                          show-overflow-tooltip
                          align="center"
                          width="140"
                        >
                          <template #default="scope">
                            <el-tag
                              v-if="scope.row.freightPayable"
                              type="success"
                              effect="plain"
                            >
                              <i class="el-icon-success" />
                              {{ scope.row.freightPayableStatus }}
                            </el-tag>
                            <el-tag v-else type="danger" effect="plain">
                              <i class="el-icon-error" />
                              {{ scope.row.freightPayableStatus }}
                            </el-tag>
                          </template>
                        </el-table-column>-->
                        <!--<el-table-column
                          prop="discountPercentage"
                          label="Descuento"
                          width="140"
                          align="center"
                        >
                          <template #default="scope">
                            {{ scope.row.discountPercentage }} %
                          </template>
                        </el-table-column>
                        <el-table-column
                          label="Flete"
                          width="140"
                          align="center"
                        >
                          <template #default="scope">
                            {{ scope.row.shippingQuote | formatMXNCurrency }}
                          </template>
                        </el-table-column> -->

                        <el-table-column prop="receptionDate" label="Fecha" width="160" align="center">
                          <template #default="scope">
                            <el-tag effect="plain">
                              <i class="el-icon-date" />
                              {{ scope.row.receptionDate | formatDate }}
                            </el-tag>
                          </template>
                        </el-table-column>
                      </el-table>
                    </el-collapse-item>
                  </el-collapse>
                </el-collapse-item>
              </el-collapse>
            </el-col>
          </el-row>
        </el-col>
      </el-row>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button size="small" @click="onCancel">Cancelar</el-button>
      <!-- <el-button type="primary" size="small" @click="onSubmitForm('formData')"
        >Guardar</el-button
      > -->
    </span>
  </el-dialog>
</template>
