import BaseForm from '../infraestructure/BaseForm'
import CustomersLocationViewModel from "../../core/features/customerLocation/CustomersLocationViewModel";

export default {
    extends: BaseForm,
    data() {
        return {
            viewModel: null,
            customerId: null,
            destinations: [],
            neighborhoods: [],
            rules: {
                /*                 code: [{
                                    required: true,
                                    message: 'Código(s) requerido.',
                                    trigger: 'blur'
                                }], */
                description: [{
                    required: true,
                    message: 'Descripción requerido.',
                    trigger: 'blur'
                }],
                mainStreet: [{
                    required: true,
                    message: 'Calle Principal requerido.',
                    trigger: 'blur'
                }],
                secondaryStreet: [{
                    required: true,
                    message: 'Entre Calles requerido.',
                    trigger: 'blur'
                }],
                extNumber: [{
                    required: true,
                    message: 'Numero requerido.',
                    trigger: 'blur'
                }],
                neighborhood: [{
                    required: true,
                    message: 'Colonia requerido.',
                    trigger: 'blur'
                }],
                postalCode: [{
                    required: true,
                    message: 'Código Postal requerido.',
                    trigger: 'blur'
                }],
                city: [{
                    required: true,
                    message: 'Ciudad/Destino requerido.',
                    trigger: 'blur'
                }],
                isActive: [{
                    required: true,
                    message: 'El Estado es requerido.',
                    trigger: 'blur'
                }]
            }
        }
    },
    props: {
        config: {
            type: Object,
            required: true
        },
        formData: {
            type: Object,
            required: true
        }
    },
    created() {
        this.viewModel = new CustomersLocationViewModel(this)
        this.onActive()
    },
    methods: {
        onActive() {
            this.viewModel.bindForm(this.formData)
        },
        onSubmitForm(formName) {
            this.$refs[formName].validate((isValid) => {
                if (isValid) {
                    this.onSave(this.formData)
                    return true
                }
                return false
            })
        },
        onSaveResponse(response) {
            this.onDoAfterSaveResponse(response.message, 'on-submited-customer-locations')
        },
        onFindDestinations(data) {
            if (data) {
                this.viewModel.findDestinations(`Name=${data}&IsActive=true`);
            } else {
                this.destinations = [];
            }
        },
        onFindDestinationsResponse(response) {
            this.destinations = response.data;
        },
        onDestinationsChange() {
            this.neighborhoods = []
        },
        onFindNeighborhoods(data) {
            if (this.formData.city && data) {
                this.viewModel.findNeighborhoods(`CityId=${this.formData.city.id}&Name=${data}&IsActive=true`)
            } else {
                this.$refs.formData.validateField('city')
            }
        },
        onFindNeighborhoodsResponse(response) {
            this.neighborhoods = response.data
        },
        onAddNeighborhood() {
            if (this.formData.city && this.formData.neighborhoodName) {
                var neighborhood = this.viewModel.addNeighborhood({
                    name: this.formData.neighborhoodName,
                    city: this.formData.city
                })
                this.viewModel.createNeighborhood(neighborhood)
            } else {
                this.$refs.formData.validateField('city')
            }
        },
        onCreateNeighborhoodResponse(response) {
            this.formData.neighborhoodName = ''
            this.neighborhoods = [response.data]
            this.$set(this.formData, 'neighborhood', response.data)
            this.$refs.formData.clearValidate(['neighborhood']);
        }
    }
}