<script>
import ListOrderComponent from "./ListOrderComponent";
import ModalOrderView from "./ModalOrderView";
import ModalImportView from "../shared/modals/ModalImportView";

export default {
  components: {
    "modal-order-view": ModalOrderView,
    "modal-import-view": ModalImportView,
  },
  extends: ListOrderComponent,
};
</script>

<template>
  <div>
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="row mb-2">
              <div class="d-flex justify-content-between">
                <div>
                  <div
                    id="entradas"
                    class="mb-3"
                  >
                    <span class="mx-2">Mostrar</span>
                    <el-select
                      v-model="defaultShowEntry"
                      placeholder="Opción"
                      size="small"
                      @change="onChangeEntries"
                    >
                      <el-option
                        v-for="item in showEntries"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      />
                    </el-select>
                    <span class="mx-2">registros </span>
                  </div>
                </div>
                <div class="d-flex justify-content-between">
                  <div>
                    <div
                      id="buttonlist"
                      class="mb-3"
                    >
                      <el-button type="danger" class="filter-btn" icon="fa fa-filter" size="medium" @click="onMoreFilters">
                        Filtrar por:
                      </el-button>                      
                      <el-button
                        v-if="
                          onUserPermission('Permission.Order.Create')
                        "
                        type="primary"
                        size="medium"
                        class="main-action"
                        :disabled="!onCanCreate(modulePermissions)"
                        @click="onCreate"
                      >
                        <i class="fas fa-plus-circle" /> Crear Orden
                      </el-button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- end row -->
            <div class="table-responsive mb-4">
              <el-table
                v-if="onUserPermission('Permission.Order.Read')"
                ref="multipleTable"
                v-loading="isListLoading"
                class="table table-centered table-nowrap mb-0"
                :data="collection"
                style="width: 100%"
                @selection-change="onSelectOrder"
              >
                <el-table-column
                  type="selection"
                  width="55"
                />
                <el-table-column
                  property="orderNumber"
                  label="No. Orden"
                  width="180"
                />
                <el-table-column
                  label="Ruta de Recolección"
                  show-overflow-tooltip
                >
                  <template #default="scope">
                    <el-select
                      v-model="scope.row.collectionRoute"
                      placeholder="Seleccionar"
                      filterable
                      clearable
                      debounce
                      value-key="id"
                      size="small"
                      @change="onChangeCollectionRoute(scope.row)"
                    >
                      <el-option
                        v-for="item in collectionRoutes"
                        :key="item.id"
                        :label="item.name"
                        :value="item"
                      />
                    </el-select>
                  </template>
                </el-table-column>
                <el-table-column
                  label="Operador"
                  property="employeeProfile"
                  show-overflow-tooltip
                  width="180"
                />

                <el-table-column
                  label="Contacto"
                  show-overflow-tooltip
                  width="200"
                >
                  <template #default="scope">
                    <div v-if="scope.row.customer">
                      {{ scope.row.customer.contactName }}
                    </div>
                  </template>
                </el-table-column>
                <el-table-column
                  label="Teléfono"
                  show-overflow-tooltip
                  width="200"
                >
                  <template #default="scope">
                    <div v-if="scope.row.customer">
                      {{ scope.row.customer.contactPhone }}
                    </div>
                  </template>
                </el-table-column>
                <el-table-column
                  label="Correo"
                  show-overflow-tooltip
                >
                  <template #default="scope">
                    <div v-if="scope.row.customer">
                      {{ scope.row.customer.contactEmail }}
                    </div>
                  </template>
                </el-table-column>
                <el-table-column
                  label="Estatus"
                  show-overflow-tooltip
                  align="center"
                  width="220"
                >
                  <template #default="scope">
                    <el-tag
                      effect="plain"
                      :type="getStatusTagStyle(scope.row.status)"
                    >
                      <i :class="getStatusIcon(scope.row.status)" />
                      {{ scope.row.statusLabel || "Sin Estado" }}
                    </el-tag>
                  </template>
                </el-table-column>

                <el-table-column
                  v-if="
                    onUserPermission('Permission.Order.Update') ||
                      onUserPermission('Permission.Order.Delete')
                  "
                  label="Opciones"
                  width="120"
                  align="center"
                  show-overflow-tooltip
                >
                  <template #default="scope">
                    <el-dropdown @command="onChangeOption">
                      <el-button size="small">
                        <i class="el-icon-more" />
                      </el-button>
                      <template #dropdown>
                        <el-dropdown-menu>
                          <el-dropdown-item
                            v-if="
                              onUserPermission(
                                'Permission.Order.Read'
                              ) &&
                                scope.row.customer &&
                                scope.row.status !== 1
                            "
                            :command="{
                              command: 'print-order-tickets',
                              item: scope.row,
                            }"
                          >
                            <i class="mdi mdi-file-pdf-outline" /> Generar
                            Etiquetas de Orden
                          </el-dropdown-item>
                          <el-dropdown-item
                            v-if="
                              onUserPermission(
                                'Permission.Order.Update'
                              ) && scope.row.customer
                            "
                            :disabled="!onCanRead(modulePermissions)"
                            :command="{ command: 'edit', item: scope.row }"
                          >
                            <i class="el-icon-edit" /> Mostrar
                          </el-dropdown-item>
                          <el-dropdown-item
                            v-if="
                              onUserPermission('Permission.Order.Delete')
                            "
                            :disabled="!onCanDelete(modulePermissions)"
                            :command="{ command: 'delete', item: scope.row }"
                          >
                            <i class="el-icon-delete" /> Eliminar
                          </el-dropdown-item>
                        </el-dropdown-menu>
                      </template>
                    </el-dropdown>
                  </template>
                </el-table-column>
              </el-table>
            </div>
            <div class="row mt-4">
              <div class="col-sm-6">
                <div>
                  <p class="mb-sm-0">
                    Mostrar 1 a {{ pagination.limit }} de
                    {{ pagination.totalItems }} registros
                  </p>
                </div>
              </div>
              <div class="col-sm-6">
                <div class="float-sm-end">
                  <el-pagination
                    :page-count="pagination.totalPages"
                    :current-page.sync="currentPage"
                    layout="prev, pager, next"
                    @current-change="onPageChange"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <modal-import-view :conf="modalImport" />
    <modal-order-view
      v-if="modalConfig.isVisible"
      :form-data="item"
      :config="modalConfig"
    />
    <el-drawer
      custom-class="filters-drawer"
      :visible.sync="isMoreFilterVisible"
      direction="rtl"
      size="20%"
    >
      <template slot="title">
        <span class="title">Filtros</span>
        <el-button class="btn-text" type="text" @click="onClearFilters"
          >Borrar todos los filtros</el-button
        >
      </template>
      <el-row class="row-bg wrapper-more-filters" :gutter="20">
        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
          <div class="block">
            <span class="block-title">Orden:</span>
            <el-input
              type="text"
              placeholder="Buscar por orden"
              v-model="filtersSelected.orderNumber"
              clearable
              size="small"
              v-on:input="onSearchByOrderNumber"
            ></el-input>
          </div>
        </el-col>
      </el-row>
      <el-row class="row-bg wrapper-more-filters" :gutter="20">
        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
          <div class="block">
            <span class="block-title">Contacto:</span>
            <el-input
              type="text"
              placeholder="Buscar por contacto"
              v-model="filtersSelected.contactName"
              clearable
              size="small"
              v-on:input="onSearchByContactName"
            ></el-input>
          </div>
        </el-col>
      </el-row>
      <el-row class="row-bg wrapper-more-filters" :gutter="20">
        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
          <div class="block">
            <span class="block-title">Telefono:</span>
            <el-input
              type="text"
              placeholder="Buscar por telefono"
              v-model="filtersSelected.contactPhone"
              clearable
              size="small"
              v-on:input="onSearchByContactPhone"
            ></el-input>
          </div>
        </el-col>
      </el-row>
      <el-row class="row-bg wrapper-more-filters" :gutter="20">
        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
          <div class="block">
            <span class="block-title">Email:</span>
            <el-input
              type="text"
              placeholder="Buscar por Email"
              v-model="filtersSelected.email"
              clearable
              size="small"
              v-on:input="onSearchByEmail"
            ></el-input>
          </div>
        </el-col>
      </el-row>
      <el-row class="row-bg wrapper-more-filters" :gutter="20">
        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
          <div class="block">
            <span class="block-title">Operador:</span>
            <el-select
              v-model="filtersSelected.operator"
              placeholder="Buscar Operador"
              filterable
              clearable
              debounce
              value-key="id"
              size="medium"
              @change="onChangeOperator"
            >
              <el-option
                v-for="item in operators"
                :key="item.id"
                :label="item.firstName + ' ' + item.lastName"
                :value="item.id"
              />
            </el-select>
          </div>
        </el-col>
      </el-row>
      <el-row class="row-bg wrapper-more-filters" :gutter="20">
        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
          <div class="block">
            <span class="block-title">Ruta:</span>
            <el-select
              v-model="filtersSelected.route"
              placeholder="Buscar Ruta"
              filterable
              clearable
              debounce
              value-key="id"
              size="medium"
              @change="onChangeRoute"
            >
              <el-option
                v-for="item in routes"
                :key="item.id"
                :label="item.description"
                :value="item.id"
              />
            </el-select>
          </div>
        </el-col>
      </el-row>
      <el-row class="row-bg wrapper-more-filters" :gutter="20">
        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
          <div class="block">
            <span class="block-title">¿Activo?:</span>
            <el-select
              v-model="filtersSelected.status"
              placeholder="Seleccionar"
              :popper-append-to-body="false"
              size="medium"
              clearable
              debounce
              @change="searchStatusFilter"
            >
              <el-option
                v-for="item in statusOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </div>
        </el-col>
      </el-row>
    </el-drawer>
  </div>
</template>
