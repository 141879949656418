<script>
import AdminMenuView from "../shared/menus/AdminMenuView.vue";
import AdminHeaderView from "../shared/headers/AdminHeaderView.vue";
import AdminFooterView from "../shared/footers/AdminFooterView";
import ModuleBillConceptComponent from "./ModuleBillConceptComponent";
import ListBillConceptView from "./ListBillConceptView";
import ListCurrencyView from "../currencies/ListCurrencyView";
// import ListTaxFactorTypeView from "../taxFactorTypes/ListTaxFactorTypeView";
// import ListTaxTypeView from "../taxTypes/ListTaxTypeView";
// import ListTaxFeeView from "../taxFees/ListTaxFeeView";
import ListBillTypeView from "../billTypes/ListBillTypeView";
import ListBillUsingTypeView from "../billUsingTypes/ListBillUsingTypeView";
import ListBillRelationView from "../billRelations/ListBillRelationView";
import ListPaymentMethodView from "../paymentMethods/ListPaymentMethodView";
import ListPaymentTypeView from "../paymentTypes/ListPaymentTypeView";
import ListBillConceptKeyView from "../billConceptKeys/ListBillConceptKeyView";
import ListBankAccountView from '../bankAccount/ListBankAccountView'
import ListTaxRegimeView from '../taxRegimes/ListTaxRegimeView'

export default {
  components: {
    "admin-menu-view": AdminMenuView,
    "admin-header-view": AdminHeaderView,
    "admin-footer-view": AdminFooterView,
    "list-bill-concept-view": ListBillConceptView,
    "list-currency-view": ListCurrencyView,
    // "list-tax-factor-type-view": ListTaxFactorTypeView,
    // "list-tax-type-view": ListTaxTypeView,
    // "list-tax-fee-view": ListTaxFeeView,
    "list-bill-using-type-view": ListBillUsingTypeView,
    "list-bill-relation-view": ListBillRelationView,
    "list-bill-type-view": ListBillTypeView,
    "list-payment-method-view": ListPaymentMethodView,
    "list-payment-type-view": ListPaymentTypeView,
    "list-bill-concept-key-view": ListBillConceptKeyView,
    "list-bank-account-view": ListBankAccountView,
    "list-tax-regime-view": ListTaxRegimeView
  },
  extends: ModuleBillConceptComponent,
};
</script>

<template>
  <div id="layout-wrapper">
    <admin-header-view />
    <admin-menu-view />
    <div class="main-content">
      <div class="page-content">
        <div class="container-fluid">
          <!-- start page title -->
          <div class="row">
            <div class="col-12">
              <div
                class="page-title-box d-flex align-items-center justify-content-between"
              >
                <h4 class="mb-0">
                  <i class="uil-chart-line module-header-icon" /> Catálogo de
                  Ventas
                </h4>
              </div>
            </div>
          </div>
          <!-- end page title -->
          <div class="row">
            <div class="col-lg-12">
              <div class="card">
                <div class="card-body">
                  <el-tabs v-model="tabActive">
                    <el-tab-pane
                      label="Conceptos de Facturación"
                      name="bill-concepts"
                    >
                      <span
                        slot="label"
                      ><i class="uil-briefcase-alt me-1" />Conceptos</span>
                      <list-bill-concept-view
                        v-if="tabActive === 'bill-concepts'"
                      />
                    </el-tab-pane>

                    <el-tab-pane
                      label="Conceptos de Facturación"
                      name="bill-concept-keys"
                    >
                      <span
                        slot="label"
                      ><i class="uil-briefcase-alt me-1" />Claves Producto-Servicio</span>
                      <list-bill-concept-key-view
                        v-if="tabActive === 'bill-concept-keys'"
                      />
                    </el-tab-pane>
                    
                    <!-- <el-tab-pane
                      label="Tipos de Impuestos"
                      name="tax-types"
                    >
                      <span
                        slot="label"
                      ><i class="uil-puzzle-piece me-1" />Tipo Impuesto</span>
                      <list-tax-type-view v-if="tabActive === 'tax-types'" />
                    </el-tab-pane>-->
                    <el-tab-pane
                      label="Regimenes Fiscales"
                      name="taxRegimes"
                    >
                      <span
                        slot="label"
                      ><i class="uil-coins me-1" />Regimenes Fiscales</span>
                      <list-tax-regime-view v-if="tabActive === 'taxRegimes'" />
                    </el-tab-pane>
                    <!-- <el-tab-pane
                      label="Tasa o Cuota"
                      name="tax-fee"
                    >
                      <span
                        slot="label"
                      ><i class="uil-percentage me-1" />Tasa-Cuota</span>
                      <list-tax-fee-view v-if="tabActive === 'tax-fee'" />
                    </el-tab-pane>
                    <el-tab-pane
                      label="Tipor Factor"
                      name="tax-factor-types"
                    >
                      <span
                        slot="label"
                      ><i class="uil-sitemap me-1" />Tipo Factor</span>
                      <list-tax-factor-type-view
                        v-if="tabActive === 'tax-factor-types'"
                      />
                    </el-tab-pane> -->
                    <el-tab-pane
                      label="Tipos de Facturas"
                      name="bill-types"
                    >
                      <span
                        slot="label"
                      ><i class="uil-ticket me-1" />Tipo Factura</span>
                      <list-bill-type-view v-if="tabActive === 'bill-types'" />
                    </el-tab-pane>
                    <el-tab-pane
                      label="Uso de CFDI"
                      name="bill-using-types"
                    >
                      <span
                        slot="label"
                      ><i class="uil-receipt-alt me-1" />Uso CFDI</span>
                      <list-bill-using-type-view
                        v-if="tabActive === 'bill-using-types'"
                      />
                    </el-tab-pane>
                    <el-tab-pane
                      label="Tipo de Relación"
                      name="bill-relation"
                    >
                      <span
                        slot="label"
                      ><i class="uil-panel-add me-1" />Relación Factura</span>
                      <list-bill-relation-view
                        v-if="tabActive === 'bill-relation'"
                      />
                    </el-tab-pane>
                    <el-tab-pane
                      label="Metodo de Pago"
                      name="payment-method"
                    >
                      <span
                        slot="label"
                      ><i class="uil-coins me-1" />Metodo Pago</span>
                      <list-payment-method-view v-if="tabActive === 'payment-method'" />
                    </el-tab-pane>
                    <el-tab-pane
                      label="Tipo de Pago"
                      name="payment-type"
                    >
                      <span
                        slot="label"
                      ><i class="uil-coins me-1" />Tipo Pago</span>
                      <list-payment-type-view v-if="tabActive === 'payment-type'" />
                    </el-tab-pane>
                    <el-tab-pane
                      label="Moneda"
                      name="currencies"
                    >
                      <span
                        slot="label"
                      ><i class="uil-coins me-1" />Moneda</span>
                      <list-currency-view v-if="tabActive === 'currencies'" />
                    </el-tab-pane>
                    <el-tab-pane
                      label="Cuentas Bancarias"
                      name="bankAccounts"
                    >
                      <span
                        slot="label"
                      ><i class="uil-coins me-1" />Cuentas Bancarias</span>
                      <list-bank-account-view v-if="tabActive === 'bankAccounts'" />
                    </el-tab-pane>
                    
                  </el-tabs>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- container-fluid -->
      </div>
      <!-- End Page-content -->
      <!-- footer -->
    </div>
    <admin-footer-view />
  </div>
</template>
