<script>
import ExchangerBoxComponent from "./ExchangerBoxComponent";
import AddressBoxView from '../../shared/boxes/addressBox/AddressBoxView'


export default {
  components: {
    AddressBoxView
  },
  extends: ExchangerBoxComponent
};
</script>
<template>
  <div>
    <!-- <el-row type="flex" class="row-bg" :gutter="20">
      <el-col :span="24">
        <el-checkbox v-model="formData.isLocationDataUsed" @change="onChangeLocationDataUsed">{{usingDataLabel}}</el-checkbox>
      </el-col>
    </el-row> -->
    <el-row
      type="flex"
      class="row-bg"
      :gutter="20"
    >
      <el-col :span="6">
        <el-form-item
          :label="nameLabel"
          :prop="`${propName}.name`"
        >
          <el-input
            v-model="formData.name"
            type="text"
            size="medium"
          />
        </el-form-item>
      </el-col>
      <el-col :span="6">
        <el-form-item
          :label="rfcLabel"
          :prop="`${propName}.federalTaxPayerRegistration`"
        >
          <!-- <el-input
            v-model="formData.federalTaxPayerRegistration"
            type="text"
            size="medium"
          /> -->
          <el-input placeholder="Ingresar rfc..." v-model="formData.federalTaxPayerRegistration" type="text" size="medium">
            <el-select v-model="federalTaxRegistrationSelected" slot="prepend" placeholder="Seleccionar" @change="onChangeRfc" class="input-with-select">
              <el-option label="RFC CLIENTE" value="1"></el-option>
              <el-option label="RFC GENERICO" value="2"></el-option>
            </el-select>
          </el-input>
        </el-form-item>
      </el-col>
      <el-col :span="4">
        <el-form-item
          :label="dateTimeLabel"
          :prop="`${propName}.exchangeDate`"
        >
          <el-date-picker
            v-model="formData.exchangeDate"
            type="datetime"
            placeholder="Seleccionar fecha y hora"
            size="medium"
          />
        </el-form-item>
      </el-col>
      <el-col :span="4">
        <el-form-item
          label="ID Ubicación"
          :prop="`${propName}.locationNumber`"
        >
          <el-input
            v-model="formData.locationNumber"
            type="text"
            size="medium"
            :disabled="true"
          />
        </el-form-item>
      </el-col>
      <el-col :span="4">
        <el-form-item
          label="Distancia Recorrida"
          :prop="`${propName}.traveledDistance`"
        >
          <el-input-number
            v-model="formData.traveledDistance"
            type="text"
            size="medium"
          />
        </el-form-item>
      </el-col>
    </el-row>
    
    <address-box-view
      :form-data="formData"
      :prop-name="propName" 
      orientation="horizontal"
    />
  </div>
</template>
